import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './CashManager.module.scss';
import '../dashboard_style.scss';
import TabContainer, { TabItem } from '../../../components/UI/Tabs/tabs.js';
import Summary from './Summary/Summary';
import Orders from './Orders/Orders';
import DailySettings from './DailySettings/DailySettings';
import TopUps from './TopUps/TopUps';
import CheckoutModal from './CheckoutModal/CheckoutModal';
import Personalizations from './CheckoutModal/Personalizations/personalizations';
import get_personalizations from '../../../queries/Personalization/Personalization';
import { graphql } from 'react-apollo';
import dropDownCurrencies from './CheckoutModal/Data';

class CashManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      showModal: false,
      showModal_pencil: false,
      show_popup: false,
      account_balance: JSON.parse(JSON.stringify(dropDownCurrencies)),
      my_checkout: JSON.parse(JSON.stringify(dropDownCurrencies)),
      duration: [
        { isChecked: false, value: i18n.t("TODAY"), valueDB: 'TODAY', graphql_notation: "day", symbol: "TODAY" },
        { isChecked: false, value: i18n.t("WEEK_TO_DATE"), valueDB: 'WEEK TO DATE', graphql_notation: "week", symbol: "WTD" },
        { isChecked: false, value: i18n.t("MONTH_TO_DATE"), valueDB: 'MONTH TO DATE', graphql_notation: "month", symbol: "MTD" },
        { isChecked: true, value: i18n.t("YEAR_TO_DATE"), valueDB: 'YEAR TO DATE', graphql_notation: "year", symbol: "YTD" }
      ]
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data.loading === false) {
      let account_balance = state.account_balance;
      let my_checkout = state.my_checkout;
      let duration = state.duration;
      const personalization = props.data.personalization;

      account_balance.forEach(currency => {
        if (personalization.homepage.includes(currency.value.split("(")[0].replace(/\s/g, ""))) {
          currency.isChecked = true;
        } else {
          currency.isChecked = false;
        }
      });

       my_checkout.forEach(currency => {
        if (personalization.myCheckout.includes(currency.value.split("(")[0].replace(/\s/g, ""))) {
          currency.isChecked = true;
        } else {
          currency.isChecked = false;
        }
      });

      duration.forEach(duration => {
        if (personalization.duration === duration.valueDB) {
          duration.isChecked = true;
        } else {
          duration.isChecked = false;
        }
      });

      console.log("parent", dropDownCurrencies)
      console.log("checkout", my_checkout)
      console.log("account_balance", account_balance)
    }
    return null;
  }


  handle_func = () => {
    this.props.refresh_dashboard();
  };

  showSubComp = () => {
    let duration, symbol
    this.state.duration.forEach(element => {
      if (element.isChecked) {
        duration = element.graphql_notation
        symbol = element.symbol
      }
    });

    switch (this.state.activeTab) {
      case 1:
        return <Summary current_user = {this.props.current_user} currencies={this.state.account_balance} duration={duration} symbol={symbol} />
      case 2:
        return <DailySettings current_user = {this.props.current_user} inform_parent={() => this.handle_func()} />
      case 3:
        return <TopUps current_user = {this.props.current_user}/>;
      case 4:
        return <Orders current_user = {this.props.current_user}/>;
      default:
        return <Summary />;
    }
  };

  modalHandler = () => {
    this.setState({
      showModal: !this.state.showModal
    });

    if (!this.state.showModal) {
      this.props.effect_open("cash_manager")
    }
  };

  childEvent(event) {
    event.stopPropagation();
    if (this.props.childEvent) {
      this.props.childEvent();
    }
  }

  closeCheckoutModal() {
    this.setState({
      showModal: false
    });

  }

  render() {
    var modal = null;
    if (this.state.showModal) {
      modal = <CheckoutModal current_user={this.props.current_user} currencies={this.state.my_checkout} modal_open={this.modalHandler.bind(this)} closeCheckoutModal={this.closeCheckoutModal.bind(this)} />;
    }
    var CashManagerBody =
      <div>
        <TabContainer>
          <TabItem text={i18n.t("TAB_SUMMARY")} active={this.state.activeTab === 1 ? "active" : ''} onClick={() => { this.setState({ activeTab: 1 }); }} />
          <TabItem text={i18n.t("TAB_DAILY_SETTINGS")} active={this.state.activeTab === 2 ? "active" : ''} onClick={() => { this.setState({ activeTab: 2 }); }} />
          <TabItem text={i18n.t("TAB_TOP_UPS")} active={this.state.activeTab === 3 ? "active" : ''} onClick={() => { this.setState({ activeTab: 3 }); }} />
          <TabItem text={i18n.t("TAB_ORDERS")} active={this.state.activeTab === 4 ? "active" : ''} onClick={() => { this.setState({ activeTab: 4 }); }} />
        </TabContainer>
        {this.showSubComp()}
      </div>;

    return (
      <div className={classes.dashboardItem}>
        <div className={classes.dashboardHeader} onClick={this.props.showTabHandler}>
          <div className={classes.dashboardHeaderLeftSide}>
            <div onClick={this.childEvent.bind(this)} style={{ display: 'inline-flex' }}>
              <div className={classes.dashboardHeaderTitle} onClick={this.props.showTabHandler}>{i18n.t("CASH_MANAGER")}</div>
              <div style={{ position: "relative" }} onClick={() => this.modalHandler()}>
                <i id="checkoutModalIconCM" className="fas fa-plus-circle" style={{ color: 'white', fontSize: '23px', marginLeft: '10px' }}></i>
                {modal}
              </div>
            </div>
          </div>
          <div className={classes.dashboardHeaderRightSide} >
            <div className="position-relative" onClick={(event) => { event.stopPropagation() }}>
              <i id="personalizationModalIconCM" className="fas fa-pen" onClick={this.personalizations_toggle_modal.bind(this)}></i>
              {this.state.showModal_pencil ?
                (<Personalizations
                  close_modal={this.close_personalizations_modal.bind(this)}
                  set_currency_list={this.set_currency_list}
                  account_balance={this.state.account_balance}
                  duration={this.state.duration}
                  my_checkout={this.state.my_checkout}
                />
                ) : null}
            </div>
          </div>
        </div>
        {this.props.show ? CashManagerBody : null}

      </div>);

  }
  personalizations_toggle_modal() {
    this.setState({ showModal_pencil: !this.state.showModal_pencil })
    if (!this.state.showModal_pencil) {
      this.props.effect_open("cash_manager")
    }
  }
  close_personalizations_modal() {
    this.setState({ showModal_pencil: false })
  }
}


export default graphql(get_personalizations)(CashManager);
