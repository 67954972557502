import i18n from 'i18next';
import React, {useState, useEffect} from 'react';
import classes from '../../CashManager.module.scss';
import DailySettingsRate from '../DailySettingsRate';
import get_exchange_rates_query from '../../../../../queries/CashManager/ExchangeRates';
import create_exchange_rate_mutation from '../../../../../mutations/CashManager/CreateExchangeRate';

import { useQuery } from "@apollo/react-hooks";
import { useMutation } from '@apollo/react-hooks';

const ExchangeRateComponent = props => {

  const { loading, error, data, refetch } = useQuery(get_exchange_rates_query, {
    variables: { date: props.date }
  });
  const [goMutation] = useMutation(create_exchange_rate_mutation);
  const [newExchangeRate, setNewExchangeRate] = useState(false);
  var currentdate = new Date();
  const current_date = ("0" + currentdate.getDate()) + "/" + ("0" + (currentdate.getMonth() + 1)).slice(-2) + "/" + currentdate.getFullYear();
  var dateFormat = require('dateformat');

  const handleClickedNew = type => {
    goMutation({
      variables:
      {
        attributes:
        {
          currency: type.currency ? type.currency.toUpperCase() : null,
          purchaseRate: parseFloat(type.first_element),
          saleRate: parseFloat(type.second_element)
        }
      }
    })
    setNewExchangeRate(false)
  };


  refetch();

  if (loading) return null;
  if (error) return `Error! ${error}`;
  var listItems = null;

  if (data.exchangeRates) {
    listItems = data.exchangeRates.map(record => {

      return (
        <DailySettingsRate
          key={record.id}
          currency={record.currency}
          purchase_rate={record.purchaseRate.toString()}
          sales_rate={record.saleRate.toString()}
          type={"exchange_rate"}
          input={props.input}
          employee = {props.current_user.isEmployee}
          onChildClicked={handleClickedNew}
          date_of_creation={dateFormat(record.datetime, "dd/mm/yyyy")}
          flex={1}
          with_ALL={true}
          marginLeft={"0px"} />
      )
    });

  }

  return (
    <div className={classes.daily_settings_card_small_text} style={{ marginBottom: "20.39px", paddingTop: "12.6"}}>
    {i18n.t("EXCHANGE_RATES")}
      {props.current_user.isEmployee || props.date != current_date ?
        null
      :
        <i
          className="fas fa-plus"
          style={{
            color: "rgb(211, 156, 62)",
            fontSize: "14px",
            marginLeft: "10px"
          }}
          onClick={() => setNewExchangeRate(true)}>
        </i>
      }
      <div style={{ marginTop: "12.6px", width: "90%", marginLeft: "auto", marginRight: "auto" }}>
        {listItems}
        {newExchangeRate ?
          <DailySettingsRate type="exchange_rate"
            onChildClicked={handleClickedNew}
            input={"true"}
            modifiable={true}
            date_of_creation={dateFormat(new Date(), i18n.t("DD_MM_YYYY"))}
            flex={1}
            with_ALL={true}
            marginLeft="0px" /> :
          null}

      </div>
    </div>);

};

export default ExchangeRateComponent;
