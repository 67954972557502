import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './MyDossier.module.scss';
import CurrencyBoxDossier from './CurrencyBoxDossier/CurrencyBoxDossier';
import receive_client from "../../../../Client";
import users_query from '../../../../queries/LoggedUser';

class MyDossier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: "",
      currencies: [
        { isChecked: true, value: "ALL", description: "Albanian Lek" },
        { isChecked: true, value: "EUR", description: "Euro" },
        { isChecked: true, value: "USD", description: "US Dollar" },
        { isChecked: true, value: "GBP", description: "Pound Sterling" },
        { isChecked: true, value: "CHF", description: "Pound Sterling" }]
    };


  }

  componentDidMount = async () => {
    const client = receive_client();
    const result = await client.query({ query: users_query });
    this.setState({
      user_id: result.data.currentUser.id
    });

  };

  render() {
    let filtering_string = `user_id=${this.state.user_id}&`;

    const currency_box = this.state.currencies.map(currency => {
      if (currency.isChecked) {
        filtering_string = filtering_string.concat("currencies[]=" + currency.value + "&");
        return (
          <CurrencyBoxDossier key={currency.value} type={currency.value} duration={"day"} symbol={"TODAY"} />);
      }
      return null
    });

    filtering_string = filtering_string + "typology=online";

    const url = `${process.env.REACT_APP_HOME_URL}/file/daily_currency_details?${filtering_string}`;

    return (
      <div style={{ paddingLeft: '80px', paddingRight: '80px', paddingTop: '55px', paddingBottom: '55px', minHeight: "600px" }}>
        <div className="text-center">
          <div className={classes.summary_title}>{i18n.t("ACCOUNT_BALANCE")} <span className={classes.summary_online}>{i18n.t("AVAILABLE_ONLINE")}</span></div>
          <div className="hr_seperator"></div>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
            <a href={this.state.user_id !== "" ? url : null}>
              <i className="fas fa-list-ul" style={{
                color: "rgb(211, 156, 62)",
                fontSize: "18px",
                marginRight: "10px",
                cursor: "pointer"
              }}>
              </i>
            </a>
          </div>
        </div>
        {currency_box}
        <div className={classes.SummaryBottomTextContainer}>
          {i18n.t("YOUR_ACCOUNT_BALANCES_ARE_BASE")} <br />
          {i18n.t("MADE_WITHIN_THIS_PORTAL_THEY")} <br />
          {i18n.t("ONLINE_AND_NOT_THE_CURRENCY_AV")}
        </div>
      </div>);

  }
}


export default MyDossier;
