import React, { useEffect, useState } from 'react';
import classes from './SearchEngine.module.scss';

const SearchEngine = (props) => {

    const [search_value, setSearchValue] = useState(props.value ? props.value : '');


    const handleSearchEngineChange = (e) => {
        setSearchValue(e.target.value)
        // props.filter_search_filed(e.target.value)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            props.filter_search_filed(search_value)
        }
    }

    useEffect(() => {
        if (props.value === null)
            setSearchValue('');
    }, [props.value])

    return (
        <div className={classes.SearchEngine} style={props.extraStyle}>
            <i className={["fas fa-search fa-lg"].join(' ')}></i>
            <input
                className={classes.SearchInput}
                type="text"
                placeholder={props.placeholder}
                value={search_value}
                onChange={handleSearchEngineChange}
                onKeyPress={handleKeyPress}
            />
        </div>
    )
}

export default SearchEngine;
