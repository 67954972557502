import i18n from 'i18next';
import React, { useState } from 'react';
import classes from './SignUp.module.scss';
import { graphql } from 'react-apollo';
import { useMutation } from 'react-apollo';
import AddFiscalizationData from '../../../../../mutations/Fiscalization/AddFiscalizationData';


const EmployeeFiscData = (props) => {

const [addFiscalizationData] = useMutation(AddFiscalizationData);
const [message, setMessage] = useState(null);
const [tvsh, setTvsh] = useState(false);
const [fiscData, setFiscData] = useState({
  tcrCode: "",
  businessUnitCode: "",
  operatorCode: "",
});

const handleInputChange = (event) => {
    event.persist();
    const fieldName = event.target.name;
    setFiscData({ ...fiscData, [fieldName]: event.target.value });
}

const onSave = () => {
  addFiscalizationData({
      variables: {
        attributes: {
          tcrCode: fiscData.tcrCode,
          businessUnitCode: fiscData.businessUnitCode,
          operatorCode: fiscData.operatorCode,
          tvsh: tvsh
        },
        user_id: props.user_id,
      }
  }).then(result => {
      setMessage(null)
      props.changeStep(3)
  }).catch(error => {
      formatErrorMEssage(error.message)
  });
}

const formatErrorMEssage = (message) => {
  let new_msg = message.replace('GraphQL error: ',"").replaceAll(',', '\n')
  setMessage(<p style={{ color: "#9A2020", whiteSpace:"pre-line" }}>{new_msg}</p>)
}

  return (
    <div className={classes.Form}>
      {message}
      <p style={{ fontSize: 16 }}><b>{i18n.t("PROVIDE_FISCALIZATION_DATA")}</b></p>
      <input
        className="info_card_input"
        style={{ width: 192 }}
        name="tcrCode"
        placeholder="TCR Code"
        onChange={handleInputChange}
      />
      <input
        className="info_card_input"
        style={{ width: 192 }}
        name="businessUnitCode"
        placeholder="Business Unit Code"
        onChange={handleInputChange}
      />
      <input
        className="info_card_input"
        style={{ width: 192 }}
        name="operatorCode"
        placeholder="Operator Code"
        onChange={handleInputChange}
      />
      <div className="info_card_input" style={{display:"flex", alignItems:"center", width: 192, border:"none"}}>
        <input type="radio" name="demo" id="radio-one" checked={tvsh} className={classes.formRadio}
          value="privacy"
          description="privacy"
          readOnly={true}
          onClick={() => setTvsh(!tvsh)}
        />
        <div style={{fontWeight:600}}>TVSH</div>
      </div>
      <div className="button_type1" style={{ margin: "20px 0", width: 101 }} onClick={() => onSave()}>{i18n.t("SAVE")}</div>
    </div>);
}

export default EmployeeFiscData
