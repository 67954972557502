import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languagedetector from 'i18next-browser-languagedetector';

import common_en from "./translations/en.json";
import common_sq from "./translations/sq.json";

const resources = {
  en: {
    translation: common_en
  },
  sq: {
    translation: common_sq
  }
};

var localLang = window.localStorage.getItem('language');

let language = 'sq';

if (localLang !== [] || localLang.length !== 0) {
  language = localLang;
}

i18n
  .use(languagedetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: language,
    keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
