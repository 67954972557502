import gql from 'graphql-tag';

export default gql`
mutation createSaleOrder($attributes:SaleOrderInput!, $sold_from_purchase:ID, $expiration:String, $sold_currency_bank:ID, $paid_currency_bank:ID){
    createFixedSaleOrder(attributes:$attributes, soldFromPurchase:$sold_from_purchase, expiration: $expiration, soldCurrencyBank:$sold_currency_bank, paidCurrencyBank:$paid_currency_bank) {
     id
     amountSoldCents
     amountSoldCurrency
     amountPaidCents
     amountPaidCurrency
     status
     number
     rate
     datetime
     companyRepresentative
     expiration
    }
  }
`;
