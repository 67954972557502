import gql from 'graphql-tag';

export default gql`

mutation createHeadquarter($attributes: HeadquarterInput, $user_id:ID!, $shareholder_list:[JSON!], $registrationSignedId: ID, $licenceSignedId:ID) {
  createHeadquarter(attributes: $attributes, userId: $user_id, shareholderList:$shareholder_list, registrationSignedId: $registrationSignedId, licenceSignedId: $licenceSignedId ) {
    id
  }
}
`;
