import gql from 'graphql-tag';
export default gql`

query marketRates($currency:String) {
  marketRates(currency:$currency){
    currency
    buyRate
    sellRate
    prevBuyRate
    prevSellRate
    candles
  }
}

`;
