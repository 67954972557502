import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './personalizations.module.scss';
import MultiSelectDropdown from '../../../../../components/UI/MultiSelectDropdown/MultiSelectDropdown';
import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';
import { graphql } from 'react-apollo';
import update_personalization from '../../../../../mutations/Personalization/UpdatePersonalization';
import get_personalizations from '../../../../../queries/Personalization/Personalization';

class Personalizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_balance: this.props.account_balance,
      duration: this.props.duration,
      my_checkout: this.props.my_checkout,
      account_balance_dropdown: false,
      my_checkout_dropdown: false,
      selected: "",
      input_account_balance: "",
      input_my_checkout: ""
    };


    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }


  handleSave = () => {
    let homepage_currencies = [];
    let my_checkout_currencies = [];
    let duration = null;
    this.state.account_balance.forEach(currency => {
      if (currency.isChecked) {
        homepage_currencies.push(currency.value.split("(")[0].replace(/\s/g, ""));
      }
    });

    this.state.my_checkout.forEach(currency => {
      if (currency.isChecked) {
        my_checkout_currencies.push(currency.value.split("(")[0].replace(/\s/g, ""));
      }
    });

    this.state.duration.forEach(currency => {
      if (currency.isChecked) {
        duration = currency.valueDB;
      }
    });


    this.props.mutate({
      variables: { homepage: homepage_currencies, my_checkout: my_checkout_currencies, duration: duration },
      refetchQueries: [{ query: get_personalizations }]
    });


    this.props.close_modal();

  };

  handleAccountBalance = (currency_selected, action) => {
    let account_balance = JSON.parse(JSON.stringify(this.state.account_balance));
    account_balance.forEach(currency => {
      if (currency.value === currency_selected.value)
        currency.isChecked = action === "checked" ? true : false;
    });
    this.setState({ account_balance: account_balance });
  };

  handleDuration = (selected_duration, id) => {
    let duration = JSON.parse(JSON.stringify(this.state.duration));
    duration.forEach(element => {
      if (element.value === selected_duration) {
        element.isChecked = true;
      } else {
        element.isChecked = false;
      }
    });
    this.setState({ duration: duration });
  };

  handleMyCheckout = (currency_selected, action) => {
    let my_checkout = JSON.parse(JSON.stringify(this.state.my_checkout));

    let counter = 0;

    my_checkout.forEach(currency => {
      if (currency.isChecked === true) {
        counter++;
      }
    });


    my_checkout.forEach(currency => {
      if (currency.value === currency_selected.value) {
        if (action === "checked" && counter < 3) {
          currency.isChecked = true;
        } else
          if (action === "remove") {
            currency.isChecked = false;
          }
      }
    });

    this.setState({ my_checkout: my_checkout });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.id !== 'personalizationModalIconCM') {
      this.props.close_modal();
    }
  }

  handleInputClick = item => {
    if (item === "account_balance_dropdown") {
      this.setState({
        account_balance_dropdown: true,
        my_checkout_dropdown: false
      });

    } else
      if (item === "my_checkout_dropdown") {
        this.setState({
          account_balance_dropdown: false,
          my_checkout_dropdown: true
        });

      }
  };

  handleClickIcon = item => {
    if (item === "account_balance_dropdown") {
      this.setState({
        account_balance_dropdown: !this.state.account_balance_dropdown,
        my_checkout_dropdown: false
      });

    } else
      if (item === "my_checkout_dropdown") {
        this.setState({
          account_balance_dropdown: false,
          my_checkout_dropdown: !this.state.my_checkout_dropdown
        });

      }
  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };


  render() {
    return (
      <div ref={this.setWrapperRef} className={[classes.modal_item, classes.personalizations_modal].join(' ')}>
        <div className={classes.modal_header}>
          <div className={classes.frequency_modal_header}>{i18n.t("PERSONALIZATIONS")}</div>
        </div>
        <div className={classes.modal_body}>
          <div className={classes.personalizations_body}>
            <div className={[classes.personalizations_hrow, 'row'].join(' ')}>
              <div className="col-lg-8">
                <div className={classes.personalizations_miniHeader}>{i18n.t("ACCOUNT_BALANCE")}</div>
                <div className="row">
                  <div className="col-6" style={{ paddingRight: "2.5px" }}>
                    <div className="text-center">


                      <div className="multi_select_dropdown">
                        <MultiSelectDropdown
                          name={"input_account_balance"}
                          placeholder="currency"
                          onChange={this.handleInput}
                          value={this.state.input_account_balance}
                          onClick={() => this.handleInputClick("account_balance_dropdown")}
                          onClickIcon={() => this.handleClickIcon("account_balance_dropdown")}
                          data={this.state.account_balance}
                          show_dropdown={this.state.account_balance_dropdown}
                          handleDropdownChange={this.handleAccountBalance}
                          description={!this.state.account_balance_dropdown ? i18n.t("SELECT_THE_CURRENCIES_YOU_WOUL") : null}
                          flex={"column"}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="col-6" style={{ paddingLeft: "2.5px" }}>
                    <div className="text-center">

                      <div className="dropdown" style={{ marginTop: "10px" }}>
                        <Dropdown key="firstDropdown"
                          items={this.state.duration}
                          onSelectDropdown={this.handleDuration}
                          selectedItem={this.state.duration.filter(duration => duration.isChecked === true)[0].value}
                        />
                      </div>

                      <div className={classes.personalizations_lightgray} style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {i18n.t("SELECT_THE_TIME_RANGE_FOR_STAT")}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className={classes.personalizations_miniHeader}>{i18n.t("MY_CHECKOUT")}</div>
                <div className="row">
                  <div className="col-12">
                    <div className="text-center">

                      <div className="multi_select_dropdown">
                        <MultiSelectDropdown
                          name={"input_my_checkout"}
                          placeholder="checkout"
                          onChange={this.handleInput}
                          value={this.state.input_my_checkout}
                          onClick={() => this.handleInputClick("my_checkout_dropdown")}
                          onClickIcon={() => this.handleClickIcon("my_checkout_dropdown")}
                          data={this.state.my_checkout}
                          show_dropdown={this.state.my_checkout_dropdown}
                          handleDropdownChange={this.handleMyCheckout}
                          description={!this.state.my_checkout_dropdown ? i18n.t("SELECT_THE_THREE_CURRENCIES_YO") : null}
                          flex={"column"}
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right" style={{ marginTop: '15px', width: '100%' }}>
                <div className="button_type1" onClick={() => this.handleSave()}>{i18n.t("SAVE")}</div>
                <div className="button_type2" onClick={() => this.props.close_modal()}>{i18n.t("CANCEL")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>);

  }
}



export default graphql(update_personalization)(Personalizations);
