import React, { Component } from 'react';
import MyProfileModal from '../../../containers/Modals/MyProfile/MyProfile.js';

export default class UserIcon extends Component {
  constructor(props) {
    super();
    this.state = {
      modal_open: false,
    }
  }
  escFunction(event) {
    if (event.keyCode === 27) {
      this.setState({ modal_open: false });
      document.body.style.overflow = 'auto';
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction.bind(this), false);
  }
  render() {
    var icon_opacity = 1;
    var modal = null;
    if (this.state.modal_open) {
      icon_opacity = 0.28;
      modal = <MyProfileModal modal_open={this.changeShow.bind(this)} />
    }
    return (
      <div>
        <div onClick={() => { this.changeShow() }} style={{ cursor: 'pointer' }}>
          <i className={"fas fa-user-cog "} style={{ color: 'white', fontSize: '20px', justifyContent: 'baseline', opacity: icon_opacity }} ></i>
        </div>
        {modal}
      </div>
    )
  }
  changeShow() {
    this.setState({ modal_open: !this.state.modal_open });
    if (document.getElementById('modal_my_profile')) {
      document.body.style.overflow = 'auto';
    }
    else {
      document.body.style.overflow = 'hidden';
    }
  }
}
