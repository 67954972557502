import i18n from "i18next";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classes from "../CurrencyTrader.module.scss";
import Button from "../../../../components/UI/Button/Button";
import CurrencyOptions from "./CurrencyTraderOrders/CurrencyOptions";
import coin from "../../../../assets/icons/coin.png";
import { graphql } from "react-apollo";
import bank_accounts from "../../../../queries/CurrencyTrader/BankAccounts";

class DossierModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_type: "Purchase",
      currency: "EUR",
      second_currency: "ALL",
      cross_currency: false,
      error_message: null,
      currencies: [
        {
          value: "EUR",
          description: "Euros",
          isChecked: true,
        },

        {
          value: "USD",
          description: "US Dollars",
          isChecked: false,
        },

        {
          value: "GBP",
          description: "British Pounds",
          isChecked: false,
        },

        {
          value: "CHF",
          description: "Swiss Francs",
          isChecked: false,
        },
      ],
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  childEvent(event) {
    event.stopPropagation();
    if (this.props.childEvent) {
      this.props.childEvent();
    }
  }

  sendData = (obj) => {
    if (obj.type === "currency") {
      this.setState({
        currency: obj.currency,
      });
    } else if (obj.type === "second_currency") {
      this.setState({
        second_currency: obj.currency,
      });
    } else {
      this.setState({
        currency: obj.currency,
      });
    }
  };

  handleNext = (path) => {
    const { order_type, currency, second_currency } = this.state;
    let first_currency_available = false;
    let second_currency_available = false;

    const bank_accounts = this.props.data.bankAccounts;
    bank_accounts.map((bank) => {
      if (bank.currency === currency) {
        first_currency_available = true;
        return null;
      } else if (bank.currency === second_currency) {
        second_currency_available = true;
        return null;
      }
      return null;
    });

    if (!first_currency_available || !second_currency_available) {
      this.setState({
        error_message: i18n.t("YOU_DON_T_HAVE_AVAILABLE_BANK1"),
      });
    } else {
      this.props.history.push({
        pathname: path,
        state: { order_type, currency, second_currency },
      });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * close modal if clicked on outside of element
   */
  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.srcElement.id !== "dossierModalIconCT"
    ) {
      this.props.closeDossierModal();
    }
  }

  toggleCrossCurrency = () => {
    this.setState({
      cross_currency: !this.state.cross_currency,
    });
  };

  render() {
    return (
      <div
        ref={this.setWrapperRef}
        className={[classes.modal_item, classes.dossierModal].join(" ")}
        onClick={this.childEvent.bind(this)}
      >
        <div className={classes.modal_header}>
          <div className={classes.dossierHeader}>{i18n.t("NEW_TRANSACTION")}</div>
          <div onClick={this.toggleCrossCurrency}>
            <img
              className={classes.dossierModalHeaderIcon}
              src={coin}
              alt="coin"
              style={this.state.cross_currency ? { opacity: "30%" } : { opacity: "100%" }}
            />
          </div>
        </div>

        <div className={[classes.modal_body, classes.dossierBody].join(" ")}>
          <div className={classes.options}>
            {this.state.error_message ? (
              <div
                style={{
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#9A2020",
                  fontSize: "14px",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                {this.state.error_message}
              </div>
            ) : null}
            <p className={classes.someText}>{i18n.t("I_WOULD_LIKE_TO")}</p>
            <div
              className="d-flex"
              style={{ justifyContent: "center" }}
            >
              <p
                className={classes.optionText}
                style={this.state.order_type === "Purchase" ? { opacity: 1 } : { opacity: 0.28 }}
                onClick={() => {
                  this.setState({ order_type: "Purchase" });
                }}
              >
                {i18n.t("BUY1")}{" "}
              </p>
              <div
                style={{
                  height: "25px",
                  border: "1px solid #fd6700",
                  opacity: "0.28",
                  margin: "0 5px",
                }}
              ></div>
              <p
                className={classes.optionText}
                style={this.state.order_type === "Sale" ? { opacity: 1 } : { opacity: 0.28 }}
                onClick={() => {
                  this.setState({ order_type: "Sale" });
                }}
              >
                {i18n.t("SELL1")}{" "}
              </p>
            </div>
          </div>

          <CurrencyOptions
            cross_currency={this.state.cross_currency}
            send={this.sendData}
            available_currencies={this.state.currencies}
            order_type={this.state.order_type}
          />

          <div style={{ display: "flex", justifyContent: "flex-end", margin: "15px 0 0" }}>
            {this.state.cross_currency ? null : (
              <Button clicked={() => this.handleNext("/initialization")}> {i18n.t("NEXT")} </Button>
            )}
            <Button
              extraStyle={classes.cancelButton}
              clicked={() => {
                this.props.modal_open();
              }}
            >
              {" "}
              {i18n.t("CANCEL")}{" "}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default graphql(bank_accounts)(withRouter(DossierModal));
