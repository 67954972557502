import React, { Component } from 'react';
import ShareholderComponent from '../../../../Register/Headquarters/ShareholderComponent'
import Plus from '../../../../../components/UI/Plus/Plus';
import { graphql } from 'react-apollo';
import user from '../../../../../queries/LoggedUser'
import create_headquarter from '../../../../../mutations/Registration/CreateHeadquarter'
import compose from 'lodash/fp/compose';

class ShareHolderScreen extends Component {
  constructor(props) {
    super(props);
    let shareholders = this.props.data.currentUser.headquarter.shareholders || []
    this.state = {
      shareholders: shareholders,
      shareholder_counter: shareholders ? shareholders.filter(shareholder => shareholder.position.toString().length == 1).length : 0
    }
  }


  updateShareholders = () => {
    this.props.mutate({
      variables: { user_id: this.props.data.currentUser.id, shareholder_list: this.state.shareholders }
    }).then(res => {
      this.props.cancelEdit()
    }).catch(error => {
      const error_message = "Please fill all required fields"
      this.setState({
        error_message
      })
    })
  }

  findIndex = (position) => {
    let value;
    const position_length = position.toString().length
    if (position.toString().charAt(position_length - 1) == 1) {
      value = position.toString().substring(0, position_length - 1);
    }
    else {
      const prev = (position - 1).toString();
      const childs = this.state.shareholders.filter(shareholderi => shareholderi.position.toString().startsWith(prev))
      value = childs[childs.length - 1]["position"]
    }

    const array = this.state.shareholders
    for (var i = 0; i < array.length; i += 1) {
      if (array[i]["position"] === parseInt(value)) {
        return i;
      }
    }
    return -1;
  }

  addQuota = (position, quota) => {
    let shareholders = [...this.state.shareholders]
    const obj = shareholders.find(shareholder => shareholder.position === position)
    obj.quota = quota
    this.setState({
      shareholders
    })
  }

  addShareholderName = (position, name) => {
    let shareholders = [...this.state.shareholders]
    const obj = shareholders.find(shareholder => shareholder.position === position)
    obj.name = name
    this.setState({
      shareholders
    })
  }

  addShareholder = (position) => {
    const position_length = position.toString().length
    const previous_element_index = this.findIndex(position)
    let shareholders = [...this.state.shareholders]
    if (position.toString().length == 1) {
      shareholders.push({ name: "", quota: null, position: position })
    }
    else {
      shareholders.splice(previous_element_index + 1, 0, { name: "", quota: null, position: position });
      shareholders.join()
    }
    this.setState({
      shareholder_counter: position_length > 1 ? this.state.shareholder_counter : this.state.shareholder_counter + 1,
      shareholders
    })
  }

  render() {
    const shareholders = this.state.shareholders.map((shareholder, i) => {
      let counter = null
      let selected_circle = null
      const position_length = shareholder.position.toString().length
      if (shareholder.position.toString().charAt(position_length - 1) == "1" && shareholder.position.toString().length != 1) {
        const sub_shareholders = this.state.shareholders.filter(shareholderi => shareholderi.position.toString().startsWith(shareholder.position.toString().substring(0, shareholder.position.toString().length - 1)) && shareholderi.position.toString().length == shareholder.position.toString().length)
        counter = sub_shareholders.length
      }

      const sub_shareholders = this.state.shareholders.filter(shareholderi => shareholderi.position.toString().startsWith(shareholder.position) && shareholderi.position.toString().length != shareholder.position.toString().length).length
      if (sub_shareholders > 0) {
        selected_circle = true
      }

      return (
        <ShareholderComponent
          key={i}
          padding_counter={position_length}
          input_name={shareholder.position}
          handleNewchild={this.addShareholder}
          shareholders={this.state.shareholders}
          addQuota={(quota) => this.addQuota(shareholder.position, quota)}
          addShareholderName={(name) => this.addShareholderName(shareholder.position, name)}
          name={shareholder.name}
          counter={counter}
          selected_circle={selected_circle}
          editable={this.props.editMode}
          quota={shareholder.quota}
          plus={position_length != 1 && shareholder.position.toString().charAt(position_length - 1) == 1 && this.props.editMode ? true : false}
        />
      )
    })

    return (
      <div className="CEO_Details">
        CEO: {this.props.data.currentUser.headquarter.ceo}
        <div className="CEO_other_details">
          <div style={{ maxWidth: "650px", maxHeight: "400px", overflow: "auto" }}>
            {
              this.props.editMode ?
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
                  <Plus clicked={this.state.shareholder_counter == 9 ? null : () => this.addShareholder(this.state.shareholder_counter + 1)} />
                </div>
                :
                null
            }
            {shareholders}
          </div>
          {this.props.editMode ?
            <div className="text-center" style={{ marginTop: 30, width: "100%" }}>
              <div className="button_type1" onClick={this.updateShareholders}>SAVE</div>
              <div className="button_type2" onClick={() => this.props.cancelEdit()} >CANCEL</div>
            </div>
            :
            null
          }
        </div>
      </div>
    )
  };

}

export default compose(
  graphql(user),
  graphql(create_headquarter)
)(ShareHolderScreen);
