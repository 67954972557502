import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../DossierModal/BuySell.module.scss';
import ToolbarLogout from '../../../../components/Toolbar/ToolbarLogout';
import NumberFormat from 'react-number-format';
import Button from '../../../../components/UI/Button/Button';
import { Link } from "react-router-dom";
import eur_sell from '../../../../mutations/CurrencyTrader/CreateFixedSaleOrder';
import get_currency_trader_orders from '../../../../queries/CurrencyTrader/GetOrders';
import { graphql } from 'react-apollo';
import getSymbolFromCurrency from 'currency-symbol-map'


class SellCurrency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: null,
      amount_choosen: null,
      message: null,
      total_available: this.props.location.state.total_available,
      input_disabled: false,
      currency: this.props.location.state.currency
    };

  }

  amountChoosenInput = value => {
    this.setState({
      amount_choosen: value.value,
      total: (value.value * this.props.location.state.rate).toFixed(2)
    });

  };

  onSellEur = () => {
    if (this.state.amount_choosen == null) {
      this.setState({
        error_message: i18n.t("PLEASE_ADD_AN_AMOUNT")
      });

    } else {
      this.props.mutate({
        variables: {
          attributes:
          {
            amountSoldCents: this.state.amount_choosen.toString(),
            amountSoldCurrency: this.state.currency,
            amountPaidCents: this.state.total.toString(),
            amountPaidCurrency: "ALL",
            status: "completed",
            datetime: new Date()
          },

          sold_from_purchase: this.props.location.state.order_id
        },

        refetchQueries: [{ query: get_currency_trader_orders }]
      }).then(res => {
        this.props.history.push({
          pathname: "/completedorder",
          state: { order_type: "Sale", page: "eur_transaction" }
        });


      }).catch(error => {

        if (error.message === "GraphQL error: grater than available") {
          this.setState({
            error_message: i18n.t("THE_REQUESTED_AMOUNT_EXCEEDS_T")
          });

        } else
          if (error.message === "GraphQL error: don't pass amount validations") {
            this.setState({
              error_message: i18n.t("THE_AMOUNT_MUST_BE_AT_LEAST1")
            });

          } else
            if (error.message === "GraphQL error: Not enough limit remaining") {
              this.setState({
                error_message: i18n.t("THE_REQUESTED_AMOUNT_EXCEEDS_Y")
              });

            } else
              if (error.message === "GraphQL error: no bank account available") {
                this.setState({
                  error_message: i18n.t("YOU_DON_T_HAVE_AVAILABLE_BANK")
                });
              }
              else
                if (error.message === "GraphQL error: Currency Trader closed") {
                  this.setState({
                    error_message: i18n.t("CURRENCY_TRADER_CLOSED")
                  })
                }
      });
    }
  };

  componentDidMount = () => {
    const available_amount = this.props.location.state.total_available;
    const rate = this.props.location.state.rate.toFixed(2);
    if (available_amount <= 100) {
      this.setState({
        input_disabled: true,
        total: (available_amount * rate).toFixed(2),
        amount_choosen: available_amount
      });

    }
  };



  render() {
    const symbol = getSymbolFromCurrency(this.state.currency)
    return (
      <div>
        <ToolbarLogout />

        <div className={classes.Component}>
          <div className={classes.Head}>
            <div className={classes.TransactionText}>{i18n.t("RECAP")}</div>
          </div>
          <div style={{ height: "60px", display: "flex", alignItems: "center", justifyContent: "center", color: "#9A2020", fontSize: "14px" }}>{this.state.error_message}</div>
          <div className={classes.container} style={{ paddingTop: "0px" }}>
            <div className={classes.currenciesHeader}> </div>
            <div className={[classes.currencies, classes.Sale].join(' ')}>
              <div className={classes.currencies_content} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div className={classes.someText}>{this.state.input_disabled ? i18n.t("YOU_WOULD_LIKE_TO_SELL") : i18n.t("HOW_MUCH_WOULD_YOU_LIKE_TO_SEL")}</div>
                <div className={classes.recap_input_container}>
                  <div className={classes.boldText} style={{ height: "auto" }}>{this.state.currency}: </div>
                  <div>

                    {!this.state.input_disabled ?
                      <div>
                        <NumberFormat style={{ marginLeft: "5px" }}
                          thousandSeparator={true}
                          prefix={symbol}
                          placeholder={i18n.t("MINIMUM1", {symbol: symbol})} value={this.state.amount_choosen}
                          onValueChange={value => this.amountChoosenInput(value)}
                        />
                      </div> :

                      <NumberFormat className={classes.boldText}
                        style={{ marginLeft: "5px" }}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={symbol}
                        value={this.state.amount_choosen}
                      />}

                  </div>
                </div>

                {!this.state.input_disabled ?
                  <div className={classes.input_requested_small}>
                    <NumberFormat style={{ marginLeft: "5px" }}
                      displayType={"text"} thousandSeparator={true}
                      prefix={symbol}
                      value={this.state.total_available}
                    />
                    <span> {i18n.t("REQUESTED")}</span>
                  </div> :

                  null}



              </div>
            </div>

            <div className={[classes.currencies, classes.Sale].join(' ')}>
              <div className={classes.currencies_content}>
                <p className={classes.someText}>{i18n.t("AT_A_RATE_OF")}</p>
                <p className={classes.boldText}>{symbol} 1 =<NumberFormat style={{ marginLeft: "10px" }} displayType={"text"} thousandSeparator={true} suffix={" L"} value={this.props.location.state.rate.toFixed(2)} /></p>

              </div>
            </div>

            <div className={[classes.currencies, classes.Sale].join(' ')}>
              <div className={classes.currencies_content}>
                <p className={classes.someText}>{i18n.t("FOR_A_TOTAL_OF")}</p>
                {this.state.total ?
                  <p className={classes.boldText}><NumberFormat style={{ marginLeft: "10px" }} displayType={"text"} thousandSeparator={true} suffix="L" value={this.state.total} /></p> :

                  <div style={{ fontWeight: "bold" }}> ... </div>}

              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
              <Button clicked={this.onSellEur}> {i18n.t("CONFIRM")} </Button>
              <Link to="/dashboard"> <Button extraStyle={classes.cancelButton}> {i18n.t("CANCEL")} </Button> </Link>
            </div>

          </div>
        </div>
      </div>);

  }
}




export default graphql(eur_sell)(SellCurrency);
