import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import Amplify from 'aws-amplify';
import classes from './StartingPopup.module.scss';
import get_service_point_shifts_query from '../../../../../queries/CashManager/ServicePointShifts';
import PopupPeriod from './PopupPeriod';
import PopupExchangeRate from './PopupExchangeRate';

const StartingPopup = props => {
  Amplify.configure({
    API: {
      graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
      graphql_headers: async () => ({
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    }
  });


  const [close, setClose] = useState(true);
  const [message, set_message] = useState(false);
  var currentdate = new Date();
  const current_date = ("0" + currentdate.getDate()) + "/" + ("0" + (currentdate.getMonth() + 1)).slice(-2) + "/" + currentdate.getFullYear();

  const closePopup = async (message_active = true) => {
    var lek = null;
    var euro = null;
    const fetched_data = await API.graphql(graphqlOperation(get_service_point_shifts_query));

    fetched_data.data.servicePointShifts.map(record => {
      if (record.amountOpenedCurrency === "EUR") {
        euro = true;
        return null;
      }
      if (record.amountOpenedCurrency === "ALL") {
        lek = true;
        return null;
      }
      return null
    });


    if (euro && lek) {
      setClose(true);
    } else {
      set_message(message_active);
      setClose(false);
    }
  };

  useEffect(() => {
    closePopup(false);
  }, []);

  return (
    <div>
      {!close ?
        <div className={classes.StartingPopup}>
          <div style={{ width: "80%", position: "relative", margin: "auto", display: "flex", padding: "10px" }}>
            <div className={[classes.ModalItem, classes.StartingModal].join(' ')}>
              <div style={{ flex: 1, flexDirection: "column", minHeight: "400px" }}>
                <div className={classes.dashboardHeader} style={{ border: "0px" }}>
                  <div className={classes.dashboardHeaderLeftSide}>
                    <div className={classes.dashboardHeaderTitle}> {i18n.t("START")} </div>
                  </div>
                  <div className={classes.dashboardHeaderRightSide}>
                    <div className={classes.dashboardHeaderTitle}> {current_date} </div>
                  </div>
                </div>
                <PopupPeriod message={message} />
              </div>
            </div>
            <div className={[classes.ModalItem, classes.DailySettingsModal].join(' ')}>
              <div style={{ display: "flex", flex: 1, flexDirection: "column", minHeight: "400px" }}>
                <div className={classes.dashboardHeader} style={{ border: "0px" }}>
                  <div className={classes.dashboardHeaderLeftSide}>
                    <div className={classes.dashboardHeaderTitle}> {i18n.t("SET_DAILY_RATES")}</div>
                  </div>
                  <div className={classes.dashboardHeaderRightSide}>
                    <div className={classes.dashboardHeaderTitle}> <i onClick={closePopup} className="fas fa-times-circle" style={{ color: "white", fontSize: "23px" }}></i> </div>
                  </div>
                </div>
                <PopupExchangeRate date={current_date} />
              </div>
            </div>
          </div>
        </div> : null}
    </div>);

};

export default StartingPopup;
