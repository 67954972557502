import React, { Component } from 'react';
import ToolbarLogout from '../../components/Toolbar/ToolbarLogout';
import ToolbarFooter from '../../components/Toolbar/ToolbarFooter';
import MarketRates from './MarketRates/MarketRates';
import CashManager from './CashManager/CashManager';
import CurrencyTrader from './CurrencyTrader/CurrencyTrader';
import StartingPopup from './CashManager/DailySettings/StartingPopup/StartingPopup';
import QuickRegistration from './QuickRegistration/QuickRegistration';
import BankAccountsPopUp from './CurrencyTrader/Orders/BankAccounts/BankAccountsPopUp';
import user from '../../queries/LoggedUser';
import { graphql } from 'react-apollo';


class Home extends Component {

  constructor(props) {
    super();
    this.state = {
      showCashManager: false,
      showCurrencyTrader: true,
      closed_popup: true,
      show_quick_registration: true,
      currencies: [
        { value: "EUR", text: 'EUR', name: "EUR (EUROS)", sign: "€" },
        { value: "USD", text: 'USD', name: "USD (US dollars)", sign: "$" },
        { value: "GBP", text: 'GBP', name: "GBP (British pounds)", sign: "£" },
        { value: "CHF", text: 'CHF', name: "CHF (Swiss francs)", sign: "₣" }],

      bankPopup: false
    };

  }

  showTabHandler() {
    const openedCashManager = this.state.showCashManager;
    this.setState({
      showCashManager: !openedCashManager,
      showCurrencyTrader: !this.state.showCurrencyTrader
    });


  }

  changeOpenedTab(tab) {
    if (tab === "cash_manager") {
      this.setState({
        showCashManager: true,
        showCurrencyTrader: false
      });

    } else {
      this.setState({
        showCashManager: false,
        showCurrencyTrader: true
      });

    }
  }

  componentDidMount() {
    const condition = typeof this.props.location.state === "undefined" ? false : true;
    this.setState({
      showCashManager: condition
    });

  }

  handle_refresh = () => {
    this.setState({
      closed_popup: false
    });

  };

  render() {
    if (!this.props.data.loading){

      let user = this.props.data.currentUser
      let user_typology = user.servicePoint.typology
      const CT = user.currencyTrader
      const MP = user.myProfile

      return (
        <div>
          {this.state.bankPopup ? <BankAccountsPopUp bankAccountsPopUp={() => this.setState({ bankPopup: false })} /> : null}
          {/* {user_typology === "service_point" && user.isStoreAdmin ? <StartingPopup /> : null} */}
          <ToolbarLogout current_user={user} my_profile={MP}/>
          <div className="container-fluid">
            <div className="row">
              <div className={user_typology === "market_viewer" ? "col-xl-12" : "col-xl-6"} style={{ marginTop: "20px", marginBottom: "20px" }}>
                {user_typology === "service_point" ? <QuickRegistration current_user={user} show={this.state.show_quick_registration} /> : null}
                <MarketRates />
              </div>
              <div className="col-xl-6" style={{marginTop:'-7px'}}>
                {user_typology === "market_viewer" || CT == false ? null : <CurrencyTrader current_user={user} bankAccountPopUp={() => this.setState({ bankPopup: !this.state.bankPopup })} show={this.state.showCurrencyTrader} showTabHandler={this.showTabHandler.bind(this)} effect_open={tab => this.changeOpenedTab(tab)} />}
              </div>
            </div>
          </div>
          <ToolbarFooter/>
        </div>);
      }
      else{
        return null
      }
  }
}


export default graphql(user)(Home);
