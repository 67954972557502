import i18n from 'i18next';
import React, { Component } from 'react';
import '../../myprofile.scss';
import InfoCard from '../../Extra/infoCard';

export default class PhoneFax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneFax_display: true,
      phoneFaxDetails: false,
      createNew: false,
      icon_show: true,
      phone_list: this.props.phone_list,
      fax_list: this.props.fax_list,
      new_phone: "",
      new_fax: ""
    };

  }

  componentDidUpdate = prevProps => {
    if (prevProps !== this.props)
      this.setState({
        phone_list: this.props.phone_list,
        fax_list: this.props.fax_list
      });

  };

  phoneFaxDetailsFunction() {
    var phoneFaxDetails = this.state.phoneFaxDetails;
    if (!phoneFaxDetails) {
      this.setState({ phoneFax_display: false, phoneFaxDetails: true, createNew: false });
    } else {
      this.setState({ phoneFax_display: true, phoneFaxDetails: false, createNew: false });
    }
  }
  addNewFunction() {
    this.setState({
      phoneFax_display: false,
      phoneFaxDetails: false,
      createNew: true
    });

  }
  saveCard = async () => {
    let phone_list = [...this.state.phone_list];
    let fax_list = [...this.state.fax_list];

    phone_list.push(this.state.new_phone);
    fax_list.push(this.state.new_fax);
    await this.props.update_data("phone_list", phone_list.filter(phone => { return phone !== ""; }));
    await this.props.update_data("fax_list", fax_list.filter(fax => { return fax !== ""; }));
    this.props.fire_mutation();

    this.setState({
      phoneFax_display: true,
      phoneFaxDetails: false,
      createNew: false,
      icon_show: true,
      new_fax: "",
      new_phone: ""
    });

  };

  cancelCard() {
    this.setState({
      phoneFax_display: true,
      phoneFaxDetails: false,
      createNew: false,
      icon_show: true
    });

  }
  checkChangePhone(index) {
    this.props.checkChangePhone(index);
  }
  checkChangeFax(index) {
    this.props.checkChangeFax(index);
  }

  handleInput = (e, index) => {
    let phone_list = [...this.state.phone_list];
    let fax_list = [...this.state.fax_list];
    if (e.target.name === "phone_list") {
      phone_list[index] = e.target.value;
      this.props.update_data(e.target.name, phone_list.filter(phone => { return phone !== ""; }));
    } else
      if (e.target.name === "fax_list") {
        fax_list[index] = e.target.value;
        this.props.update_data(e.target.name, fax_list.filter(fax => { return fax !== ""; }));
      }
  };

  render() {
    /* Phone Things */
    let phone_display = this.state.phone_list.map((phone, index) => {
      return (
        <div className="info_card_flex_between" key={index}>
          <b>{i18n.t("PHONE")} </b>
          <span> {this.props.modifiable ? <input className="info_card_input" name="phone_list" value={phone} onChange={e => this.handleInput(e, index)} /> : phone} </span>
        </div>);

    });

    /* Fax Things */
    let fax_display = this.state.fax_list.map((fax, index) => {
      return (
        <div className="info_card_flex_between" key={index}>
          <b>{i18n.t("FAX")} </b>
          <span> {this.props.modifiable ? <input className="info_card_input" name="fax_list" value={fax} onChange={e => this.handleInput(e, index)} /> : fax} </span>
        </div>);

    });

    /* Phone and Fax Things */
    var phoneFax_display =
      <div className="text-center" style={{ width: "100%" }}>
        {phone_display}
        {fax_display}
      </div>;


    var createNew =
      <div onClick={event => { event.stopPropagation(); }} style={{ padding: 10, width: '100%' }}>
        <div className="checkBox_container" style={{ marginTop: 5 }}>
        </div>
        <div className="text-center" style={{ padding: 10 }}>
          <div className="row info_card_row">
            <div className="col-md-12">
              <input type="text" className="info_card_input" placeholder="Phone" onChange={e => this.setState({ new_phone: e.target.value })} />
            </div>
          </div>
        </div>
        <div className="checkBox_container" style={{ marginTop: 5 }}>
        </div>
        <div className="text-center" style={{ padding: 10 }}>
          <div className="row info_card_row">
            <div className="col-md-12">
              <input type="text" className="info_card_input" placeholder="Fax" onChange={e => this.setState({ new_fax: e.target.value })} />
            </div>
          </div>
        </div>
        <div className="text-center" style={{ marginTop: 15, width: "100%" }}>
          <div className="button_type1" onClick={() => { this.saveCard(); }}>{i18n.t("SAVE")}</div>
          <div className="button_type2" onClick={() => { this.cancelCard(); }}>{i18n.t("CANCEL")}</div>
        </div>
      </div>;


    return (
      <div>
        <InfoCard title="Phone/Fax" icon={this.state.icon_show} childEvent={this.addNewFunction.bind(this)} plus_button={this.props.add_functionality}>
          <div className="row">
            {this.state.phoneFax_display ? phoneFax_display : null}
            {this.state.createNew ? createNew : null}
          </div>
        </InfoCard>
      </div>);

  }
}