import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './FilterModal.module.scss';

class EURFilterModal extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      min_rate_value: this.props.state.min_rate || "",
      max_rate_value: this.props.state.max_rate || "",
      min_paid_value: this.props.state.min_price || "",
      max_paid_value: this.props.state.max_price || "",
      min_quantity: this.props.state.min_quantity || "",
      max_quantity: this.props.state.max_quantity || "",
      sorting_element: this.props.state.sorting_element || null,
      sorting_order: this.props.state.sorting_order || "ASC"
    };


    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  childEvent(event) {
    event.stopPropagation();
    if (this.props.childEvent) {
      this.props.childEvent();
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.classList.value.search('filterModalIcon') === -1) {
      this.props.closeModal();
      this.show_values();
    }
  }

  show_values = () => {
    this.props.get_parent({
      min_rate_value: this.state.min_rate_value,
      max_rate_value: this.state.max_rate_value,
      min_paid_value: this.state.min_paid_value,
      max_paid_value: this.state.max_paid_value,
      min_quantity: this.state.min_quantity,
      max_quantity: this.state.max_quantity,
      sorting_element: this.state.sorting_element,
      sorting_order: this.state.sorting_order
    });

  };

  resetFilters = () => {
    this.setState({
      min_rate_value: "",
      max_rate_value: "",
      min_paid_value: "",
      max_paid_value: "",
      min_quantity: "",
      max_quantity: "",
      sorting_element: null,
      sorting_order: "ASC"
    });

  };

  changeSortDirection = () => {
    if (this.state.sorting_order === "ASC") {
      this.setState({
        sorting_order: "DESC"
      });

    } else {
      this.setState({
        sorting_order: "ASC"
      });

    }
  };


  render() {
    const sort_element =
      <div className={classes.arrowItem} style={{ top: this.state.sorting_order === "ASC" ? "-33px" : "-50px" }}><i onClick={() => this.changeSortDirection()} className={this.state.sorting_order === "ASC" ? "fas fa-sort-up" : "fas fa-sort-down"}></i></div>;


    return (
      <div ref={this.setWrapperRef} style={{ position: "absolute" }} onClick={this.childEvent.bind(this)}>

        <div className={classes.modal_item}>
          <div className={classes.modal_header}>
            <div className={classes.filter_modal_header}>{i18n.t("FILTERS")}</div>
            <div className={classes.filter_modal_header_icon} onClick={() => this.resetFilters()}>
              <i className="fas fa-undo-alt"></i>
            </div>
          </div>

          <div className={classes.modal_body}>
            <div className={classes.filter_modal_body}>


              <div className={classes.filterItem}>
                <div onClick={() => this.setState({ sorting_element: "value" })} className={classes.itemHeader}>
                  <p className={classes.itemTitle}>{i18n.t("QUANTITY")}</p></div>
                <div className={classes.currencies} style={{ position: "relative" }}>
                  {this.state.sorting_element === "value" ? sort_element : null}
                  <div style={{ marginRight: "2.5px" }}><input type="text" className={[classes.inputs, classes.left_input].join(' ')} placeholder="Min" value={this.state.min_quantity} onChange={e => { this.setState({ min_quantity: e.target.value }); }} /></div>
                  <div style={{ marginLeft: "2.5px" }}><input type="text" className={classes.inputs} placeholder="Max" value={this.state.max_quantity} onChange={e => { this.setState({ max_quantity: e.target.value }); }} /></div>
                </div>
              </div>
              <div className={classes.filterItem}>
                <div onClick={() => this.setState({ sorting_element: "amount_paid_cents" })} className={classes.itemHeader}>
                  <p className={classes.itemTitle}>{i18n.t("TOTAL_PRICE")} </p>
                </div>
                <div className={classes.currencies} style={{ position: "relative" }}>
                  {this.state.sorting_element === "amount_paid_cents" ? sort_element : null}
                  <div style={{ marginRight: "2.5px" }}><input type="text" className={[classes.inputs, classes.left_input].join(' ')} placeholder="Min" value={this.state.min_paid_value} onChange={e => { this.setState({ min_paid_value: e.target.value }); }} /></div>
                  <div style={{ marginLeft: "2.5px" }}><input type="text" className={classes.inputs} placeholder="Max" value={this.state.max_paid_value} onChange={e => { this.setState({ max_paid_value: e.target.value }); }} /></div>
                </div>
              </div>
              <div className={classes.filterItem}>
                <div onClick={() => this.setState({ sorting_element: "rate" })} className={classes.itemHeader}>
                  <p className={classes.itemTitle}>{i18n.t("RATE")}</p>  </div>
                <div className={classes.currencies} style={{ position: "relative" }}>
                  {this.state.sorting_element === "rate" ? sort_element : null}
                  <div style={{ marginRight: "2.5px" }}><input type="text" className={[classes.inputs, classes.left_input].join(' ')} placeholder="Min" value={this.state.min_rate_value} onChange={e => { this.setState({ min_rate_value: e.target.value }); }} /></div>
                  <div style={{ marginLeft: "2.5px" }}><input type="text" className={classes.inputs} placeholder="Max" value={this.state.max_rate_value} onChange={e => { this.setState({ max_rate_value: e.target.value }); }} /></div>
                </div>
              </div>


            </div>
          </div>



        </div>
      </div>);

  }
}

export default EURFilterModal;
