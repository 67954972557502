import gql from 'graphql-tag';

export default gql`
{
  currentUser {
    id
    name
    firstName
    lastName
    isStoreAdmin
    email
    token
    state
    typology
    personalIdNumber
    address
    postalCode
    country
    city
    cellphone
    isEmployee
    currencyTrader
    myProfile
    employees{
      id
      name
      firstName
      lastName
      myProfile
      currencyTrader
      cashManagerBalances{
        amountCurrency
        amountCents
      }
      exchangeRateGroup{
        id
      }
    }
    servicePoint {
      id
      typology
      address
      city
      commercialName
      country
      email
      fax
      fiscalCode
      identificationNumber
      phone
      postalCode
    }
    headquarter {
      id
      address
      ceo
      city
      country
      email
      licenceNumber
      postalCode
      registrationNumber
      shareholders
    }
  }
}
`;
