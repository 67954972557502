import gql from 'graphql-tag';

export default gql`

mutation createServicePointShift($attributes:ServicePointShiftInput!, $mismatch_ignored:Boolean){
  createServicePointShift(attributes:$attributes, mismatchIgnored:$mismatch_ignored) {
  id
  shiftNumber
  amountOpenedCurrency
  amountOpenedCents
  averageOpenedRate
  }
}

`;
