import React, { useEffect, useState } from 'react';
import ToolbarLogin from '../../../../../components/Toolbar/ToolbarLogin';
import i18n from 'i18next';
import classes from './SignUp.module.scss';
import Circle from '../Circle/Circle';
import '../../myprofile.scss';
import "../UserStyle.css";
import { useMutation } from 'react-apollo';
import AddEmployee from './AddEmployee';
import EmployeeFiscData from './EmployeeFiscData';

const SignUpComponent = (props) => {

    const [step, setStep] = useState(1);
    const [user_id, setUserID] = useState(null);

    const onClose = () => {
        props.history.push("/login");
    }

    const changeStep = (step) => {
      setStep(step)
    }


    const renderSwitch = () => {
      switch (step){
        case 1:
          return <AddEmployee changeStep={(step) => changeStep(step)} setUserId = {(user_id) => setUserID(user_id)}/>
          break;
        case 2:
          return <EmployeeFiscData changeStep={(step) => changeStep(step)} user_id = {user_id}/>
          break;
        case 3:
          return <FinalForm onClose={() => onClose()}/>
      }
    }

    return (
        <div>
            <ToolbarLogin />
            <div className={classes.AddEmployeeContainer}>
                <ul className={classes.PrivateAccountSlider}>
                    <hr className={classes.hrPrivate} />
                    <li><Circle circleType={step == 1 || 2 ? { active: true, activated: false } : { active: false, activated: true }} /></li>
                </ul>
                <div style={{ background: "white" }}>
                    <div className={classes.Header} style={step == 1 || 2 ? { opacity: 1 } : { opacity: 0.3 }}></div>
                    <div className={classes.FormContainer} style={step == 1 || 2 ? { padding: "30px 5px" } : null}>
                      {renderSwitch()}
                    </div>
                </div>
            </div>
        </div>)
}

export default SignUpComponent;


const FinalForm = (props) => {

const onClose = () => {
  props.onClose()
}

return (
  <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
    <p style={{ fontSize: 16 }}><b>{i18n.t("THANK_YOU") + "!"}</b></p>
    <p style={{ margin: 15 }}>{i18n.t("INFO3")}</p>
    <p>{i18n.t("INFO4")}</p>
    <div className="button_type1" style={{ margin: "20px 0", width: 110 }} onClick={() => onClose()}>{i18n.t("CLOSE")}</div>
  </div>
)}
