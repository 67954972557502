import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../../../CurrencyTrader/CurrencyTrader.module.scss';
import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';
import currenciesObject from "../../../../../data/currencies";

class Bought extends Component {

  currencies = currenciesObject[0];

  constructor(props) {
    super(props);

    this.state = {
      checkboxes: this.props.available_currencies,
      purchaseId: null
    };

  }

  onCheckCurrency = e => {
    let checkboxes = this.state.checkboxes;
    checkboxes.forEach(currency => {
      if (currency.value.split("(")[0].replace(/\s/g, "") === e.target.value.split("(")[0].replace(/\s/g, "")) {
        currency.self_checked = e.target.checked;
      } else {
        currency.self_checked = false;
      }
    });
    this.setState({ checkboxes: checkboxes });
    this.props.send({ currencyYouGet: e.target.value.split("(")[0].replace(/\s/g, "") });
  };

  onSelectDropdown1(selectedValue, id) {
    this.props.send({ currencyYouGet: id });
  }

  onSelectDropdown2(selectedValue, id) {
    this.props.send({ currencyYouGive: id });
  }

  componentDidMount() {
    this.setState({
      checkboxes: this.state.checkboxes.concat({ value: "OTHER", isChecked: true, self_checked: false, type: "All Currencies" })
    });

  }

  render() {
    let key = 0;
    const new_registration_checkboxes = this.state.checkboxes.map(checkbox => {
      if (checkbox.isChecked) {
        return (
          <div className={classes.radioBlock} key={key++}>
            <input type="radio" name={checkbox.value.split("(")[0].replace(/\s/g, "")} id="radio-one" className={classes.formRadio}
              value={checkbox.value.split("(")[0].replace(/\s/g, "")}
              description={checkbox.value.split("(")[0].replace(/\s/g, "")}
              checked={checkbox.self_checked}
              onChange={this.onCheckCurrency} />


            <div className={classes.currencyContainer}>
              <p className={classes.bigText}>{checkbox.value.split("(")[0].replace(/\s/g, "")}</p>
              <p className={classes.smallText}>{checkbox.type}</p>
            </div>

          </div>);

      }
      return null;
    });

    const otherCurrenciesHTML =
      <div>
        <div className={classes.currenciesDropdown}>
          <div style={{ fontWeight: "bold" }}>{i18n.t("CURRENCY_YOU_ARE_BUYING")}</div>

          <div style={{ width: "280px", marginTop: "4px" }}>
            <Dropdown key="firstDropdown" items={[
              { value: this.currencies['EUR'].description, id: this.currencies['EUR'].shortDsc },
              { value: this.currencies['USD'].description, id: this.currencies['USD'].shortDsc },
              { value: this.currencies['AUD'].description, id: this.currencies['AUD'].shortDsc },
              { value: this.currencies['CAD'].description, id: this.currencies['CAD'].shortDsc },
              { value: this.currencies['GBP'].description, id: this.currencies['GBP'].shortDsc },
              { value: this.currencies['CHF'].description, id: this.currencies['CHF'].shortDsc },
              { value: this.currencies['JPY'].description, id: this.currencies['JPY'].shortDsc },
              { value: this.currencies['SEK'].description, id: this.currencies['SEK'].shortDsc },
              { value: this.currencies['NOK'].description, id: this.currencies['NOK'].shortDsc },
              { value: this.currencies['DKK'].description, id: this.currencies['DKK'].shortDsc }]}

              onSelectDropdown={this.onSelectDropdown1.bind(this)} />
          </div>
        </div>
        <div className={classes.currenciesDropdown}>
          <div style={{ fontWeight: "bold" }}>{i18n.t("CURRENCY_YOU_ARE_USING_TO_PAY")}</div>

          <div style={{ width: "280px", marginTop: "4px" }}>
            <Dropdown key="secondDropdown" items={[
              { value: this.currencies['EUR'].description, id: this.currencies['EUR'].shortDsc },
              { value: this.currencies['USD'].description, id: this.currencies['USD'].shortDsc },
              { value: this.currencies['AUD'].description, id: this.currencies['AUD'].shortDsc },
              { value: this.currencies['CAD'].description, id: this.currencies['CAD'].shortDsc },
              { value: this.currencies['GBP'].description, id: this.currencies['GBP'].shortDsc },
              { value: this.currencies['CHF'].description, id: this.currencies['CHF'].shortDsc },
              { value: this.currencies['JPY'].description, id: this.currencies['JPY'].shortDsc },
              { value: this.currencies['SEK'].description, id: this.currencies['SEK'].shortDsc },
              { value: this.currencies['NOK'].description, id: this.currencies['NOK'].shortDsc },
              { value: this.currencies['DKK'].description, id: this.currencies['DKK'].shortDsc }]}

              onSelectDropdown={this.onSelectDropdown2.bind(this)} />
          </div>
        </div>
      </div>;


    return (
      <div>
        <div className={classes.checkoutHeaderBuy}> </div>
        {(!this.props.otherCurrencies ?
          (<div className={classes.checkoutCurrencies}>
            <div className={classes.radioBtns}>
              {new_registration_checkboxes}
            </div>
          </div>) :
          otherCurrenciesHTML)}
        {this.props.show_message ? <p style={{ color: "#992020", textAlign: "center" }}>{i18n.t("YOU_NEED_TO_SELECT_ONE_CURRENC")}</p> : ""}
      </div>);

  }
}

export default Bought;
