import React, { Component } from 'react';

import classes from './Circle.module.css';

class Circle extends Component {


    render() {
        let property;

        if (this.props.circleType) {
            if (this.props.circleType.active)
                property = classes.Active;
            if (this.props.circleType.activated)
                property = classes.Activated;
        }

        return (
            <div style={{marginTop:"20px"}}>
            <div className={classes.container}>                    
                <div className={[classes.Circle, property].join(' ')} onClick={this.props.clicked} />
            </div>
            </div>
        );
    }
}

export default Circle;
