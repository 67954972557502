import React from "react";

const plus = (props) => (
  <div>
    <i
      className="fas fa-plus-circle"
      style={{ cursor: "pointer", color: "#fd6700", fontSize: "22px" }}
      onClick={props.clicked}
    ></i>
  </div>
);

export default plus;
