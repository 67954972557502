import gql from 'graphql-tag';

export default gql`

mutation deleteFixedPurchaseOrder($id: ID!){
deleteFixedPurchaseOrder(id:$id) {
id
}
}

`;
