import gql from 'graphql-tag';
export default gql`

query($type:String!) {
 	bankAccountDetails(type:$type){
    bankAccounts
  }
}

`;
