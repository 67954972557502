import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ActionCable from 'actioncable'
import { ActionCableLink } from 'graphql-ruby-client'
import { ApolloLink } from 'apollo-link'

const Client = function () {

  const httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL })
  const cable = ActionCable.createConsumer(process.env.REACT_APP_ACTION_CABLE_URL)

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    }
  })

  const hasSubscriptionOperation = ({ query: { definitions } }) => {
    return definitions.some(
      ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
    )
  }

  const action_cable_link = ApolloLink.split(
    hasSubscriptionOperation,
    new ActionCableLink({ cable }),
    httpLink
  )



  const client = new ApolloClient({
    link: authLink.concat(action_cable_link),
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          }
        }
      })
    })
  })

  return client;
}

export default Client;
