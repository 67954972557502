import gql from 'graphql-tag';

export default gql`
mutation createFlexiblePurchaseOrder($attributes:PurchaseOrderInput!, $offer_list:[JSON!]){
    createFlexiblePurchaseOrder(attributes:$attributes, offerList: $offer_list) {
       id
       amountPaidCents
       amountPaidCurrency
       amountPurchasedCents
       amountPurchasedCurrency
       status
       number
       rate
       datetime
       expiration
    }
  }
`;
