import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './New.module.scss';

export default class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option_enabled: true
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickedType = type => {
    const clicked = type;
    this.setState({
      option_enabled: false
    })
    this.props.onChildClicked(clicked);
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.id !== 'newModalIconCM') {
      this.props.closeModal();
    }
  }

  render() {
    console.log(this.state.option_enabled)
    return (
      <div ref={this.setWrapperRef} className={classes.modal_item}>
        <div className={classes.modal_header}>{i18n.t("NEW")}</div>
        <div className={classes.modal_body}>
          <div className={classes.new_modal_body}>
            <div className={classes.new_click_item} style={{ cursor: "pointer" }} onClick={() => this.handleClickedType(this.props.first_description_ds || "top_up_" + this.props.first_description)}>{i18n.t(this.props.first_description_ds || this.props.first_description)}</div>
            <div className={classes.new_click_item} style={{ cursor: "pointer" }} onClick={() => this.state.option_enabled ? this.handleClickedType(this.props.second_description_ds || "top_up_" + this.props.second_description) : null}>{i18n.t(this.props.second_description_ds || this.props.second_description)}</div>
            <div className={classes.new_click_item} style={{ cursor: "pointer" }} onClick={() => this.handleClickedType("top_up_" + this.props.third_description)}>{i18n.t(this.props.third_description)}</div>
            <div className={classes.new_click_item} style={{ cursor: "pointer" }} onClick={() => this.handleClickedType("top_up_" + this.props.fourth_description)}>{i18n.t(this.props.fourth_description)}</div>
            {this.props.current_user.isStoreAdmin ? <div className={classes.new_click_item} style={{ cursor: "pointer" }} onClick={() => this.handleClickedType("top_up_" + this.props.fifth_description)}>{i18n.t(this.props.fifth_description)}</div> : null}
          </div>
        </div>
      </div>);
  }
}
