import i18n from 'i18next';
import React, { Component } from 'react';
import '../myprofile.scss';

export default class InfoCard extends Component {
  constructor(props) {
    super();
    this.state = {
      icon_click: false
    };

  }

  parentEvent(event) {
    if (this.props.parentEvent) {
      this.props.parentEvent();
    }
  }
  childEvent(event) {
    event.stopPropagation();
    if (this.props.childEvent) {
      this.props.childEvent();
    }
  }

  render() {
    var Icon = null;
    if (this.props.icon) {
      Icon =
        <div className="info_card_header_icon" onClick={this.childEvent.bind(this)}>
          <i className="fas fa-plus-circle"></i>
        </div>;

    }
    return (
      <div className="info_card" style={this.props.extraStyle ? this.props.extraStyle : null} onClick={this.parentEvent.bind(this)}>
        {this.props.show_message ?
          <div style={{ fontSize: "10px", marginBottom: "5px", color: "#881401" }}>
            {i18n.t("NOTE_IF_YOU_DELETE_THIS_BANK")}
          </div> :

          null}
        <div className="info_card_header">
          <div className="info_card_header_title">{this.props.title}</div>
          {this.props.plus_button !== false ? Icon : null}
        </div>
        {this.props.children}
      </div>);

  }
}