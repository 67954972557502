import React, { Component } from 'react';
import classes from './graph.module.css';
import BlankGraph from '../../../../assets/img/BlankGraph.svg';
import MaximizedGraph from '../../../../assets/img/Maximized graph.png';
import MaxiGraphHeader from "./MaxiGraphHeader";
import MiniGraphHeader from './MiniGraphHeader';

class Graph extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      maximize: false
    };

  }

  maximizePosition = position => {
    switch (position) {
      case 2:
        return classes.maximizeSecond;
      case 3:
        return classes.maximizeThird;
      case 4:
        return classes.maximizefourth;
      default:
        return null;
    }

  };

  toggleMaximize = () => {
    this.setState({ maximize: !this.state.maximize });
  };

  render() {
    let currencyType = this.props.currencyType;
    let panel = this.props.panel;
    let showMaxIcon = this.props.showMaxIcon;
    let img = null;
    if (currencyType !== 'OTHER') {
      const images = require.context('../../../../assets/img', true);
      img = images('./EUR.png');
    }

    return (
      <div className={classes.graphContainer}>
        <div className={this.state.maximize === true ?
          [classes.maximize, this.maximizePosition(this.props.position)].join(' ') :
          classes.showInWidget}>

          {!this.state.maximize ? <MiniGraphHeader panel={panel} currencyType={currencyType} showMaxIcon={showMaxIcon} toggleMaximize={this.toggleMaximize} /> :
            <MaxiGraphHeader showMaxIcon={showMaxIcon} toggleMaximize={this.toggleMaximize} />}

          {
            currencyType !== 'OTHER' ?
              !this.state.maximize ?
                <div style={{ width: '100%' }}><img src={img} alt={currencyType} style={{ width: '100%' }} /></div> :
                <div style={{ width: '100%' }}><img src={MaximizedGraph} alt="MaximizedGraph" style={{ width: '100%' }} /></div> :
              <div style={{ width: '100%' }}><img src={BlankGraph} alt="BlankGraph" style={{ width: '100%' }} /></div>}


        </div>
      </div>);

  }
}


export default Graph;