import gql from 'graphql-tag';


export default gql`

query ($attributes:FilterCurrencyTraderInput, $status:String) {
  currencyTraderOrders(attributes:$attributes, status:$status) {
    ... on PurchaseOrder {
      amountPaidCents
      id
      amountPaidCurrency
      amountPurchasedCents
      amountPurchasedCurrency
      datetime
      rate
      number
      expiration
      status
      updatedAt
      availableTradingAmount
      percentagePurchased
      portionPurchased
      typology
      bankAccounts {
        id
        currency
        iban
        bankName
      }
      transactions {
        id
        amount
        parentOrder
        saleOrder {
          rate
        }
      }
      portfolio {
        user {
          firstName
          lastName
          globalName
          name
        }
      }
    }
    ... on SaleOrder {
      id
      amountPaidCents
      amountSoldCents
      amountPaidCurrency
      amountSoldCurrency
      datetime
      rate
      number
      companyRepresentative
      expiration
      status
      updatedAt
      availableTradingAmount
      percentageSold
      portionSold
      typology
      bankAccounts {
        id
        currency
        iban
        bankName
      }
      transactions {
        id
        amount
        parentOrder
        purchaseOrder {
          rate
        }
      }
      portfolio {
        user {
          firstName
          lastName
          globalName
          name
        }
      }
    }
  }
}
`;
