import React from 'react';
import './tabs_module.css';

export const TabContainer = (props) => (
    <div className="tabContainer">
        {props.children}
    </div>
);

export const TabItem = (props) => (
    <div className={`tabItem ${props.active}`} onClick={props.onClick}>
      {props.text}
    </div>
);

export default TabContainer;
