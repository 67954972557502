import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import classes from '../../CashManager.module.scss';
import ExceptionComponent from './ExceptionComponent'
import Button from '../../../../../components/UI/Button/Button';
import receive_client from "../../../../../Client";
import LoggedUser from '../../../../../queries/LoggedUser';
import ExceptionRateGroup from "../../../../../queries/CashManager/ServicePointAdmin/ExceptionalExchangeRates/Group";
import CREATE_GROUP from '../../../../../mutations/CashManager/ServicePointAdmin/ExceptionalExchangeRates/CreateGroup';

const ExchangeRateException = props => {

  const [exceptionGroups, setExceptionGroups] = useState([]);
  const [storeUsers, setStoreUsers] = useState([]);

  //MUTATIONS
  const [createGroup] = useMutation(CREATE_GROUP);

  const receive_query_data = async () => {
    const client = receive_client()
    const result = await client.query({ query: ExceptionRateGroup, variables: { date: props.date }});
    const current_user = await client.query({ query: LoggedUser })

    let employees = current_user.data.currentUser.employees
    let groups = result.data.exchangeRateGroup
    let store_users = employees.filter(el => el.exchangeRateGroup == null)
    setStoreUsers(store_users)
    setExceptionGroups(groups)
  }

  useEffect(() => {
    receive_query_data()
  },[props.date]);

  const create_group = () => {
    createGroup({
      refetchQueries: [{ query: ExceptionRateGroup, variables: { date: props.date }}]
    }).then(result => {
      receive_query_data()
    })
  }

  let existing_exceptions = exceptionGroups.map(group => {
    return(
      <ExceptionComponent data={props.date} key={group.id} store_users = {storeUsers} exceptional_users = {group.users} group_id={group.id} refreshList={() => receive_query_data()}/>
    )
  })

  return (
    <div className={classes.daily_settings_card_small_text} style={{ marginBottom: "20.39px", paddingTop: "12.6" }}>
      {existing_exceptions}
      <Button extraStyle={classes.saveButton} clicked={() => create_group()}> {i18n.t("NEW_EXCEPTION")} </Button>
    </div>);

};

export default ExchangeRateException;
