import React, { Component } from "react";
import classes from "./Headquarters.module.css";
import Plus from "../../../components/UI/Plus/Plus";
import NumberFormat from "react-number-format";
import create_headquarter from "../../../mutations/Registration/CreateHeadquarter";

class ShareholderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      circle_clicked: this.props.selected_circle || false,
      counter: this.props.counter || 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected_circle != this.props.selected_circle) {
      this.setState({
        circle_clicked: this.props.selected_circle,
      });
    }
  }

  subShareholder = (location) => {
    if (location == "same_line") {
      this.props.handleNewchild(this.props.input_name + this.state.counter);
      this.setState({
        counter: this.state.counter + 1,
      });
    } else {
      this.props.handleNewchild(parseInt(this.props.input_name + "1"));
      this.setState({
        circle_clicked: true,
      });
    }
  };

  handleQuota = (quota) => {
    this.props.addQuota(quota);
  };

  handleShareholderName = (e) => {
    this.props.addShareholderName(e.target.value);
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: (this.props.padding_counter - 1) * 70,
        }}
      >
        <div style={{ position: "relative" }}>
          <div
            style={{
              paddingTop: "5px",
              marginRight: "10px",
              marginLeft: "50px",
              position: "absolute",
              left: "-80px",
              top: "10px",
            }}
          >
            {!this.props.plus || this.state.counter == 9 ? null : (
              <Plus clicked={() => this.subShareholder("same_line")} />
            )}
          </div>
          {this.props.editable ? (
            <input
              style={{ width: "330px" }}
              placeholder="Shareholder Name"
              name={this.props.input_name}
              className={classes.inputs}
              value={this.props.name}
              onChange={this.handleShareholderName}
            />
          ) : (
            <div style={{ width: "fitContent", marginTop: "15px" }}>{this.props.name}</div>
          )}
        </div>
        <div
          style={{
            width: "90px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.props.editable ? (
            <div
              className={classes.circle}
              style={this.state.circle_clicked ? { background: "#58595B" } : null}
              onClick={() => (this.state.circle_clicked ? null : this.subShareholder())}
            ></div>
          ) : null}
          <div style={{ width: "60%", marginTop: this.props.editable ? null : "15px" }}>
            <NumberFormat
              style={{ fontSize: "14px", width: "50px" }}
              displayType={this.props.editable ? null : "text"}
              value={this.props.quota}
              className={this.props.editable ? classes.inputs : null}
              suffix={"%"}
              placeholder={"0 %"}
              onValueChange={(value) => this.handleQuota(value.value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ShareholderComponent;
