import i18n from 'i18next';
import React, { Component } from 'react';
import '../../myprofile.scss';
import InfoCard from '../../Extra/infoCard';

export default class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address_display: true,
      addressDetails: false,
      createNew: false,
      icon_show: true,
      address_details: this.props.address
    };

  }


  addressDetailsFunction() {
    var addressDetails = this.state.addressDetails;
    if (!addressDetails) {
      this.setState({ address_display: false, addressDetails: true, createNew: false });
    } else {
      this.setState({ address_display: true, addressDetails: false, createNew: false });
    }
  }

  checkChange(id) {
    this.props.checkChange(id);
  }
  saveCard() {
    this.setState({
      address_display: true,
      addressDetails: false,
      createNew: false,
      icon_show: true
    });

  }
  cancelCard() {
    this.setState({
      address_display: true,
      addressDetails: false,
      createNew: false,
      icon_show: true
    });

  }

  componentDidUpdate = prevProps => {
    if (prevProps !== this.props)
      this.setState({
        address_details: this.props.address
      });

  };

  handleInput = e => {
    let address_details = Object.assign({}, this.state.address_details);
    address_details[e.target.name] = e.target.value;
    this.props.update_data("address", address_details);
  };

  render() {
    let address_display =
      <div>
        {this.props.modifiable ?
          <div className="text-center">
            <div className="row info_card_row">
              <div className="col-md-12">
                <input type="text" className="info_card_input" name="address" placeholder="Street" value={this.state.address_details.address} onChange={this.handleInput} />
              </div>
              <div className="col-md-4">
                <input type="text" className="info_card_input" name="postal_code" placeholder="Postal Code" value={this.state.address_details.postal_code} onChange={this.handleInput} />
              </div>
              <div className="col-md-4">
                <input type="text" className="info_card_input" name="city" placeholder="City" value={this.state.address_details.city} onChange={this.handleInput} />
              </div>
              <div className="col-md-4">
                <input type="text" className="info_card_input" name="country" placeholder="Country" value={this.state.address_details.country} onChange={this.handleInput} />
              </div>
            </div>
          </div> :

          <div>
            <span>{this.state.address_details.address}</span><br />
            {this.state.address_details.postal_code} {this.state.address_details.city}, {this.state.address_details.country}
          </div>}

      </div>;



    let addressDetails_display =
      <div></div>;

    var createNew =
      <div></div>;

    return (
      <div className="row">
        <InfoCard title={i18n.t("ADDRESS1")} icon={this.state.icon_show} plus_button={false}>
          <div style={{ display: "flex", justifyContent: "center", textAlign: "center", paddingTop: "15px" }}>
            {this.state.address_display ? address_display : null}
            {this.state.addressDetails ? addressDetails_display : null}
            {this.state.createNew ? createNew : null}
          </div>
        </InfoCard>
      </div>);

  }
}
