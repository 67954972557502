import gql from 'graphql-tag';
export default gql`
mutation createTopUp($attributes:TopUpInput!){
  createTopUp(attributes:$attributes){
  id
  datetime
  topUpAmountCents
  topUpAmountCurrency
  rate
  topUpPaidCurrency
  createdAt
  updatedAt
  }
}
`;
