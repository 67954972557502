import gql from 'graphql-tag';

export default gql`

mutation updateUser($attributes: UserInput!, $user_id:ID!) {
  updateUser(attributes: $attributes, userId:$user_id) {
    id
  }
}
`;
