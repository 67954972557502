import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import classes from './SignUp.module.scss';
import '../../myprofile.scss';
import "../UserStyle.css";
import { useMutation } from 'react-apollo';
import CreateEmployee from '../../../../../mutations/EmployeeRegistration/CreateEmployee';

const AddEmployee = (props) => {

    const [createEmployee] = useMutation(CreateEmployee);
    const [message, setMessage] = useState(null);
    const [user, setUser] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        name: "",
        surname: ""
    });

    const handleInputChange = (event) => {
        event.persist();
        const fieldName = event.target.name;
        setUser({ ...user, [fieldName]: event.target.value });
    }

    const onSave = () => {
        if (user.email === null || user.email === "" || user.password === null || user.password === "" ||
            user.confirmPassword === null || user.confirmPassword === "" ||
            user.name === null || user.name === "" ||
            user.surname === null || user.surname === "") {
            setMessage(<p style={{ color: "#9A2020" }}>{i18n.t("ENTER_ALL_FIELDS")}</p>)
        }
        else {
            createEmployee({
                variables: {
                    email: user.email,
                    password: user.password,
                    password_confirmation: user.confirmPassword,
                    first_name: user.name,
                    last_name: user.surname
                }
            }).then(result => {
              console.log(result)
                if (result.data.createEmployee === null) setMessage(<p style={{ color: "#9A2020" }}>{i18n.t("CAN_NOT_REGISTER")}</p>)
                else {
                    setMessage(null);
                    props.changeStep(2)
                    props.setUserId(result.data.createEmployee.id)
                }
            }).catch(error => {
                if (error.message.includes("GraphQL error: Invalid input: Email has already been taken"))
                    setMessage(<p style={{ color: "#9A2020" }}>{i18n.t("EMAIL_TAKEN")}</p>)
                else if (error.message.includes("GraphQL error: Invalid input: Password confirmation doesn't match Password, Password is too short (minimum is 6 characters)"))
                    setMessage(<p style={{ color: "#9A2020" }}>{i18n.t("PASSWORD_CONFIRMATION") + ". " + i18n.t("PASSWORD_TOO_SHORT")}</p>)
                else if (error.message.includes("GraphQL error: Invalid input: Password confirmation doesn't match Password"))
                    setMessage(<p style={{ color: "#9A2020" }}>{i18n.t("PASSWORD_CONFIRMATION")}</p>)
                else if (error.message.includes("GraphQL error: Invalid input: Password is too short (minimum is 6 characters)"))
                    setMessage(<p style={{ color: "#9A2020" }}>{i18n.t("PASSWORD_TOO_SHORT")}</p>)
            });
        }
    }

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const email = urlParams.get('email');
        setUser({ ...user, email: email });
    }, [])


    return (
      <div className={classes.Form}>
          {message}
          <p style={{ fontSize: 16 }}><b>{i18n.t("YOUR_EMAIL_ADDRESS")}</b></p>
          <input type="text" className="info_card_input" name="email" style={{textAlign:"center"}} placeholder="Email" value={user.email} onChange={handleInputChange} disabled />
          <p style={{ margin: 15 }}>{i18n.t("INFO")}</p>
          <hr />
          <p style={{ fontSize: 16 }}><b>{i18n.t("CREATE_PASSWORD")}</b></p>
          <p style={{ margin: 15 }}>{i18n.t("INFO2")}</p>
          <input type="password"
              className="info_card_input"
              style={{ width: 192 }}
              name="password"
              placeholder="Password"
              value={user.password}
              onChange={handleInputChange} />
          <input type="password"
              className="info_card_input"
              style={{ width: 192 }}
              name="confirmPassword"
              placeholder="Retype your password"
              value={user.confirmPassword}
              onChange={handleInputChange} />
          <hr />
          <p style={{ fontSize: 16 }}><b>{i18n.t("WHO_YOU_ARE")}</b></p>
          <div>
              <input type="text"
                  className="info_card_input"
                  style={{ width: 192, marginRight: 7 }}
                  name="name"
                  placeholder="Name"
                  value={user.name}
                  onChange={handleInputChange} />
              <input type="text"
                  className="info_card_input"
                  style={{ width: 192 }}
                  name="surname"
                  placeholder="Surname"
                  value={user.surname}
                  onChange={handleInputChange} />
          </div>
          <div className="button_type1" style={{ margin: "20px 0", width: 101 }} onClick={() => onSave()}>{i18n.t("SAVE")}</div>
      </div>
    )
}

export default AddEmployee;
