import i18n from "i18next";
import React, { Component } from "react";
import classes from "../../../CurrencyTrader/DossierModal/BuySell.module.scss";
import ToolbarLogout from "../../../../../components/Toolbar/ToolbarLogout";
import CheckMark from "../../../../../assets/icons/big_check_mark.svg";
class SoldCompleted extends Component {
  imageClick = () => {
    this.props.history.push({
      pathname: "/dashboard",
      state: { summary_tab_active: true },
    });
  };

  render() {
    const order_id = this.props.location.state.order_id;
    const url = `${process.env.REACT_APP_HOME_URL}/file/sales_invoice/${order_id}`;
    return (
      <div>
        <ToolbarLogout />
        <div
          className={classes.Component}
          style={{ margin: "35px auto" }}
        >
          <div className={classes.Head}>
            <p className={classes.TransactionText}>{i18n.t("REGISTRATION_COMPLETED")}</p>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <a href={url}>
                <i
                  className="fas fa-print"
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontSize: "20.33px",
                    marginLeft: "10px",
                    marginRight: "5px",
                  }}
                ></i>
              </a>
              <i
                className="fas fa-times-circle"
                style={{
                  cursor: "pointer",
                  color: "white",
                  fontSize: "20.33px",
                  marginLeft: "10px",
                  marginRight: "15px",
                }}
                onClick={() => this.imageClick()}
              ></i>
            </div>
          </div>
          <div
            className={classes.container}
            style={{ padding: "30px 40px" }}
          >
            <div className={classes.checkoutHeader}></div>
            <div
              className={classes.checkoutCurrencies}
              style={{ height: "260px", flexDirection: "column" }}
            >
              <div className={classes.boldText}>{i18n.t("REGISTRATION_SUCCESSFUL")}</div>
              <img
                onClick={() => this.imageClick()}
                src={CheckMark}
                style={{ width: "192px", height: "147px", color: "#58595B", marginTop: "10px" }}
                alt="checkmark"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SoldCompleted;
