import gql from 'graphql-tag';

export default gql`
subscription {
  currencyTraderOrders {
    ... on PurchaseOrder {
      id
    }
    ... on SaleOrder {
      id
    }
  }
}
`;
