import React from 'react';
import classes from './CustomerRegisterNav.module.scss';
import { NavLink, withRouter } from 'react-router-dom';

const CutomerRegisterNav = (props) => {

    let iconOpacity;

    if (props.location.pathname === '/customerRegister') {
        iconOpacity = 0.3;
    }
    else {
        iconOpacity = 1;
    }

    return (
        <NavLink to='/customerRegister'>
            <span className={['fa-stack', classes.UserIcon].join(' ')} style={{ opacity: iconOpacity }}>
                <i className="fas fa-circle fa-stack-2x" style={{ color: 'white' }}></i>
                <i className="fas fa-user fa-stack-1x" style={{ fontSize: '15px' }}></i>
            </span>
        </NavLink>
    );

}

export default withRouter(CutomerRegisterNav);