import i18n from 'i18next';
import React, { Component } from 'react';
import './myprofile.scss';
import user from '../../../queries/LoggedUser';
import { graphql } from 'react-apollo';
import Contact from './Contact/Contact.js';
import Payments from './Payments/Payments.js';
import User from './User/User.js';

class MyProfileModal extends Component {
  constructor(props) {
    super();
    this.state = {
      activeIcon: 2
    };

  }


  showSubComp = () => {
    switch (this.state.activeIcon) {
      case 0:
        return <Contact />;
      case 1:
        return <Payments />;
      case 2:
        return <User />;
      default:
        return <Contact />;
    }

  };
  render() {
    const user_typology = this.props.data.currentUser.servicePoint.typology;
    return (
      <div id="modal_my_profile">
        <div id="Modal_hide" onClick={() => { this.props.modal_open(); }}></div>

        <div className="modal_container">
          <div className="modal_header">
            <div>{i18n.t("MY_PROFILE")}</div>
            <div onClick={() => { this.props.modal_open(); }}><i className={"fas fa-times-circle "} style={{ color: 'white', fontSize: '25px', cursor: 'pointer' }}></i></div>
          </div>
          <div className="modal_body">

            <div className="icon_container">
              {user_typology === "service_point" || user_typology === "business_account" ? <div className="icon_item" style={this.state.activeIcon === 0 ? { opacity: 0.28 } : { opacity: 1 }} onClick={() => { this.setState({ activeIcon: 0 }); }}><i className="fas fa-map-marked-alt"></i></div> : null}
              {user_typology === "market_viewer" ? null : <div className="icon_item" style={this.state.activeIcon === 1 ? { opacity: 0.28 } : { opacity: 1 }} onClick={() => { this.setState({ activeIcon: 1 }); }}><i className="fas fa-piggy-bank"></i></div>}
              <div className="icon_item" style={this.state.activeIcon === 2 ? { opacity: 0.28 } : { opacity: 1 }} onClick={() => { this.setState({ activeIcon: 2 }); }}><i className="fas fa-user-cog"></i></div>
            </div>

            {this.showSubComp()}

          </div>
        </div>
      </div>);

  }
}


export default graphql(user)(MyProfileModal);
