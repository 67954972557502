import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import classes from '../../CashManager.module.scss';
import get_service_point_shifts_query from '../../../../../queries/CashManager/ServicePointShifts';
import create_service_point_shift_mutation from '../../../../../mutations/CashManager/CreateServicePointShift';
import update_service_point_shift_mutation from '../../../../../mutations/CashManager/UpdateServicePointShift';
import not_updated_service_point_shifts from '../../../../../queries/CashManager/NotUpdatedServicePointShift';
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from '@apollo/react-hooks';
import DailySettingsRate from '../DailySettingsRate';
import getSymbolFromCurrency from 'currency-symbol-map';
import get_personalizations from '../../../../../queries/Personalization/Personalization';

const PeriodComponent = props => {

  const { loading, error, data, refetch } = useQuery(get_service_point_shifts_query, {
    variables: { date: props.date }
  });
  const { refetch: refetch_not_updated } = useQuery(not_updated_service_point_shifts, {
    variables: { date: props.date }
  });

  const [goCreateMutation] = useMutation(create_service_point_shift_mutation, { refetchQueries: [{ query: get_personalizations }] });
  const [goUpdateMutation] = useMutation(update_service_point_shift_mutation);
  const [newOpeningCurrency, setNewOpeningCurrency] = useState(false);
  const [requested_without_closing, setRequestedWithoutClosing] = useState(props.requested_without_closing);
  const [unclosed_currency_alert, set_unclosed_currency_alert] = useState(null);
  const [mismatchClose, setMismatchClose] = useState(false);
  const [mismatchOpen, setMismatchOpen] = useState(false);
  const [mismatchCurrencyID, setMismatchCurrencyID] = useState(null);
  const [modifiable, setModifiable] = useState(false);
  const [input_data, setInputData] = useState(null);

  var dateFormat = require('dateformat');

  var currentDate = new Date(Date.now());
  const current_date = ("0" + currentDate.getDate()) + "/" + ("0" + (currentDate.getMonth() + 1)).slice(-2) + "/" + currentDate.getFullYear();
  const current_time = " " + currentDate.getHours() + ":" + currentDate.getMinutes();


  const handleClickedNew = (type, decision = false) => {
    setInputData(type);
    goCreateMutation({
      variables:
      {
        attributes:
        {
          id: type.id,
          shiftNumber: props.shift_number,
          amountOpenedCurrency: type.currency != null ? type.currency.toUpperCase() : null,
          amountOpenedCents: type.first_element,
          averageOpenedRate: parseFloat(type.second_element)
        },
        mismatch_ignored: decision
      }
    }).then(
      result => {
        refetch();
        props.parenti();
        setNewOpeningCurrency(false);
        setRequestedWithoutClosing(false);
        set_unclosed_currency_alert(null);
        setMismatchOpen(false);
        setMismatchClose(false);
      },
      error => {
        console.log(error)
        if (error.message === "GraphQL error: Opening same currency without closing the previous one") {
          set_unclosed_currency_alert(type.currency.toUpperCase());
          setNewOpeningCurrency(false);
        }
        else if (error.message === "GraphQL error: Opening new period without closing the previous one") {
        }
        else if (error.message === "GraphQL error: mismatch") {
          setMismatchOpen(true);
          setMismatchCurrencyID(type.id);
          setModifiable(true);
          props.mismatch_happened();
        }
      });
  };

  const handleClickedUpdate = async (type, decision = false) => {
    setInputData(type);
    await goUpdateMutation({
      variables:
      {
        attributes:
        {
          id: type.id,
          amountClosedCents: type.first_element,
          amountClosedCurrency: type.currency,
          averageClosedRate: parseFloat(type.second_element)
        },
        mismatch_ignored: decision
      }
    }).then(
      result => {
        setMismatchClose(false);
        setMismatchOpen(false);
        setModifiable(false);
        props.parenti();
      },
      error => {
        if (error.message === "GraphQL error: mismatch") {
          setMismatchClose(true);
          setMismatchCurrencyID(type.id);
          setModifiable(true);
          props.mismatch_happened();
        }
      });

  };

  useEffect(() => {
    refetch();
    refetch_not_updated();
    setRequestedWithoutClosing(props.requested_without_closing);
  });

  const handleNewSession = () => {
    setNewOpeningCurrency(true);
    setMismatchClose(false);
    setMismatchOpen(false);
    setModifiable(false);
    setRequestedWithoutClosing(false);
    set_unclosed_currency_alert(null);
    props.parenti();
  };

  const onConfirmMismatchClose = () => {
    handleClickedUpdate(input_data, true);
  };

  const onConfirmMismatchOpen = () => {
    handleClickedNew(input_data, true);
  };



  if (loading) return null;
  if (error) return `Error! ${error}`; else {
    var listItems = null;

    listItems = data.servicePointShifts.map(record => {

      if (record.shiftNumber === props.shift_number) {
        return (
          <div key={record.id} style={{ marginTop: "12.6px", width: "90%", marginLeft: "auto", marginRight: "auto" }}>
            <DailySettingsRate
              key={record.id.toString() + "open"}
              period_id={record.id}
              type={"period"}
              period_type={"open"}
              currency={record.amountOpenedCurrency}
              currency_symbol={getSymbolFromCurrency(record.amountOpenedCurrency) + " "}
              amount={record.amountOpenedCents}
              action={"open"}
              date={dateFormat(record.timeOpened, "dd/mm/yyyy")}
              time={dateFormat(record.timeOpened, "HH:MM")}
              ave_rate={record.averageOpenedRate}
              action_handeled={true}
              input={props.input}
              onChildClicked={handleClickedNew}
              date_of_creation={dateFormat(record.timeOpened, "dd/mm/yyyy")}
              flex={2}
              marginLeft={"10px"}
              />


            <DailySettingsRate
              key={record.id.toString() + "close"}
              period_id={record.id}
              type={"period"}
              currency={record.amountOpenedCurrency}
              action={"close"}
              currency_symbol={getSymbolFromCurrency(record.amountOpenedCurrency) + " "}
              amount={record.amountClosedCents}
              date={record.timeClosed ? dateFormat(record.timeClosed, i18n.t("DD_MM_YYYY")) : null}
              time={record.timeClosed ? dateFormat(record.timeClosed, "HH:MM") : null}
              action_handeled={true}
              ave_rate={record.averageClosedRate}
              input={props.input}
              onChildClicked={handleClickedUpdate}
              requested_without_closing={unclosed_currency_alert === record.amountOpenedCurrency ? true : requested_without_closing}
              modifiable={unclosed_currency_alert === record.amountOpenedCurrency ? true : record.amountClosedCents === null && requested_without_closing ? true : false}
              date_of_creation={dateFormat(record.timeOpened, "dd/mm/yyyy")}
              flex={2}
              marginLeft={"10px"}
              confirmation={mismatchClose && record.id === mismatchCurrencyID}
              onConfirmMismatch={onConfirmMismatchClose}
              confirmation_modify={modifiable && record.id === mismatchCurrencyID ? true : false} />

          </div>);

      };
    });
  }

  return (
    <div>
      {props.new_period_type ? null : <div className={classes.daily_settings_card_small_text} style={{ marginTop: "20.39px" }}>{i18n.t("PERIOD1")} {props.shift_number}{i18n.t("OPEN_CLOSE")}
      {props.date == current_date ?
          <i
            className="fas fa-plus"
            style={{
              color: "rgb(211, 156, 62)",
              fontSize: "14px",
              marginLeft: "10px"
            }}

            onClick={() => handleNewSession()}>
          </i>
        :
          null
        }

        {!props.new_period_requested ? <div>{listItems}</div> : null}

        {newOpeningCurrency ?
          <div style={{ marginTop: "12.6px", width: "90%", marginLeft: "auto", marginRight: "auto" }}>
            <DailySettingsRate
              period_id={null}
              type="period"
              currency={null}
              action={"open"}
              currency_symbol={null}
              amount={null}
              date={current_date}
              time={current_time}
              action_handeled={true}
              ave_rate={null}
              input={true}
              onChildClicked={handleClickedNew}
              modifiable={true}
              date_of_creation={dateFormat(new Date(), i18n.t("DD_MM_YYYY"))}
              confirmation={mismatchOpen}
              onConfirmMismatch={onConfirmMismatchOpen}
              confirmation_modify={modifiable}/>

          </div> :
          null}

      </div>}

    </div>);

};

export default PeriodComponent;
