import i18n from 'i18next'; 
import React, { Component } from 'react';
import classes from './CustomerRegister.module.scss';
import Button from '../../components/UI/Button/Button';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import update_client from '../../mutations/BuySellRegistration/CreateCustomer';
import customers_query from '../../queries/Customer';


class EditClient extends Component {

        constructor(props) {
                super(props);
                this.state = {
                        typeOfClient: this.props.type,
                        client: this.props.client,
                        address: this.props.client.address,
                        postalCode: this.props.client.postalCode,
                        city: this.props.client.city,
                        country: this.props.client.country,
                        email: this.props.client.email,
                        cellphone: this.props.client.cellphone
                };


        }

        handleOnChange = (e, property) => {
                this.setState({
                        [e.target.name]: e.target.value
                });

        };

        updateClient = (firstName, lastName, address, cellphone, email, city, country, personalIdNumber, postalCode, typology, rea) => {
                this.props.mutate({
                        variables: { attributes: { firstName, lastName, address, cellphone, email, city, country, personalIdNumber, postalCode, typology, rea } },
                        refetchQueries: [{ query: customers_query }]
                });

                this.props.history.push({
                        pathname: "/"
                });

        };

        render() {
                const client = this.state;
                return <div className={classes.ClientDetails}>
                        {this.state.typeOfClient === "INDIVIDUALS" ?
                                <>
                                        <div style={{ fontSize: '25px', marginTop: '20px' }}><b>{this.state.client.firstName} {this.state.client.lastName}</b></div>
                                        <div style={{ marginBottom: '40px' }}><b>{i18n.t("PERSONAL_ID")}</b> {this.state.client.personalIdNumber} </div>
                                </> :
                                <>
                                        <div style={{ fontSize: '25px', marginTop: '20px' }}><b>{this.state.client.firstName}</b></div>
                                        <div style={{ marginBottom: '40px' }}><b>{i18n.t("NIUS")}</b> {this.state.client.rea} </div>
                                </>}

                        <div className={classes.EditForm}>
                                <input
                                        name="address"
                                        style={{ width: "420px" }}
                                        className={classes.Input}
                                        type="text"
                                        value={this.state.address}
                                        onChange={e => this.handleOnChange(e, "address")} />


                                <input
                                        style={{ width: "134px" }}
                                        name="postalCode"
                                        className={classes.Input}
                                        type="text"
                                        value={this.state.postalCode}
                                        onChange={e => this.handleOnChange(e, "postalCode")} />

                                <input
                                        name="city"
                                        style={{ width: "134px" }}
                                        className={classes.Input}
                                        type="text"
                                        value={this.state.city}
                                        onChange={e => this.handleOnChange(e, "city")} />

                                <input
                                        name="country"
                                        style={{ width: "134px" }}
                                        className={classes.Input}
                                        type="text"
                                        value={this.state.country}
                                        onChange={e => this.handleOnChange(e, "country")} />


                                <input
                                        name="email"
                                        style={{ width: "420px", marginTop: '25px' }}
                                        className={classes.Input}
                                        type="text"
                                        value={this.state.email}
                                        onChange={e => this.handleOnChange(e, "email")} />

                                <input
                                        style={{ width: "420px" }}
                                        name="cellphone"
                                        className={classes.Input}
                                        type="text"
                                        value={this.state.cellphone}
                                        onChange={e => this.handleOnChange(e, "cellphone")} />


                        </div>

                        <div className={classes.buttons} style={{ marginTop: '80px' }}>
                                <Button clicked={() => this.updateClient(this.state.client.firstName, this.state.client.lastName, client.address, client.cellphone, client.email, client.city, client.country, this.state.client.personalIdNumber, client.postalCode, this.state.client.typology, this.state.client.rea)}> {i18n.t("SAVE")} </Button>
                                <Button extraStyle={classes.cancelButton} clicked={this.props.onCancel}> {i18n.t("CANCEL")} </Button>
                        </div>

                </div>;
        }
}


export default withRouter(graphql(update_client)(EditClient));