import React, { Component } from 'react';
import classes from './style.css';
import QR from '../../../../../assets/img/qrcode.png';
import order from '../../../../../assets/img/qrcode.png';
import receive_client from "../../../../../Client";
var dateFormat = require('dateformat');

var QRCode = require('qrcode.react');

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: null,
    }
  }

  componentDidMount = async () => {
    this.setState({
      orders: this.props.location.state.orders,
    })
  }

  componentDidUpdate() {
    document.getElementById('bts').remove()
    var table_tr = document.getElementsByTagName('tr')[10]
    var first_tr = table_tr.getElementsByTagName('font')[0]
    var fontSize = window.getComputedStyle(first_tr).fontSize
    var width_tr = table_tr.offsetWidth;
    var new_font = parseInt(300* parseInt(fontSize) / width_tr);
    document.getElementsByTagName('tr')[9].style.fontSize = `${new_font}px`;
    document.getElementsByTagName('tr')[10].style.fontSize = `${new_font}px`;
    window.print()
    document.location.href = "dashboard";
  }


  render() {
  let user = null
  let first_order = null
  if(!this.state.orders){
    return null
  }
  else {
    user = this.state.orders[0].order.portfolio.user
    first_order = this.state.orders[0].order
  }
  return (
    		 <div id="container">
          <table border="0" cellSpacing="0" cellPadding="0" border="0">
          <colgroup></colgroup>
          <colgroup span="2"></colgroup>
          <colgroup></colgroup>
          <tbody>
             <tr>
                <td colSpan="4" align="center"><font face="Arial" size="4">{user.globalName}</font></td>
             </tr>
             <tr>
                <td colSpan="4" align="center"><font face="Arial">{user.servicePoint.fiscalCode}</font></td>
             </tr>
             <tr>
                <td colSpan="4" align="center"><font face="Arial">{user.servicePoint.fullInvoiceAddress}</font></td>
             </tr>
             <tr>
                <td colSpan="4" align="center"><font face="Arial">{user.servicePoint.phone[0]}</font></td>
             </tr>
             <tr>
                <td colSpan="4" align="left"><br/></td>
             </tr>
             <tr>
                <td colSpan="4" align="left"><font face="Arial">Data dhe koha: {dateFormat(first_order.datetime, "dd/mm/yyyy, HH:MM")}</font></td>
             </tr>
             {this.state.orders.map(order => {
               return <InvoiceElements order={order}/>
             })}
             <tr>
                <td className="sm_line_height" colSpan="4" align="left" style={{paddingTop:"20px"}}><font face="Arial">Përjashtuar nga TVSH-ja bazuar në nenin 53, shkronja “d”, të ligjit nr. 92/2014 “Për tatimin mbi vlerën e shtuar në Republikën e Shqipërisë”, të ndryshuar.</font></td>
             </tr>
          </tbody>
          </table>
    		</div>
    )}};

export default Invoice;


const InvoiceElements = (props) => {

  const toCurrency = (amount) => {
    return amount.toLocaleString('ja-JP', { minimumFractionDigits: 2 })
  }

  const toNumber = (currency) => {
    return parseFloat(currency.replace(/(,|[^\d.-]+)+/g, ''))
  }

  const amountCommitted = (commission, paid_amount, main_currency_amount, rate) => {
    if(commission == 0){
      return 0.0
    }
    else{
      let result = (toNumber(paid_amount) - main_currency_amount * rate).toFixed(2)
      return result
    }
  }

  let order = props.order.order
  let fiscalization = props.order.fiscalizationData

  let order_type = order.__typename == "PurchaseOrder" ? "Blerje" : "Shitje"
  let paid_to_client, currency, type, amount_without_commission, main_currency, main_currency_amount, sold_currency, amount_committed
  if (order.__typename == "PurchaseOrder"){
     paid_to_client = order.amountPaidCents
     currency = order.amountPaidCurrency == "ALL" ? "L" : order.amountPaidCurrency
     type = "purchase"
     main_currency = order.amountPurchasedCurrency
     main_currency_amount = toNumber(order.amountPurchasedCents)
     amount_committed = amountCommitted(order.commission, order.amountPaidCents, main_currency_amount, order.rate)
     amount_without_commission = toNumber(order.amountPaidCents) - amount_committed
  }
  else if(order.__typename == "SaleOrder"){
     paid_to_client= order.amountSoldCents
     currency = order.amountPaidCurrency == "ALL" ? "L" : order.amountPaidCurrency
     type = "sale"
     sold_currency = order.amountSoldCurrency
     main_currency = order.amountSoldCurrency
     main_currency_amount = toNumber(order.amountSoldCents)
     amount_committed = amountCommitted(order.commission, order.amountPaidCents, main_currency_amount, order.rate)
     amount_without_commission = toNumber(order.amountPaidCents) - amount_committed
  }

  const total_info = (type) => {
    console.log(amount_committed)
    if(type == "purchase"){
      return `Paguar klientit: -${paid_to_client}`
    }
    else{
      return `Arketuar nga klienti: ${toCurrency(amount_without_commission + (amount_committed != 0 ? toNumber(amount_committed) : 0))}`
    }
  }

  return (
    <div className={classes.SearchEngine} style={props.extraStyle}>
      <tr>
         <td colSpan="4" align="left"><br/></td>
      </tr>
      <tr>
         <td colSpan="4" align="center"><b><font face="Arial" size="3">Faturë e Thjeshtë - {order_type} Valute</font></b></td>
      </tr>
      <tr>
         <td colSpan="4" align="left"><font face="Arial">{total_info(type)}</font></td>
      </tr>
      <tr>
         <td align="left"><br/></td>
         <td align="left"><br/></td>
         <td align="left"><br/></td>
         <td align="left"><br/></td>
      </tr>
      <tr>
         <td className="central_table" align="center"><b><font face="Arial">Monedha</font></b></td>
         <td className="central_table" align="center"><b><font face="Arial">Shuma</font></b></td>
         <td className="central_table" align="center"><b><font face="Arial">Kursi</font></b></td>
         <td className="central_table" align="center"><b><font face="Arial">Shuma Totale</font></b></td>
      </tr>
      <tr>
         <td className="central_table" align="center" valign="bottom"><b><font face="Arial" size="5">{main_currency}</font></b></td>
         <td className="central_table" align="center"><b><font face="Arial" size="5">{toCurrency(main_currency_amount)}</font></b></td>
         <td className="central_table" align="center"><b><font face="Arial" size="5">{order.rate}</font></b></td>
         <td className="central_table" align="center"><b><font face="Arial" size="5">{toCurrency(amount_without_commission)}</font></b></td>
      </tr>
      <tr>
         <td colSpan="4" align="left"><br/></td>
      </tr>
      <tr>
         <td colSpan="4" align="right"><font face="Arial">Komisioni: {order.commission} % ({toCurrency(Math.abs(amount_committed))} {currency})</font></td>
      </tr>
      <tr>
         <td colSpan="4" align="right"><font face="Arial" size="4">Paguar klientit: {paid_to_client}</font></td>
      </tr>
      <tr>
         <td align="left"><br/></td>
         <td align="left"><br/></td>
         <td align="left"><br/></td>
         <td align="left"><br/></td>
      </tr>
      <tr>
         <td className="sm_line_height" colSpan="4" align="left"><font face="Arial">NSLF: {fiscalization.nslf}</font></td>
      </tr>
      <tr>
         <td className="sm_line_height" colSpan="4" align="left"><font face="Arial">NIVF: {fiscalization.nivf}</font></td>
      </tr>
      <tr>
         <td colSpan="4" align="left"><br/></td>
      </tr>
      <tr>
         {fiscalization.qrUrl ?
            <td colSpan="4" rowSpan="6" align="center"><br/>
             <QRCode value={fiscalization.qrUrl}/>
            </td>
         : null}
      </tr>
    </div>
  )
}
