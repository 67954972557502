import i18n from 'i18next';
import React, { Component } from 'react';
import PurchaseList from './PurchaseList';
import classes from './Purchase.module.scss';
import FourDots from '../../../../components/UI/Icons/FourDots/FourDots';
import EURFilterModal from '../../../../containers/Modals/FilterModal/EURFilterModal';
import GET_CURRENCY_TRADER_ORDERS from '../../../../queries/CurrencyTrader/GetOrders';
import GET_CURRENCY_TRADER_ORDERS_SUBSCRIPTION from '../../../../subscriptions/GetCurrencyTraderOrdersSubscription';
import CURRENCY_TRADER_ORDER_COUNTER from '../../../../queries/CurrencyTrader/CountCurrencyTrader';
import Pagination from "react-js-pagination";
import FilteredComponent from "../../../../containers/Modals/FilterModal/FilteredComponent";
import { Query } from 'react-apollo'

var dateFormat = require('dateformat');
const exactMath = require('exact-math');

class Purchase extends Component {
  constructor(props) {
    super();
    this.state = {
      showModal: false,
      orders_to_purchase: [],
      active_page: 1,
      order_counter: 0,
      offset: 0,
      activePage: 1,
      min_rate_value: null,
      max_rate_value: null,
      min_price: null,
      max_price: null,
      min_quantity: null,
      max_quantity: null,
      sorting_element: "rate",
      sorting_order: "ASC",
      loading: true
    };

  }

  getFltr = () => {
    const { min_rate, max_rate, min_price, max_price, min_quantity, max_quantity, sorting_element, sorting_order, offset } = this.state;
    const filtering_elements = {
      buyOnlyCurrency: this.props.currencies,
      minRateValue: min_rate,
      maxRateValue: max_rate,
      minPaidValue: min_price,
      maxPaidValue: max_price,
      minAmount: min_quantity,
      maxAmount: max_quantity,
      sortingElement: sorting_element,
      sortingOrder: sorting_order,
      offset: offset
    }
    return filtering_elements
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    clearInterval(this.interval);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.classList.value.search('filterModalIcon') === -1) {
      this.props.closeModal();
    }
  }

  modalHandler = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  closeModal() {
    this.setState({
      showModal: false
    });
  }

  handlePageChange = async (pageNumber) => {
    await this.setState({
      activePage: pageNumber,
      offset: (pageNumber - 1) * 7
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currencies.toString() !== this.props.currencies.toString()) {
      this.setState({
        offset: 0
      })
    }
  }

  getParent = obj => {
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        obj[key] = null;
      }
    });

    this.setState({
      max_rate: obj.max_rate_value,
      min_rate: obj.min_rate_value,
      min_price: obj.min_paid_value,
      max_price: obj.max_paid_value,
      min_quantity: obj.min_quantity,
      max_quantity: obj.max_quantity,
      sorting_element: obj.sorting_element,
      sorting_order: obj.sorting_order
    });
  };

  onRemoveFilter = async (element, value) => {
    await this.setState({ [element]: null });
  };

  _subscribeToNewOrders = (subscribeToMore, refetch) => {
    subscribeToMore({
      document: GET_CURRENCY_TRADER_ORDERS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        refetch()
      }
    })
  }

  render() {
    let order_list = [];
    var modal = null;
    var list_of_filters = [];
    var stati = this.state;

    Object.keys(stati).forEach(key => {
      if (stati[key] !== null && !["showModal", "offset", "activePage", "user_id", "sorting_order", "sorting_element", "orders_to_purchase", "active_page", "loading", "order_counter"].includes(key)) {
        list_of_filters.push(key);
      }
    });

    list_of_filters = list_of_filters.map(element => {
      return <FilteredComponent key={element} property={element.replace(/_/g, " ")} value={this.state[element]} onRemoveFilter={this.onRemoveFilter} />;
    });

    if (this.state.showModal) {
      modal = <EURFilterModal modal_open={this.modalHandler.bind(this)} closeModal={this.closeModal.bind(this)} get_parent={this.getParent} state={this.state} />;
    }

    return (
      <div className={classes.order_container}>
        <div className='d-flex align-items-center'>
          <div style={{ whiteSpace: "nowrap" }}>
            <b>{this.props.currencies && (this.props.currencies.length > 1 ?
              i18n.t("TAB_BUY_EUR_ALL1") :
              i18n.t("BUY4") + " " + this.props.currencies[0] + "/ALL")}</b>
          </div>
          <div className="filterModalIcon" style={{ position: "relative" }} onClick={() => { this.modalHandler(); }}>
            <FourDots size={18} dots_size={7.37} />
            {modal}
          </div>
        </div>
        <div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", flexFlow: "wrap" }}>
          {list_of_filters}
        </div>
        <Query query={GET_CURRENCY_TRADER_ORDERS} variables={{attributes: this.getFltr()}} fetchPolicy= "network-only">
          {({ loading, error, data, refetch, subscribeToMore }) => {
            if (loading) return null
            if (error) return null
              this._subscribeToNewOrders(subscribeToMore, refetch)
            const orders_to_purchase = data.currencyTraderOrders
            return (
              <div>
                {orders_to_purchase.map(order =>
                  <PurchaseList
                    key={order.id}
                    order_id={order.id}
                    money={order.availableTradingAmount}
                    rate={order.rate}
                    currency={order.amountSoldCurrency}
                    paid_currency = {order.amountPaidCurrency}
                    expireDate={dateFormat(new Date(order.expiration), "dd/mm/yyyy HH:MM")}
                    totalCost={exactMath.mul(order.availableTradingAmount, order.rate).toFixed(2)}
                  />
                )}
              </div>
            )
          }}
        </Query>
        <div className={classes.order_pagination}>
          <Query query={CURRENCY_TRADER_ORDER_COUNTER} variables={{attributes: this.getFltr()}} fetchPolicy= "network-only">
            {({ loading, error, data, refetch, subscribeToMore }) => {
              if (loading) return null
              if (error) return null
                this._subscribeToNewOrders(subscribeToMore, refetch)
              const number_pages = data.countCurrencyTrader
              return (
                <div>
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={7}
                      totalItemsCount={number_pages}
                      pageRangeDisplayed={9}
                      prevPageText={null}
                      nextPageText={null}
                      activeLinkClass={classes.activePage}
                      itemClass={classes.numbers}
                      linkClass={classes.numbers}
                      onChange={this.handlePageChange}
                    />
                </div>
              )
            }}
          </Query>
        </div>
      </div>);
  }
}


export default Purchase;
