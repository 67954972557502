import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import classes from '../../CashManager.module.scss';
import query from '../../../../../queries/CurrencyDetails';
import { useQuery } from "@apollo/react-hooks";

function CurrencyBoxCheckout(props) {

  const [show, setShow] = useState(false);
  const [durations] = useState([
    { symbol: "YTD", value: "year" },
    { symbol: "MTD", value: "month" },
    { symbol: "WTD", value: "week" },
    { symbol: "TODAY", value: "day" }]);

  let [counter, setCounter] = useState(null);
  let [currency, setCurrency] = useState(null);
  let [duration, setDuration] = useState(null);

  const { loading, error, data, refetch } = useQuery(query, {
    variables: { currency: currency, duration: duration, typology: "service_point" }
  });


  useEffect(() => {

    setCurrency(props.type);
    setDuration(props.duration);

    for (var i = 0; i < durations.length; i++) {
      if (durations[i].symbol === props.symbol) {
        setCounter(i);
      }
    }
  }, [props.symbol, props.type, props.duration]);

  var icon_style = "closedIcon";
  var extra_style = "d-none";
  var icon_color = "white";

  if (show) {
    icon_style = "openIcon";
    extra_style = "";
    icon_color = "rgb(211, 156, 62)";
  };

  var Bottom_hr =
    <div className={classes.hr_seperator} style={{ marginTop: '15px', marginBottom: '15px' }}></div>;


  const change_duration = () => {

    if (counter === 3) {
      setCounter(0);
      setDuration(durations[0].value);
    } else {
      let cou = counter;
      cou++;
      setCounter(cou);
      setDuration(durations[cou].value);
    }

  };

  let result = null;
  if (error) return `Error! ${error}`;
  if (!loading) {
    result = data.currencyDetail;
    refetch();
  }


  return (
    <div>
      <div className={classes.currencyBox}>
        <div className={classes.currencyLeftPart}>
          <div style={{ display: 'flex', alignItems: 'center', height: '32px', justifyItems: 'center' }}>
            <div className={['infoIcon', icon_style].join(' ')} onClick={result ? () => setShow(!show) : null}>
              <i className="fas fa-info" style={{ color: icon_color }}></i>
            </div>
            <div className={classes.currencyTypeText}>{currency}</div>
          </div>
        </div>
        <div className={classes.currencyRightPart}>
          {result ? <div>
            <div className={classes.currencyMoney} style={{ height: "30px" }}>{result.totalBalance}</div>
            <div className={[extra_style, 'position-relative'].join(' ')}>
              <div className={classes.currentExtraSeperator}></div>
              <div className={classes.currentExtraText}>
                <b>{i18n.t("NUMBER_OF_TRANSACTIONS")}</b> {result.numberOfTransactions}
                <div className={classes.ShareSquare} style={duration ? durations[counter].symbol === 'TODAY' ? { left: "-65px" } : null : null} onClick={() => change_duration()}>{duration ? durations[counter].symbol : null}:</div>
              </div>
              <div className={classes.currentExtraText}><b>{i18n.t("BREAKDOWN")}</b> {result.length !== 0 ? result.numberOfPurchases : null} {i18n.t("PURCHASES")} {result.numberOfSales} {i18n.t("SALES")}</div>
              <div className={classes.currentExtraText}><b>{i18n.t("TOTAL_PURCHASE_VALUE")}</b> {result.totalPurchaseValue}</div>
              <div className={classes.currentExtraText}><b>{i18n.t("TOTAL_SALES_VALUE")}</b> {result.totalSaleValue}</div>
              <div className={classes.currentExtraText}><b>{i18n.t("AVERAGE_TRANSACTION_VALUE")}</b>  {result.averageTransactionValue}</div>
              <div className={classes.currentExtraText}><b>{i18n.t("TOTAL_EARNINGS")}</b>  {result.totalEarnings} </div>
              {result.allBalances.length > 0 && props.current_user.isStoreAdmin?
                <div>
                  <div className={classes.currentExtraSeperator}></div>
                  <b style={{ left: "-115px", position:"absolute" }}>BREAKDOWN:</b>
                  {result.allBalances.map(balance => {
                    return(
                      <div key={balance.id} className={classes.currentExtraText}><b>{balance.user.name}{balance.user.isStoreAdmin? " (admin): " : ": "}</b>
                        {balance.amountCents}
                      </div>
                    )
                  })}
                </div>
              :
                null
              }
            </div>
          </div> : <div>---------</div>}
        </div>
      </div>
      {show ? Bottom_hr : null}

    </div>);


}
export default CurrencyBoxCheckout;
