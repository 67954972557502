import React from 'react';
import './four_dots_module.css';

function FourDots(props){
  var size = props.size;
  var dots_size = props.dots_size;
  return(
    <div className="four_dots_container filterModalIcon" style={{height:size,width:size, marginLeft:10, marginRight:10}}>
      <i className="fas fa-circle filterModalIcon" style={{fontSize:`${dots_size}px`}}></i>
      <i className="fas fa-circle filterModalIcon" style={{fontSize:`${dots_size}px`}}></i>
      <i className="fas fa-circle filterModalIcon" style={{fontSize:`${dots_size}px`}}></i>
      <i className="fas fa-circle filterModalIcon" style={{fontSize:`${dots_size}px`}}></i>
    </div>
  );
}


export default FourDots;
