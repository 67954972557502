import React, { Component } from 'react';
import Flag from 'react-world-flags'
import CurrencyFlag from 'react-currency-flags';
import EXCHANGE_RATES from '../../queries/CashManager/ExchangeRates';
import EXCHANGE_RATE_SUBSCRIPTION from '../../subscriptions/ExchangeRateSubscription';
import { Query } from 'react-apollo'

class ProjectRates extends Component {
  constructor(props) {
    super();
  }

  _subscribeToNewExchangeRates = (subscribeToMore, refetch) => {
    subscribeToMore({
      document: EXCHANGE_RATE_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        refetch()
      }
    })
  }

  render() {
    return (
      <table className="table" style={{zoom:"200%", fontFamily:"Arial"}}>
        <thead>
          <tr>
            <td scope="col" style={{color:"#7979ff"}}>MONEDHA</td>
            <td scope="col" style={{color:"#7979ff"}}>BLERJE</td>
            <td scope="col" style={{color:"#7979ff"}}>SHITJE</td>
          </tr>
        </thead>
        <Query query={EXCHANGE_RATES}>
          {({ loading, error, data, refetch, subscribeToMore }) => {
            if (loading) return null
            if (error) return null
            if(data.exchangeRates.length != 0){
              this._subscribeToNewExchangeRates(subscribeToMore, refetch)
            }
            const exchange_rates = data.exchangeRates
            return (
              <tbody>
                {exchange_rates.map(rate =>
                  <tr key={rate.id} style={{fontSize:"40px"}}>
                    <td className="d-flex align-items-center font-weight-bold"><CurrencyFlag currency={rate.currency} width={40} height={27.75}/><span style={{marginLeft:"10px"}}>{rate.currency}</span></td>
                    <td className="font-weight-bold">{rate.purchaseRate}</td>
                    <td className="font-weight-bold">{rate.saleRate}</td>
                  </tr>
                )}
              </tbody>
            )
          }}
        </Query>
      </table>
    );
  }
}

export default ProjectRates;
