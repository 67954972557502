import React, { Component } from 'react';
import i18n from 'i18next';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import classes from '../Login.module.css';
import sendEmailToRecoverPassword from '../../../mutations/Login/SendEmailToRecoverPassword';
import { graphql } from 'react-apollo';
import compose from 'lodash/fp/compose';


class PasswordTool extends Component {
  state = {
    orderForm: {
      email: {
        elementType: "input",
        elementConfig: {
          type: 'email',
          placeholder: 'Email'
        },
        value: "",
        validation: {
          required: true,
          isEmail: true
        },
        valid: false
      }
    },
    listemail: ['a@b', 'mirco@email.com', 'prova'],
    formIsValid: false,
    message: null,
    sentpassword: false
  };


  checkValidity(value, rules) {
    let isValid = true;

    this.setState({ touch: true });

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }
    //trim elimina gli spazi
    if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }
    return isValid;
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const updatedOrderForm = {
      ...this.state.orderForm
    };

    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };


    updatedFormElement.value = event.target.value;
    //VALIDAZIONE
    if (updatedFormElement.validation) {
      updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    }

    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });


  };

  nextHandler = () => {
    //if(!this.state.listemail.includes(this.state.orderForm.email.value))
    if (this.state.orderForm.email.value === "")
      this.setState({ message: <p style={{ color: "#992020" }}>{i18n.t("PLEASE_ENTER_YOUR_EMAIL")}</p> });

    else if (!this.state.orderForm.email.valid)
      this.setState({ message: <p style={{ color: "#992020" }}>{i18n.t("THERE_IS_NO_ACCOUNT_REGISTERED")}</p> });
    else {
      this.props.recoverPassword({
        variables: { email: this.state.orderForm.email.value }
      }).then((data) => {
        const status = data.data.sendEmailToRecoverPassword.success;
        if (status)
          this.setState({ sentpassword: true });
        else
          this.setState({ message: <p style={{ color: "#992020" }}>{i18n.t("THERE_IS_NO_ACCOUNT_REGISTERED")}</p> });

      }).catch((error) => { console.log(error) });
    }
  };

  closeHandler = () => {
    this.props.history.replace({
      pathname: 'close '
    });

  };
  handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.nextHandler();
    }
  }

  keyPress = (e) => {
    if(e.keyCode == 13){
      e.preventDefault();
      this.nextHandler()
    }
   }

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });

    }

    let form =
      <form>
        <div className={classes.Components2}>
          <div className={classes.Head}></div>
          <p className={classes.enterYourEmailText}>{i18n.t("PLEASE_ENTER_YOUR_EMAIL")}</p>
          {this.state.message}
          {formElementsArray.map((formElement) =>
            <Input
              onKeyDown={this.keyPress}
              key={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              changed={event => this.inputChangedHandler(event, formElement.id)}
              onKeyPress={this.handleOnKeyPress} />)}

          <Button
            clicked={() => this.nextHandler()}
            extraStyle={classes.nextButton}>
            {i18n.t("NEXT")}
          </Button>
        </div>
      </form>;


    if (this.state.sentpassword) {
      form =
        <form>
          <div className={classes.Components2}>
            <div className={classes.Head}></div>
            <div className={classes.contentTextLogin}>
              <p className={classes.infoTextLogin}>{i18n.t("AN_EMAIL_HAS_BEEN_SENT_TO_THE")} <br />
                {i18n.t("WITH_INSTRUCTIONS_ON_HOW_TO")} <br />
                {i18n.t("CHANGE_YOUR_PASSWORD")}</p>
              <Button
                clicked={() => this.closeHandler()}
                extraStyle={classes.closeButton}>

                {i18n.t("CLOSE")}
              </Button>
            </div>
          </div>
        </form>;
    }


    return (
      <div>
        <div>
          {form}
        </div>
      </div>);
  }
}


export default compose(
  graphql(sendEmailToRecoverPassword, { name: 'recoverPassword' }))(PasswordTool);
