import i18n from "i18next";
import { Trans, useTranslation } from "react-i18next";
import React, { Component } from "react";
import classes from "./BankAccounts.module.scss";
import Bank from "./Bank";
import bank_account_details from "../../../../../queries/CurrencyTrader/BankAccountDetails";
import { graphql, Query } from "react-apollo";
import LoggedUser from "../../../../../queries/LoggedUser";
import receive_client from "../../../../../Client";

class BankAccountsPopup extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      user_id: null,
      outgoing: true,
      incoming: false,
    };
  }

  componentDidMount = async () => {
    document.addEventListener("mousedown", this.handleClickOutside);
    const client = receive_client();
    const logged_user = await client.query({ query: LoggedUser });
    this.setState({
      user_id: logged_user.data.currentUser.id,
    });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.bankAccountsPopUp();
    }
  }

  handleTypologyChange = () => {
    this.setState({
      incoming: !this.state.incoming,
      outgoing: !this.state.outgoing,
    });
  };

  render() {
    const excel_type = this.state.outgoing ? "outgoing" : "incoming";
    const url = `${process.env.REACT_APP_HOME_URL}/file/user_transfers?user_id=${this.state.user_id}`;
    return (
      <div
        className={classes.container}
        ref={this.setWrapperRef}
      >
        <div className={classes.bigTitleContainer}>
          <div className={classes.title}>
            <Trans i18nKey="TODAY_S_TRANSFERS">
              <span
                onClick={() => this.handleTypologyChange()}
                style={{ color: "#fd6700", cursor: "pointer" }}
              >
                {{ type: this.state.outgoing ? i18n.t("OUTGOING") : i18n.t("INCOMING") }}
              </span>
            </Trans>
          </div>
          <a href={url}>
            <i
              className="fas fa-share-square"
              style={{ fontSize: "23px", marginLeft: "15px", color: "#fd6700", cursor: "pointer" }}
            ></i>
          </a>
        </div>
        <div className={classes.bankComponentContainer}>
          <Query
            query={bank_account_details}
            variables={{ type: this.state.outgoing ? "incoming" : "outgoing" }}
          >
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return `Error! ${error}`;
              return data.bankAccountDetails.bankAccounts.map((bank_account) => {
                return (
                  <Bank
                    key={bank_account.bank_name}
                    icon={bank_account.bank_name}
                    name={bank_account.bank_name}
                    currencies={bank_account.currencies}
                  />
                );
              });
            }}
          </Query>
        </div>
      </div>
    );
  }
}

export default BankAccountsPopup;
