import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './QuickRegistration.module.scss';
import Personalizations from '../../Modals/QuickRegistration/Personalizations';
import { withRouter } from "react-router";
import get_personalizations from '../../../queries/Personalization/Personalization';
import { graphql } from 'react-apollo';
import Amplify from 'aws-amplify';

Amplify.configure({
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
    graphql_headers: async () => ({
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    })
  }
});


class QuickRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showQuickRegistration: this.props.show,
      showPersonalizationModal: false,
      maxNrOfCurrencies: 4,
      loading: true,
      quick_registration: [
        { isChecked: true, value: "EUR", description: "Euro" },
        { isChecked: true, value: "USD", description: "US Dollar" },
        { isChecked: true, value: "GBP", description: "Pound Sterling" },
        { isChecked: true, value: "CHF", description: "Swiss Francs" },
        { isChecked: false, value: "JPY", description: "Japanese Yen" },
        { isChecked: false, value: "AUD", description: "Australian Dollar" },
        { isChecked: false, value: "CAD", description: "Canadian Dollar" },
        { isChecked: false, value: "SEK", description: "Swedish Krona" },
        { isChecked: false, value: "NOK", description: "Norwegian Krone" },
        { isChecked: false, value: "DKK", description: "Danish Krone" }]
    };


  }

  static getDerivedStateFromProps(props, state) {
    if (props.data.loading === false) {
      const personalization = props.data.personalization;

      let quick_registration = state.quick_registration;
      quick_registration.forEach(currency => {
        if (personalization.quickRegistration.includes(currency.value)) {
          currency.isChecked = true;
        } else {
          currency.isChecked = false;
        }
      });
      return {
        quick_registration, loading: false
      };

    } else {
      return null;
    }


  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({ showQuickRegistration: this.props.show });
    }
  }

  togglePersonalizationsModal() {
    this.setState({
      showPersonalizationModal: !this.state.showPersonalizationModal
    });


    if (!this.state.showQuickRegistration) {
      this.setState({
        showQuickRegistration: true
      });

    }
  }

  closePersonalizationModal() {
    this.setState({
      showPersonalizationModal: false
    });

  }

  handleCheckboxEvent(currency_selected, action) {

    let personalizations = JSON.parse(JSON.stringify(this.state.quick_registration));

    let counter = 0;

    personalizations.forEach(currency => {
      if (currency.isChecked === true) {
        counter++;
      }
    });


    personalizations.forEach(currency => {
      if (currency.value === currency_selected.value) {
        if (action === "checked" && counter < 4) {
          currency.isChecked = true;
        } else
          if (action === "remove") {
            currency.isChecked = false;
          }
      }
    });

    this.setState({ personalizations: personalizations });
  }

  handleNext = (path, currencyYouGet) => {
    this.props.history.push({
      pathname: path,
      state: { current_user:this.props.current_user, currencyYouGet: currencyYouGet, currencyYouGive: 'ALL', cross_currency_active: true }
    });

  };

  updateCurryencyList = currency_list => {
    this.setState({
      personalizations: currency_list,
      showPersonalizationModal: false
    });

  };

  render() {
    let cards = null;
    if (!this.state.loading) {
      cards = this.state.quick_registration.map(elem => elem.isChecked ?
        <div className={classes.card} key={elem.value}>
          <div className={classes.cardTitle}> {elem.value} </div>
          <div className="btn-group" role="group" aria-label="Basic example">
            <button type="button" className={["btn", classes.leftButton].join(' ')} onClick={() => this.handleNext("/boughtregistration", elem.value)}>
              {i18n.t("BUYING1")}
            </button>
            <button type="button" className={["btn", classes.rightButton].join(' ')} onClick={() => this.handleNext("/soldregistration", elem.value)}>
              {i18n.t("SELLING1")}
            </button>
          </div>
        </div> : null);

    }

    return (
      <div className={classes.dashboardItem}>
        <div className={classes.dashboardHeader}>
          <div className={classes.dashboardHeaderLeftSide}>
            <div className={classes.dashboardHeaderTitle}> {i18n.t("QUICK_REGISTRATION")} </div>
          </div>
          <div className={classes.dashboardHeaderRightSide}>
            <div className="position-relative" onClick={event => { event.stopPropagation(); }}>
              <i id="personalizationModalIconQR" className="fas fa-pen" onClick={this.togglePersonalizationsModal.bind(this)}></i>
              {this.state.showPersonalizationModal ?
                <Personalizations
                  currencies={this.state.quick_registration}
                  maxNrOfCurrencies={this.state.maxNrOfCurrencies}
                  onChangeEvent={this.handleCheckboxEvent.bind(this)}
                  toggle={this.togglePersonalizationsModal.bind(this)}
                  closePersonalizationModal={this.closePersonalizationModal.bind(this)}
                  set_currency_list={this.updateCurryencyList} /> :

                null}
            </div>
          </div>
        </div>

        {this.state.showQuickRegistration ?
          <div className={classes.dashboardItemBody}>

            {cards}

          </div> : null}
      </div>);

  }
}


export default graphql(get_personalizations)(withRouter(QuickRegistration));
