import gql from 'graphql-tag';

export default gql`
query($currency: String!, $duration: String, $typology:String!) {
  currencyDetail(currency:$currency, duration:$duration, typology:$typology){
    totalBalance
    numberOfTransactions
    numberOfPurchases
    numberOfSales
    totalPurchaseValue
    totalSaleValue
    averageTransactionValue
    totalEarnings
    limit
    allBalances{
     id
     amountCents
     amountCurrency
     user{
      id
      name
      isStoreAdmin
     }
    }
  }
}

`;
