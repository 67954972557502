import React, { Component } from 'react';
import classes from './graph.module.css';
import MarketRatesGraph from './MarketRatesGraph';
import BlankGraph from '../../../../assets/img/BlankGraph.svg';

class Graph extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      maximize: false
    };

  }

  maximizePosition = position => {
    switch (position) {
      case 2:
        return classes.maximizeSecond;
      case 3:
        return classes.maximizeThird;
      case 4:
        return classes.maximizefourth;
      default:
        return null;
    }

  };

  toggleMaximize = () => {
    this.setState({ maximize: !this.state.maximize });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.maximize === nextState.maximize) return false
    else return true
  }

  render() {
    let currency = this.props.currency;

    return (
      <div className={classes.graphContainer}>
        <div className={this.state.maximize === true ?
          [classes.maximize, this.maximizePosition(this.props.position)].join(' ') :
          classes.showInWidget}>
          {currency !== 'OTHER' ?
            (!this.state.maximize ?
              <div style={{ width: '100%' }}><MarketRatesGraph currency={currency} height={380} width={this.props.width} toggleSize={this.toggleMaximize} maximize={this.state.maximize} showMaxIcon={this.props.showMaxIcon} /></div> :
              <div style={{ width: '100%' }}><MarketRatesGraph currency={currency} height={600} width={850} toggleSize={this.toggleMaximize} maximize={this.state.maximize} showMaxIcon={this.props.showMaxIcon} /></div>) :
            <div style={{ width: '100%' }}><img src={BlankGraph} alt="BlankGraph" style={{ width: '100%' }} /></div>}
        </div>
      </div>);

  }
}


export default Graph;