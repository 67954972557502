import React, { Component } from 'react';
import receive_client from "../../../Client";
import users_query from '../../../queries/LoggedUser';

export default class ShareSquare extends Component {
  constructor(props) {
    super();
    this.state = {
    }
  }

  componentDidMount = async () => {
    const client = receive_client()
    const result = await client.query({ query: users_query })
    this.setState({
      user_id: result.data.currentUser.id
    })
  }

  render() {
    const daily_report_url = `${process.env.REACT_APP_HOME_URL}/file/daily_report?user_id=${this.state.user_id}`
    return (
      <div className='position-relative' style={{ cursor: 'pointer' }}>
        <a href={daily_report_url}>
          <i className={"fas fa-share-square"} style={{ color: 'white', fontSize: '20px', justifyContent: 'baseline', marginRight: "10px" }}></i>
        </a>
      </div>
    )
  }
}
