import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../../CurrencyTrader/DossierModal/BuySell.module.scss';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import query from '../../../../queries/Customer';
import FilterResults from 'react-filter-search';



class CustomerRegistration extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      clients_dropdown: false,
      individual_clients: [],
      business_clients: [],
      bank_clients: [],
      service_point_clients: [],
      autocompleted_client: null,
      filled: false,
      active_option: 0,
      autocompleted: false,
      update_on_autocomplete: false,
      individual: true,
      business: false,
      bank: false,
      servicePoint: false,
      firstName: "",
      lastName: "",
      personalIdNumber: [],
      address: "",
      postalCode: "",
      city: "",
      country: "",
      email: "",
      cellphone: "",
      rea: "",
      typology: "individual",
      company_rapresentatives: []
    };

  }

  showIndividuals = () => {
    this.props.history.push({
      pathname: this.props.type === "bought" ? '/boughtindividuals' : '/soldindividuals'
    });

  };
  showBusiness = () => {
    this.props.history.push({
      pathname: '/boughtbusiness'
    });

  };

  handleRadioButtons = (type, index) => {

    if (index === 0) {
      this.setState({
        individual: !this.state.individual,
        active_option: 0,
        business: false,
        bank: false,
        servicePoint: false,
        typology: type
      });

    } else
      if (index === 1) {
        this.setState({
          business: !this.state.business,
          active_option: 1,
          individual: false,
          bank: false,
          servicePoint: false,
          typology: type
        });

      } else

        if (index === 2) {
          this.setState({
            bank: !this.state.bank,
            active_option: 2,
            business: false,
            individual: false,
            servicePoint: false,
            typology: type
          });

        } else
          if (index === 3) {
            this.setState({
              servicePoint: !this.state.servicePoint,
              active_option: 3,
              business: false,
              individual: false,
              bank: false,
              typology: type
            });

          }

    this.setState({
      firstName: "",
      lastName: "",
      personalIdNumber: "",
      address: "",
      postalCode: "",
      city: "",
      country: "",
      email: "",
      cellphone: "",
      rea: ""
    });

  };

  handleInput = async e => {

    if (e.target.name === "personalIdNumber") {
      await this.setState({ [e.target.name]: [e.target.value] });
    } else {
      await this.setState({ [e.target.name]: e.target.value });
    }


    let personal_id_changed = false;
    let other_input_changed = false;

    let state = this.state;
    let autocompleted_client = this.state.autocompleted_client;

    if (autocompleted_client) {
      Object.keys(this.state.autocompleted_client).forEach(function (key) {
        if (key !== "id" && key !== "__typename") {
          if (state[key] !== autocompleted_client[key] && key === "personalIdNumber") {
            personal_id_changed = true;
            return true;
          } else
            if (state[key] !== autocompleted_client[key]) {
              other_input_changed = true;
              return true;
            }
        }
      });
    }


    if (!personal_id_changed && other_input_changed) {
      this.setState({
        update_on_autocomplete: true
      });

    } else {
      this.setState({
        update_on_autocomplete: false
      });

    }


    this.props.sendData(this.state);
  };


  handleInputClick = () => {
    this.setState({
      clients_dropdown: true
    });

  };

  componentDidUpdate = () => {

    let individual_clients = [];
    let business_clients = [];
    let bank_clients = [];
    let service_point_clients = [];

    if (this.state.filled !== true) {
      if(this.props.data && this.props.data.customers){
      this.props.data.customers.map(client => {
        if (client.typology === "individual") {
          individual_clients.push(client);
        } else
          if (client.typology === "business") {
            business_clients.push(client);
          } else
            if (client.typology === "bank") {
              bank_clients.push(client);
            } else
              if (client.typology === "service_point") {
                service_point_clients.push(client);
              }
              return null
      });
      this.setState({
        individual_clients,
        business_clients,
        bank_clients,
        service_point_clients,
        filled: true
      });

    }
    }

  };

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        clients_dropdown: false
      });

    }
  }

  autoGenerateData = async id => {

  await this.props.data.customers.forEach(client => {
      if (client.id === id) {
        this.setState({
          firstName: client.firstName,
          lastName: client.lastName,
          address: client.address,
          postalCode: client.postalCode,
          city: client.city,
          country: client.country,
          email: client.email,
          cellphone: client.cellphone,
          personalIdNumber: this.state.typology === "individual" ? client.personalIdNumber : [],
          rea: client.rea,
          clients_dropdown: false,
          autocompleted: true,
          autocompleted_client: client,
          update_on_autocomplete: false,
          company_rapresentatives: ["business", "service_point", "bank"].includes(this.state.typology) ? client.personalIdNumber : []
        });

      }
    });
    this.props.sendData(this.state);
  };


  render() {

    var party = null;
    if (this.state.active_option === 0) {
      party = <p className={classes.someText}>{i18n.t("SINCE_THIS_TRANSACTION_IS_OVER")}<br /> {i18n.t("YOU_WILL_NEED_TO_IDENTIFY_YOUR")}</p>;
    } else {
      party = <p className={classes.someText}>{i18n.t("SINCE_THIS_TRANSACTION_IS_OVER")}<br /> {i18n.t("YOU_WILL_NEED_TO_IDENTIFY_YOUR1")}</p>;
    }

    var partyData = null;

    if (this.state.active_option === 0) {
      partyData =
        <div className={classes.firstForm}>
          <div className={classes.doubleInput}>
            <div className={"position-relative"} style={{ width: "48%" }}>
              <input
                name="firstName"
                placeholder="Name"
                className={classes.inputs}
                onChange={this.handleInput}
                autoComplete="off"
                autocomplete="new-password"
                value={this.state.firstName}
                onClick={this.handleInputClick} />

              {this.state.clients_dropdown ?
                <div ref={this.setWrapperRef} style={{ maxHeight: "250px", overflow: "auto", background: "#ffff", position: "absolute", top: "45px", width: "100%", float: "left" }}>
                  <FilterResults
                    value={this.state.firstName}
                    data={this.state.individual_clients}
                    renderResults={(results) =>
                      <div>
                        {results.map((client) =>
                          <div key={client.id} style={{ marginLeft: "15px", display: "flex", flexDirection: "flex-start", cursor: "pointer" }} onClick={() => this.autoGenerateData(client.id)}>
                            {client.firstName} {client.lastName}
                          </div>)}

                      </div>} />


                </div> :
                null}
            </div>

            <div style={{ width: "48%" }}>
              <input
                name="lastName"
                placeholder="Surname"
                className={classes.inputs}
                onChange={this.handleInput}
                value={this.state.lastName} />

            </div>
          </div>
          <div className={classes.singleInput}>
            <input
              name="personalIdNumber"
              placeholder="Personal ID number"
              className={classes.inputs}
              onChange={this.handleInput}
              value={this.state.personalIdNumber} />

          </div>
          <p> <strong onClick={this.showIndividuals}>{i18n.t("CLICK_HERE")}</strong> {i18n.t("IF_THE_CUSTOMER_IS_ALREADY_A_C")}</p>
        </div>;

    } else {
      let data = null;
      switch (this.state.active_option) {
        case 1:
          data = this.state.business_clients;
          break;
        case 2:
          data = this.state.bank_clients;
          break;
        case 3:
          data = this.state.service_point_clients;
          break;
        default:
          data = this.state.business_clients;
      }

      partyData =
        <div className={classes.firstForm}>
          <div style={{ position: "relative" }} className={classes.singleInput}>
            <input
              name="firstName"
              placeholder="Business name"
              className={classes.inputs}
              autoComplete="off"
              autocomplete="new-password"
              onChange={this.handleInput}
              value={this.state.firstName}
              onClick={this.handleInputClick} />

            {this.state.clients_dropdown ?
              <div ref={this.setWrapperRef} style={{ maxHeight: "250px", overflow: "auto", background: "#ffff", position: "absolute", top: "45px", width: "100%", float: "left" }}>
                <FilterResults
                  value={this.state.firstName}
                  data={data}
                  renderResults={(results) =>
                    <div>
                      {results.map((client) =>
                        <div key={client.id} style={{ marginLeft: "15px", display: "flex", flexDirection: "flex-start", cursor: "pointer" }} onClick={() => this.autoGenerateData(client.id)}>
                          {client.firstName} {client.lastName}
                        </div>)}

                    </div>} />


              </div> :
              null}
          </div>
          <div className={classes.doubleInput}>
            <div style={{ width: "48%" }}>
              <input
                name="rea"
                placeholder="NUIS"
                className={classes.inputs}
                onChange={this.handleInput}
                value={this.state.rea} />


            </div>

            <div style={{ width: "48%" }}>
              <input
                name="personalIdNumber"
                placeholder="Personal ID number"
                className={classes.inputs}
                onChange={this.handleInput}
                value={this.state.personalIdNumber} />

            </div>
          </div>

          <p> <strong onClick={this.showBusiness}>{i18n.t("CLICK_HERE")}</strong> {i18n.t("IF_THE_CUSTOMER_IS_ALREADY_A_C")}</p>
        </div>;

    }

    return (
      <div>
        <div className={classes.firstPart}>

          <div className={classes.checkoutCurrencies}>
            <div>
              {party}

              <div className={classes.radioBtns} style={{ justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", marginRight: 10 }}>
                  <input type="radio" name="individual" id="radio-one" className={classes.formRadio}
                    value="Individual"
                    description="Individual"
                    checked={this.state.individual}
                    onChange={() => this.handleRadioButtons("individual", 0)} />

                  <div className={classes.normalText}>{i18n.t("INDIVIDUAL")}</div>
                </div>

                <div style={{ display: "flex", marginRight: 10 }}>
                  <input type="radio" name="business" id="radio-two" className={classes.formRadio}
                    value="Business"
                    description="Business"
                    checked={this.state.business}
                    onChange={() => this.handleRadioButtons("business", 1)} />

                  <div className={classes.normalText}>{i18n.t("BUSINESS")}</div>
                </div>

                <div style={{ display: "flex", marginRight: 10 }}>
                  <input type="radio" name="bank" id="radio-three" className={classes.formRadio}
                    value="Bank"
                    description="Bank"
                    checked={this.state.bank}
                    onChange={() => this.handleRadioButtons("bank", 2)} />

                  <div className={classes.normalText}>{i18n.t("BANK")}</div>
                </div>

                <div style={{ display: "flex", marginRight: 10 }}>
                  <input type="radio" name="servicePoint" id="radio-four" className={classes.formRadio}
                    value="Service_point"
                    description="ServicePoint"
                    checked={this.state.servicePoint}
                    onChange={() => this.handleRadioButtons("service_point", 3)} />

                  <div className={classes.normalText}>{i18n.t("SERVICE_POINT1")}</div>
                </div>

              </div>
              {this.state.update_on_autocomplete ? <div style={{ color: "red", fontSize: "12px", marginTop: "10px" }}>
                {i18n.t("YOU_HAVE_UPDATED_A_FIELD_FOR_T")}</div> :
                null}
            </div>

          </div>
        </div>

        <div className={classes.checkoutCurrencies} style={{ height: "450px", flexDirection: "column" }}>

          {partyData}

          <div className={classes.secondForm}>
            <div className={classes.singleInput}>
              <input
                name="address"
                placeholder="Street Address"
                className={classes.inputs}
                onChange={this.handleInput}
                value={this.state.address} />

            </div>


            <div className={classes.doubleInput}>
              <div style={{ width: "31%" }}>
                <input
                  name="postalCode"
                  placeholder="Postal code"
                  className={classes.inputs}
                  onChange={this.handleInput}
                  value={this.state.postalCode} />

              </div>

              <div style={{ width: "31%" }}>
                <input
                  name="city"
                  placeholder="City"
                  className={classes.inputs}
                  onChange={this.handleInput}
                  value={this.state.city} />

              </div>
              <div style={{ width: "31%" }}>
                <input
                  name="country"
                  placeholder="Country"
                  className={classes.inputs}
                  onChange={this.handleInput}
                  value={this.state.country} />

              </div>
            </div>
          </div>
          <div className={classes.secondForm}>
            <div className={classes.singleInput}>
              <input
                name="email"
                placeholder="Email"
                className={classes.inputs}
                onChange={this.handleInput}
                value={this.state.email} />

            </div>
            <div className={classes.singleInput}>
              <input
                name="cellphone"
                placeholder="Phone"
                className={classes.inputs}
                onChange={this.handleInput}
                value={this.state.cellphone} />

            </div>
          </div>

          <div className={classes.firstForm}>
            <p className={classes.someText}>{i18n.t("PLEASE_REATAIN_A_COPY_OF_THE_C")}</p>
          </div>

        </div>

      </div>);

  }
}


export default graphql(query)(withRouter(CustomerRegistration));
