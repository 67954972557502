import gql from 'graphql-tag';

export default gql`

mutation updateServicePointShift($attributes:ServicePointShiftInput!, $mismatch_ignored:Boolean){
  updateServicePointShift(attributes:$attributes, mismatchIgnored:$mismatch_ignored) {
  id
  amountOpenedCurrency
  amountOpenedCents
  amountClosedCents
  averageOpenedRate
  averageClosedRate
  }
}

`;
