// PrivateRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, currentUser, ...rest }) => {
  let current_user = currentUser ? currentUser.state : null

return(
  <Route
    {...rest}
    render={props =>
      current_user == "active"
        ? <Component currentUser={current_user} {...props} />
        : <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />}
  />
)

}

export default PrivateRoute;
