import i18n from 'i18next';
import React, { Component } from 'react';

import Toolbar from '../../components/Toolbar/Toolbar';
import classes from './Introduction.module.css';
import Bull from '../../assets/img/bull.png';

class Introduction extends Component {


  render() {

    return (
      <div className={classes.Introduction}>
        <Toolbar />
        <img src={Bull} alt="Logo"></img>
        <div className={classes.Text}>
          <h2>{i18n.t("WELCOME_TO")}</h2>
          <h1>{i18n.t("UNION_CHANGE_PORTAL")}</h1>
          <p> {i18n.t("LOREM_IPSUM_DOLOR_SIT_AMET_CO")}</p>
          <p> {i18n.t("FAUCIBUS_PULVINAR_ELEMENTUM_IN")} </p>
          <p> {i18n.t("ODIO_UT_ENIM_BLANDIT_VOLUTPAT")}</p>
          <p> {i18n.t("SEM_NULLA_PHARETRA_DIAM_SIT_AM")} </p>
        </div>
      </div>);

  }
}


export default Introduction;
