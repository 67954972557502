import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './Purchase.module.scss';
import Button from '../../../../components/UI/Button/Button';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import getSymbolFromCurrency from 'currency-symbol-map'

class PurchaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {};

  }

  handleBuyButton = () => {
    this.props.history.push({
      pathname: "/buyCurrency",
      state: { total_available: this.props.money, rate: this.props.rate, order_id: this.props.order_id, currency: this.props.currency }
    });

  };

  render() {
    return (
      <div className={[classes.order_card_container, classes.purchases_posted].join(' ')}>
        <div className={classes.order_card_header}>
          <div className={classes.order_card_block}></div>
          <div style={{ paddingRight: "0px" }} className={classes.order_card_details}>
            <div className={classes.order_card_details_row} style={{ height: '30px' }}>
              <div className={classes.order_card_details_left}>
                <div className={classes.order_card_logo}>
                  <i className="fas fa-user" style={{ fontSize: '14px' }} />
                </div>
              </div>
            </div>
            <div className={classes.order_card_details_row} style={{ height: '30px' }}>
              <div className={classes.order_card_bold_text} style={{ flex: 1 }}>
                <NumberFormat className={classes.boldText} style={{ marginLeft: "5px" }} displayType={"text"} thousandSeparator={true} prefix={getSymbolFromCurrency(this.props.currency)} value={this.props.money} />
              </div>
              <div className={classes.order_card_bold_text}><span style={{ fontWeight: 500 }}> @ </span> {this.props.rate.toFixed(2)}</div>
            </div>
            <div className={classes.order_card_details_row} style={{ height: '20px' }}>
              <div className={classes.order_card_small_text} style={{ flex: 1 }}> {i18n.t("EXPIRES")} {this.props.expireDate}</div>
              <div className={classes.order_card_small_text}>{i18n.t("TOTAL_COST")}
                <NumberFormat className={classes.boldText} style={{ marginLeft: "5px" }} displayType={"text"} thousandSeparator={true} suffix={" " + getSymbolFromCurrency(this.props.paid_currency)} value={this.props.totalCost} />
              </div>
            </div>
          </div>
          <div className={classes.order_card_button} style={{ paddingTop: "35px" }}>
            <Button clicked={this.handleBuyButton}>{i18n.t("BUY1")}</Button>
          </div>
        </div>
      </div>);

  }
}

export default withRouter(PurchaseList);
