import i18n from 'i18next';
import React, { Component } from 'react';
import '../../myprofile.scss';
import InfoCard from '../../Extra/infoCard';

export default class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_display: true,
      emailDetails: false,
      createNew: false,
      icon_show: true,
      email_list: this.props.email_list,
      new_email: ""
    };

  }

  componentDidUpdate = prevProps => {
    if (prevProps !== this.props)
      this.setState({
        email_list: this.props.email_list
      });

  };

  EmailDetailsFunction() {
    var emailDetails = this.state.emailDetails;
    if (!emailDetails) {
      this.setState({ email_display: false, emailDetails: true, createNew: false });
    } else {
      this.setState({ email_display: true, emailDetails: false, createNew: false });
    }
  }
  addNewFunction() {
    this.setState({
      email_display: false,
      emailDetails: false,
      createNew: true
    });

  }
  saveCard = async () => {
    let email_list = [...this.state.email_list];
    email_list.push(this.state.new_email);
    await this.props.update_data("email_list", email_list.filter(email => { return email !== ""; }));
    this.props.fire_mutation();
    this.setState({
      email_display: true,
      emailDetails: false,
      createNew: false,
      icon_show: true,
      new_email: ""
    });

  };

  cancelCard() {
    this.setState({
      email_display: true,
      emailDetails: false,
      createNew: false,
      icon_show: true
    });

  }

  checkChangeEmail(index) {
    this.props.checkChangeEmail(index);
  }

  handleInput = (e, index) => {
    let email_list = [...this.state.email_list];
    email_list[index] = e.target.value;
    this.props.update_data(e.target.name, email_list.filter(email => { return email !== ""; }));
  };

  render() {
    var email_display = this.state.email_list.map((email, index) => {
      return <div className="col-md-12" key={index}><div className="pa_text_t1 text-center">
        <span> {this.props.modifiable ? <input className="info_card_input" style={this.props.error ? {border: "1px solid red"} : null} name="email_list" value={email} onChange={e => this.handleInput(e, index)} /> : email} </span>
      </div>
      </div>;
    });

    var createNew =
      <div onClick={event => { event.stopPropagation(); }} style={{ padding: 10, width: '100%' }}>
        <div className="text-center" style={{ padding: 10 }}>
          <div className="row info_card_row">
            <div className="col-md-12">
              <input type="text" className="info_card_input" placeholder="Email" onChange={e => this.setState({ new_email: e.target.value })} />
            </div>
          </div>
        </div>
        <div className="text-center" style={{ marginTop: 15, width: "100%" }}>
          <div className="button_type1" onClick={() => { this.saveCard(); }}>{i18n.t("SAVE")}</div>
          <div className="button_type2" onClick={() => { this.cancelCard(); }}>{i18n.t("CANCEL")}</div>
        </div>
      </div>;


    return (
      <div>
        <InfoCard title="Email" icon={this.state.icon_show} childEvent={this.addNewFunction.bind(this)} plus_button={this.props.add_functionality}>
          <div className="row">
            <div style={{ display: "flex", width: '100%', justifyContent: 'center' }}>
              <div>
                {this.state.email_display ? email_display : null}
                {this.state.createNew ? createNew : null}
              </div>
            </div>
          </div>
        </InfoCard>
      </div>);

  }
}
