import React from "react";
import FilterResults from "react-filter-search";
import classes from "./MultiSelectDropdown.module.scss";

const MultiSelectDropdown = (props) => {
  const handleClickedCurrency = (currency, action) => {
    props.handleDropdownChange(currency, action);
  };

  const selected_data_from_list = props.data.map((element) => {
    if (element.isChecked) {
      return (
        <div
          key={element.value}
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginRight: "10px",
            whiteSpace: "nowrap",
          }}
        >
          <i
            onClick={() => handleClickedCurrency(element, "remove")}
            className="fas fa-times"
            style={{
              float: "left",
              cursor: "pointer",
              color: "#fd6700",
              fontSize: "15px",
              marginRight: "5px",
            }}
          ></i>
          {element.value}
        </div>
      );
    }
    return null;
  });

  return (
    <div style={{ paddingTop: "10px" }}>
      <div style={{ width: props.width || "100%" }}>
        <div className="position-relative">
          <input
            onChange={props.onChange}
            name={props.name}
            autoComplete="off"
            value={props.value}
            onClick={props.onClick}
            style={{
              paddingLeft: "10px",
              width: "100%",
              border: "1px solid #cccc",
              height: "33px",
            }}
          />

          <div
            className="select-box--arrow"
            onClick={props.onClickIcon}
          >
            <span className="select-box--arrow-up"></span>
          </div>
        </div>

        {props.show_dropdown ? (
          <div
            style={{
              maxHeight: "250px",
              overflow: "auto",
              border: "1px solid #cccc",
              borderTop: "none",
            }}
          >
            <FilterResults
              value={props.value}
              data={props.data}
              renderResults={(results) => (
                <div className={classes.option_item}>
                  {results.map((element) =>
                    !element.isChecked ? (
                      <div
                        key={element.value}
                        style={{
                          marginLeft: "15px",
                          display: "flex",
                          flexDirection: "flex-start",
                          cursor: "pointer",
                          fontWeight: "500",
                          marginTop: "3px",
                        }}
                        onClick={() => handleClickedCurrency(element, "checked")}
                      >
                        <div>
                          {element.value} {element.description}
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              )}
            />
          </div>
        ) : null}
        <div
          className={classes.personalizations_lightgray}
          style={{ marginTop: "10px", marginBottom: "10px", fontSize: "12px" }}
        >
          {props.description}
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", flexDirection: props.flex }}>
        {selected_data_from_list}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
