import gql from 'graphql-tag';

export default gql`
query($attributes:FilterTopUpInput) {
  topUps(attributes:$attributes){
    id
    datetime
    topUpAmountCents
    topUpAmountCurrency
    rate
    topUpPaidCurrency
    description
    category
    issuedToUserId
    user{
      name
    }
  }
}
`;
