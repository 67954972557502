import i18n from 'i18next'; 
import React, { Component } from 'react';
import '../../myprofile.scss';
import InfoCard from '../../Extra/infoCard';

export default class Certificates extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div>
        <InfoCard title="Certificates">
          <div className="row" style={{ textAlign: "center", fontSize: "14px", marginTop: "10px" }}>
            <div style={{ width: "100%" }}><b>{i18n.t("REGISTRATION")}</b> {this.props.registration_number}</div>
            <div style={{ width: "100%" }}><b>{i18n.t("LICENCE")}</b> {this.props.licence_number}</div>
          </div>
        </InfoCard>
      </div>);

  }
}