import i18n from "i18next";
import React, { Component } from "react";
import classes from "./Orders.module.scss";
import Button from "../../../../components/UI/Button/Button";
import Countdown from "react-countdown";
import NumberFormat from "react-number-format";
import delete_fixed_purchase_order from "../../../../mutations/CurrencyTrader/DeleteFixedPurchaseOrder";
import delete_fixed_sale_order from "../../../../mutations/CurrencyTrader/DeleteFixedSaleOrder";
import query from "../../../../queries/CurrencyTrader/GetOrders";
import { graphql } from "react-apollo";
import compose from "lodash/fp/compose";

import getSymbolFromCurrency from "currency-symbol-map";

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extraInfo: false,
      confirm_cancel_screen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.extraInfo !== this.props.extraInfo) {
      this.setState({
        extraInfo: this.props.extraInfo,
      });
    }
  }

  extraInfoHandler = () => {
    this.setState({
      extraInfo: !this.state.extraInfo,
    });

    this.props.close_other_childs();
  };

  handleCancelOrder = () => {
    if (this.state.confirm_cancel_screen === false) {
      this.setState({
        confirm_cancel_screen: true,
      });
    } else {
      this.delete_fixed_order(this.props.order, this.props.type);
    }
  };

  delete_fixed_order = (id, type) => {
    if (type === "Purchase") {
      this.props
        .delete_purchase({
          variables: { id: id },
        })
        .then((res) => {
          this.setState({
            confirm_cancel_screen: false,
          });

          this.props.refetchQueries();
        });
    } else {
      this.props
        .delete_sale({
          variables: { id: id },
        })
        .then((res) => {
          this.setState({
            confirm_cancel_screen: false,
          });

          this.props.refetchQueries();
        });
    }
  };

  ignoreCancel = () => {
    this.setState({
      confirm_cancel_screen: false,
    });
  };

  render() {
    const symbol = getSymbolFromCurrency(this.props.currency);
    const paid_symbol = getSymbolFromCurrency(this.props.paid_currency);

    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return <div>{i18n.t("EXPIRED")}</div>;
      } else {
        return (
          <span>
            {i18n.t("EXPIRES_IN")} {hours !== 0 ? hours + i18n.t("HRS") : null}
            {minutes !== 0 ? minutes + " min " : null}
            {hours === 0 && minutes === 0 ? seconds + " seconds" : null}
          </span>
        );
      }
    };

    let detailed_rates = [];
    let paying_account = null;
    let receiving_account = null;
    const bankat = this.props.banks;

    if (this.props.type === "Purchase") {
      paying_account = bankat.filter((bank) => bank.currency == this.props.paid_currency);
      receiving_account = bankat.filter((bank) => bank.currency == this.props.currency);
    } else if (this.props.type === "Sale") {
      paying_account = bankat.filter((bank) => bank.currency == this.props.currency);
      receiving_account = this.props.banks.filter(
        (bank) => bank.currency == this.props.paid_currency
      );
    }

    this.props.transactions.map((transaction) => {
      if (this.props.type === "Purchase") {
        if (transaction.parentOrder !== "Purchase") {
          detailed_rates.push(
            <div
              key={transaction.id}
              className={classes.order_card_extra_regular_text}
            >
              {symbol}
              {transaction.amount}
            </div>
          );
        }
      } else {
        if (transaction.parentOrder !== "Sale") {
          detailed_rates.push(
            <div
              key={transaction.id}
              className={classes.order_card_extra_regular_text}
            >
              {symbol}
              {transaction.amount}
            </div>
          );
        }
      }
      return null;
    });

    if (this.state.extraInfo) {
      var extraInfoo = (
        <div className="d-flex">
          <div className={classes.order_card_block}></div>
          <div
            className={classes.order_card_details}
            style={{ borderTop: "solid 1px #cccccc", paddingBottom: "60px" }}
          >
            {this.state.confirm_cancel_screen ? (
              <div className={classes.cancel_confirmation_div}>
                <div>{i18n.t("ARE_YOU_SURE_YOU_WANT_TO_CANCE")}</div>
                {this.props.percentage_portion ? (
                  <div>
                    <div>
                      {(100 - this.props.percentage_portion).toFixed(1)}
                      {i18n.t("OF_YOU_ORDER_WILL_BE_CANCELL")}
                    </div>
                    <div>{i18n.t("THE_OTHER_PART_WILL_BE_SAVED_A")}</div>
                  </div>
                ) : null}

                <div> {i18n.t("THIS_CANNOT_BE_UNDONE")} </div>
              </div>
            ) : null}
            <div className={classes.order_card_extra}>
              <div
                className={classes.order_card_details_row}
                style={{ height: "30px" }}
              >
                <div className={classes.order_card_details_left}>
                  <div className="d-flex-column"></div>

                  <div className={classes.order_card_extra_bold_text}>
                    {this.props.completed ? (
                      <span> {i18n.t("COMPLETED")}</span>
                    ) : this.props.portion !== 0 ? (
                      <div>
                        <div>
                          <span>
                            <NumberFormat
                              style={{ color: "#9A2020" }}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={" " + symbol}
                              value={this.props.portion}
                            />
                          </span>{" "}
                          {i18n.t("OF")}{" "}
                          <span style={{ color: "#9A2020" }}>{this.props.money}</span>{" "}
                          {this.props.type === "Purchase" ? i18n.t("PURCHASED") : i18n.t("SOLD")}
                        </div>
                        <div>
                          <span>
                            <NumberFormat
                              style={{ color: "#9A2020" }}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={" " + symbol}
                              value={this.props.portion_remaining}
                            />
                          </span>{" "}
                          {i18n.t("OF")}{" "}
                          <span style={{ color: "#9A2020" }}>{this.props.money}</span>{" "}
                          {i18n.t("REMAINING")}
                        </div>
                      </div>
                    ) : (
                      <span> {i18n.t("PENDING")}</span>
                    )}
                  </div>
                </div>
                <div className={classes.order_card_extra_bold_text}>
                  {this.props.typology === "Fixed"
                    ? i18n.t("FIXED_ORDER1")
                    : i18n.t("FLEXIBLE_ORDER1")}
                </div>
              </div>
              <div className={classes.order_card_details_twoColumns}>
                <div className={classes.order_card_details_leftColumn}>
                  <div
                    className={[
                      classes.order_card_extra_bold_text,
                      classes.order_card_borderBottom,
                    ].join(" ")}
                  >
                    {" "}
                    {i18n.t("ORDER_DETAILS")}
                  </div>
                  <div className={classes.order_card_extra_bold_text}>{i18n.t("PLACED")}</div>
                  <div className={classes.order_card_extra_regular_text}>{this.props.datetime}</div>
                  <div className={classes.order_card_extra_bold_text}></div>
                  <div className={classes.order_card_extra_bold_text}>{i18n.t("ITEM")}</div>
                  <div className={classes.order_card_extra_regular_text}>{this.props.money}</div>
                  <div className={classes.order_card_extra_regular_text}>
                    {i18n.t("IF_THE_MARKET_RATE_REACHES_AT")}
                  </div>
                  <div className={classes.order_card_extra_regular_text}>
                    {this.props.rate} {paid_symbol}/{symbol}1
                  </div>
                  <div className={classes.order_card_extra_bold_text}></div>
                  {detailed_rates.length !== 0 ? (
                    <div className={classes.order_card_extra_bold_text}>
                      {i18n.t("FURTHER_DETAILS")}
                    </div>
                  ) : null}
                  {detailed_rates}
                  <div className={classes.order_card_extra_bold_text}></div>
                  <div className={classes.order_card_extra_bold_text}></div>
                  <div className={classes.order_card_extra_bold_text}>
                    {i18n.t("ORDER_TOTAL")} {this.props.purchase}
                  </div>
                  <div className={classes.order_card_extra_bold_text}>
                    {i18n.t("EXECUTED_BY")} {this.props.user.name}
                  </div>
                  {this.props.completed ? (
                    <div className={classes.order_card_extra_bold_text}>
                      {i18n.t("PAYMENT_FOR_THIS_ORDER_IS_DUE")}
                    </div>
                  ) : null}
                </div>
                <div className={classes.order_card_details_rightColumn}>
                  <div
                    className={[
                      classes.order_card_extra_bold_text,
                      classes.order_card_borderBottom,
                    ].join(" ")}
                  >
                    {" "}
                    {i18n.t("PAYMENT_METHOD")}
                  </div>
                  <div className={classes.order_card_extra_bold_text}>
                    {i18n.t("RECEIVING_ACCOUNT")}
                  </div>
                  <div className={classes.order_card_extra_regular_text}>
                    <b>{i18n.t("BANK_NAME")}</b> {receiving_account[0].bankName}
                  </div>
                  <div className={classes.order_card_extra_regular_text}>
                    <b>IBAN:</b> {receiving_account[0].iban}
                  </div>
                  <div className={classes.order_card_extra_bold_text}></div>
                  <div className={classes.order_card_extra_bold_text}>
                    {i18n.t("PAYING_ACCOUNT")}
                  </div>
                  <div className={classes.order_card_extra_regular_text}>
                    <b>{i18n.t("BANK_NAME")}</b> {paying_account[0].bankName}
                  </div>
                  <div className={classes.order_card_extra_regular_text}>
                    <b>IBAN:</b> {paying_account[0].iban}
                  </div>
                </div>
              </div>
            </div>
            {!this.props.completed ? (
              <div className={classes.button_container}>
                {this.state.confirm_cancel_screen ? (
                  <Button
                    extraStyle={classes.no_order_button}
                    clicked={this.ignoreCancel}
                  >
                    {" "}
                    {i18n.t("NO")}{" "}
                  </Button>
                ) : null}
                <Button
                  extraStyle={classes.cancel_order_button}
                  clicked={this.handleCancelOrder}
                >
                  {" "}
                  {i18n.t("CANCEL_ORDER")}{" "}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div className={[classes.order_card_container, classes[this.props.status]].join(" ")}>
        <div
          className={classes.order_card_header}
          onClick={() => this.extraInfoHandler()}
        >
          <div className={classes.order_card_block}></div>
          <div className={classes.order_card_details}>
            <div
              className={classes.order_card_details_row}
              style={{ height: "30px" }}
            >
              <div className={classes.order_card_details_left}>
                <div className={classes.order_card_logo}>
                  {" "}
                  {this.props.type === "Purchase" ? i18n.t("P") : i18n.t("S")}{" "}
                </div>
                <div className={classes.order_card_type}>
                  {this.props.type === "Purchase" ? i18n.t("PURCHASE") : i18n.t("SALE")}
                </div>
              </div>
              <div
                className={[classes.order_card_small_text, classes.hiddenText].join(" ")}
                style={{ color: "#9a2020", fontWeight: "bold" }}
              >
                {this.props.completed ? (
                  <div style={{ color: "#58595B" }}>
                    {" "}
                    {this.props.type === "Purchase" ? i18n.t("PURCHASED2") : i18n.t("SOLD2")}{" "}
                    {this.props.completed_time}
                  </div>
                ) : (
                  <Countdown
                    date={this.props.expiration_time}
                    renderer={renderer}
                  />
                )}
              </div>
            </div>
            <div
              className={classes.order_card_details_row}
              style={{ height: "30px" }}
            >
              <div
                className={classes.order_card_bold_text}
                style={{ flex: 1 }}
              >
                {" "}
                {this.props.money}
                {!this.props.completed && this.props.portion !== 0 ? (
                  <span style={{ color: "rgb(154, 32, 32)" }}>
                    <span style={{ color: "#58595B" }}> (</span>
                    {this.props.percentage_portion}%{" "}
                    <span style={{ color: "#58595B" }}>
                      {this.props.type === "purchase" ? i18n.t("PURCHASED") : i18n.t("SOLD")})
                    </span>
                  </span>
                ) : null}
              </div>
              <div className={[classes.order_card_bold_text, classes.hiddenText].join(" ")}>
                {this.props.purchase}
              </div>
            </div>
            <div
              className={classes.order_card_details_row}
              style={{ height: "20px" }}
            >
              <div
                className={classes.order_card_small_text}
                style={{ flex: 1 }}
              >
                {" "}
                {i18n.t("ORDER_NUMBER")} {this.props.order_nr}
              </div>
              <div className={[classes.order_card_small_text, classes.hiddenText].join(" ")}>
                {i18n.t("DESIRED_RATE")} {this.props.rate} {this.props.paid_currency.charAt(0)}/
                {this.props.currency.charAt(0)}{" "}
              </div>
            </div>
          </div>
        </div>
        {extraInfoo}
        <div>{this.state.time}</div>
      </div>
    );
  }
}

export default graphql(query)(
  compose(
    graphql(delete_fixed_purchase_order, { name: "delete_purchase" }),
    graphql(delete_fixed_sale_order, { name: "delete_sale" })
  )(OrderList)
);
