import React, { Component } from 'react';
import classes from './SearchInput.module.scss';


class SearchInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
      showItems: true,
      selectedItem: ""
    };

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({ items: this.props.items });
    }
  }


  handleOnChange = event => {

    this.setState({
      showItems: true,
      selectedItem: event.target.value
    });

  };

  selectItem = item => {
    this.setState({
      selectedItem: item,
      showItems: false
    });


    this.props.onSelectItem(item);
  };

  render() {

    const regex = new RegExp(this.state.selectedItem, "i");

    return (
      <div className={classes.SearchBar}>
        <i className={["fas fa-search fa-lg", classes.SearchIcon].join(' ')}></i>
        <input
          className={classes.SearchInput}
          type="text"
          value={this.state.selectedItem}
          onChange={this.handleOnChange} />


        <div style={{ display: 'flex' }}
          className={classes.SearchItems}>
          {this.state.items.filter(item => item.firstName.search(regex) === 0).map((item) =>
            <div
              key={item.id}
              onClick={() => this.selectItem(item)}
              style={{ marginBottom: '5px', cursor: 'pointer' }}>

              {item.firstName + " " + item.lastName}
            </div>)}
        </div>
      </div>);

  }
}


export default SearchInput;