import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ToolbarLogin from '../../components/Toolbar/ToolbarLogin';
import LoginTool from './LoginTool/LoginTool';
import PasswordTool from './PasswordTool/PasswordTool';
import VerificationTool from './VerificationTool/VerificationTool';
import CodeTool from './CodeTool/CodeTool';

import classes from './Login.module.css';
import ResetPassword from './PasswordTool/ResetPassword';

class Login extends Component {
  render() {
    return (
      <div className={classes.Login}>
        <ToolbarLogin />
        <Switch>
          <Route path={this.props.match.url} exact component={LoginTool} />
          <Route path={this.props.match.url + "/password"} exact component={PasswordTool} />
          <Route path={this.props.match.url + "/verification/:code"} exact component={VerificationTool} />
          <Route path={this.props.match.url + "/code"} exact component={CodeTool} />
          <Route path={this.props.match.url + "/reset_password"} exact component={ResetPassword} />
          <Redirect from="/login/home" to="/home" />
          <Redirect from="/" to="/login" />
        </Switch>
      </div>
    );
  }
}

export default Login;
