import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './Personalizations.module.scss';
import MultiSelectDropdown from '../../../components/UI/MultiSelectDropdown/MultiSelectDropdown';
import update_personalization from '../../../mutations/Personalization/UpdatePersonalization';
import get_personalizations from '../../../queries/Personalization/Personalization';
import { graphql } from 'react-apollo';

class Personalizations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      input_quick_registration: "",
      currencies: this.props.currencies,
      quick_registration_dropdown: false
    };


    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.id !== 'personalizationModalIconQR') {
      this.props.closePersonalizationModal();
    }
  }

  handleInputClick = () => {
    this.setState({
      quick_registration_dropdown: true
    });

  };

  handleClickIcon = () => {
    this.setState({
      quick_registration_dropdown: !this.state.quick_registration_dropdown
    });

  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };

  handleSave = () => {
    let quick_registration_currencies = [];

    this.state.currencies.forEach(currency => {
      if (currency.isChecked) {
        quick_registration_currencies.push(currency.value);
      }
    });

    this.props.mutate({
      variables: { quick_registration: quick_registration_currencies },
      refetchQueries: [{ query: get_personalizations }]
    });

    this.props.closePersonalizationModal();
  };

  handleCheckboxEvent = (currency_selected, action) => {

    let currencies = JSON.parse(JSON.stringify(this.state.currencies));

    let counter = 0;

    currencies.forEach(currency => {
      if (currency.isChecked === true) {
        counter++;
      }
    });


    currencies.forEach(currency => {
      if (currency.value === currency_selected.value) {
        if (action === "checked" && counter < 4) {
          currency.isChecked = true;
        } else
          if (action === "remove") {
            currency.isChecked = false;
          }
      }
    });


    this.setState({ currencies: currencies });
  };

  render() {

    return (
      <div ref={this.setWrapperRef} className={[classes.modal_item, classes.personalizatons_modal].join(' ')}>
        <div className={classes.modal_header}>
          <div className={classes.personalizatons_modal_header}>{i18n.t("PERSONALIZATIONS")}</div>
        </div>

        <div className={[classes.modal_body, classes.personalizations_body].join(' ')}>
          <div className={classes.personalizations_miniHeader}>{i18n.t("QUICK_REGISTRATION1")}</div>
          <div className="row">
            <div className="col-12">
              <div className="text-center">

                <div className="multi_select_dropdown">
                  <MultiSelectDropdown
                    name={"input_quick_registration"}
                    placeholder="currency"
                    onChange={this.handleInput}
                    value={this.state.input_quick_registration}
                    onClick={() => this.handleInputClick("quick_registration_dropdown")}
                    onClickIcon={() => this.handleClickIcon("quick_registration_dropdown")}
                    data={this.state.currencies}
                    show_dropdown={this.state.quick_registration_dropdown}
                    handleDropdownChange={this.handleCheckboxEvent}
                    description={!this.state.quick_registration_dropdown ? i18n.t("SELECT_THE_FOUR_CURRENCIES_YOU") : null}
                    flex={"column"}/>

                </div>

                <div style={{ textAlign: "center", marginRight: "10px", marginTop: "20px" }}>
                  <div className="button_type1" onClick={() => this.handleSave()}>{i18n.t("SAVE")}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>);

  }
}



export default graphql(update_personalization)(Personalizations);
