import gql from 'graphql-tag';

export default gql`
subscription($servicePointId: Int, $exchangeRateGroupId: Int) {
  exchangeRates(servicePointId: $servicePointId, exchangeRateGroupId: $exchangeRateGroupId){
    id
    currency
    saleRate
    purchaseRate
    datetime
    servicePointId
    exchangeRateGroupId
  }
}
`;
