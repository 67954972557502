import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './CustomerRegister.module.scss';
import ToolbarLogout from '../../components/Toolbar/ToolbarLogout';
import { Link, withRouter } from 'react-router-dom';
import SearchInput from '../../components/UI/SearchInput/SearchInput';
import ClientDetails from './ClientDetails';
import EditClient from './EditClient';
import { graphql } from 'react-apollo';
import customers_query from '../../queries/Customer';

class CutomerRegisterNav extends Component {


  constructor(props) {
    super(props);
    this.state = {
      activeOption: "INDIVIDUALS",
      individual_clients: null,
      business_clients: null,
      bank_clients: null,
      service_point_clients: null,
      client: null,
      showClientDetails: false,
      showEditForm: false
    };


    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleIconOnClick = this.handleIconOnClick.bind(this);
    this.handleButtonOnClick = this.handleButtonOnClick.bind(this);
    this.onSelectItem = this.onSelectItem.bind(this);
  }

  handleOnClick(option) {
    this.setState({
      activeOption: option,
      showClientDetails: false,
      showEditForm: false
    });

  }

  handleIconOnClick() {
    this.setState({
      showClientDetails: false,
      showEditForm: true
    });

  }

  handleButtonOnClick() {
    this.setState({
      showClientDetails: true,
      showEditForm: false
    });

  }

  onSelectItem(item) {
    this.setState({
      client: item,
      showClientDetails: true
    });

  }


  static getDerivedStateFromProps(props) {
    if (props.data.loading === false) {
      let individual_clients = [];
      let business_clients = [];
      let bank_clients = [];
      let service_point_clients = [];
      props.data.customers.map(client => {
        if (client.typology === "individual") {
          individual_clients.push(client);
        } else
          if (client.typology === "business") {
            business_clients.push(client);
          } else
            if (client.typology === "bank") {
              bank_clients.push(client);
            } else
              if (client.typology === "service_point") {
                service_point_clients.push(client);
              }

        return null;
      });
      return {
        individual_clients,
        business_clients,
        bank_clients,
        service_point_clients
      };

    } else {
      return null;
    }
  }

  render() {

    let data = null;
    switch (this.state.activeOption) {
      case "INDIVIDUALS":
        data = this.state.individual_clients;
        break;
      case 'BUSINESSES':
        data = this.state.business_clients;
        break;
      case 'BANKS':
        data = this.state.bank_clients;
        break;
      case 'SERVICE POINTS':
        data = this.state.service_point_clients;
        break;
      default:
      //do nothing
    }


    return (
      <div>
        <ToolbarLogout />
        <div className={classes.ComponentBestOffer}>
          <div className={classes.newRegistration} style={{ position: 'relative' }}>
            <div className={classes.Component} style={{ height: '750px' }}>
              <div className={classes.Head}>
                <div className={classes.TransactionText} style={{ marginTop: "0px" }}>
                  {!this.state.showClientDetails ? i18n.t("CLIENT_REGISTER") : i18n.t("CLIENT_DETAILS")}
                </div>
                <Link to='/dashboard'>
                  <div style={{ marginRight: "13px" }}>
                    <i className={["fas fa-times-circle", classes.CloseIcon].join(' ')}></i>
                  </div>
                </Link>
              </div>

              <div className={classes.container}>
                <div className={classes.options}>
                  <div className="d-flex">
                    <p className={classes.optionText}
                      style={this.state.activeOption === 'INDIVIDUALS' ? { opacity: 1 } : { opacity: 0.3 }}
                      onClick={() => this.handleOnClick('INDIVIDUALS')}>{i18n.t("INDIVIDUALS")} </p>
                    <div className={classes.Separator}></div>
                    <p className={classes.optionText}
                      style={this.state.activeOption === 'BUSINESSES' ? { opacity: 1 } : { opacity: 0.3 }}
                      onClick={() => this.handleOnClick('BUSINESSES')}>{i18n.t("BUSINESSES")} </p>
                  </div>
                  <div className="d-flex">
                    <p className={classes.optionText}
                      style={this.state.activeOption === 'BANKS' ? { opacity: 1 } : { opacity: 0.3 }}
                      onClick={() => this.handleOnClick('BANKS')}>{i18n.t("BANKS")} </p>
                    <div className={classes.Separator}></div>
                    <p className={classes.optionText}
                      style={this.state.activeOption === 'SERVICE POINTS' ? { opacity: 1 } : { opacity: 0.3 }}
                      onClick={() => this.handleOnClick('SERVICE POINTS')}>{i18n.t("SERVICE_POINTS")} </p>
                  </div>
                </div>

                <div className={classes.Wall}>
                  {this.state.showClientDetails ?
                    <i className={["fas fa-pen", classes.Icon].join(' ')} onClick={this.handleIconOnClick}></i> :
                    null}

                </div>

                {!this.state.showClientDetails && !this.state.showEditForm && !this.props.data.loading &&
                  <SearchInput items={data} onSelectItem={this.onSelectItem} />}


                {this.state.showClientDetails && <ClientDetails type={this.state.activeOption} client={this.state.client} />}

                {this.state.showEditForm && <EditClient type={this.state.activeOption} onCancel={this.handleButtonOnClick} client={this.state.client} />}

              </div>
            </div>
          </div>
        </div>
      </div>);

  }
}


export default withRouter(graphql(customers_query)(CutomerRegisterNav));
