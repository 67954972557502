import React, { Component } from 'react';
import classes from './ArrowIcon.module.css';
import { getFileMetadata, directUpload } from "../../../services/fileUpload";
import create_direct_upload from '../../../mutations/FileUpload/UploadFile'
import { graphql } from 'react-apollo';

class ArrowIcon extends Component {
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
  }

  handleClick = () => {
    this.myInput.current.click();
  }

  uploadFile = (file) => {
    getFileMetadata(file).then((input) => {
      return this.props.createDirectUpload({ variables: { input: input } }).then(({ data }) => {
        const uploadData = data.directUpload;
        this.props.setFileAttributes(uploadData)
        return directUpload(uploadData.url, JSON.parse(uploadData.headers), file)
      });
    });
  };


  render() {
    return (
      <div className={[classes.arrowIcon, this.props.extraStyle].join(' ')}>
        <div className={classes.arrowContainer} onClick={this.handleClick} style={this.props.highlight ? { borderRadius: '50%', border: '1px solid red' } : null}>
          <i className="fas fa-arrow-up" style={{ fontSize: "20px" }}></i>
          <input type="file" id="file" ref={this.myInput} onChange={(e) => e.target.files[0] ? this.uploadFile(e.target.files[0]) : null} style={{ display: "none" }} />
        </div>
      </div>
    );
  }
}

export default graphql(create_direct_upload, { name: 'createDirectUpload' })(ArrowIcon);
