import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './CurrencyTrader.module.scss';
import TabContainer, { TabItem } from '../../../components/UI/Tabs/tabs.js';
import MyDossier from './MyDossier/MyDossier';
import PurchaseList from './Purchase/Purchase';
import SaleList from './Sale/Sale';
import Orders from './Orders/Orders';
import DossierModal from './DossierModal/DossierModal';
import get_personalizations from '../../../queries/Personalization/Personalization';
import { graphql } from 'react-apollo';

import Personalizations from './DossierModal/Personalizations/personalizations';

class CurrencyTrader extends Component {
  constructor(props) {
    super();
    this.state = {
      activeTab: 1,
      // show: true,
      showModal: false,
      showModal_pencil: false,
      buy_currencies: [
        { isChecked: true, value: 'EUR' },
        { isChecked: false, value: 'USD' },
        { isChecked: true, value: 'GBP' },
        { isChecked: false, value: 'CHF' }
      ],
      sell_currencies: [
        { isChecked: true, value: 'EUR' },
        { isChecked: false, value: 'USD' },
        { isChecked: true, value: 'GBP' },
        { isChecked: false, value: 'CHF' }
      ]
    }
  }
  showSubComp = () => {
    switch (this.state.activeTab) {
      case 1:
        return <MyDossier />;
      case 2:
        return <PurchaseList currencies={this.getCurrenciesFromObjects(this.state.buy_currencies)} />;
      case 3:
        return <SaleList currencies={this.getCurrenciesFromObjects(this.state.sell_currencies)} />;
      case 4:
        return <Orders current_user={this.props.current_user} bankAccountPopUp={() => this.props.bankAccountPopUp()} />
      default:
        return <MyDossier />;
    }

  };

  getCurrenciesFromObjects = (obj_list) => {
    let currencies_list = []
    obj_list.forEach(obj => {
      if (obj.isChecked) {
        currencies_list.push(obj.value)
      }
    })
    return currencies_list
  }

  static getDerivedStateFromProps(props, state) {

    if (props.data.loading === false) {
      let buy_currencies = state.buy_currencies
      let sell_currencies = state.sell_currencies
      const personalization = props.data.personalization

      buy_currencies.forEach(currency => {
        if (personalization.buyCurrency.includes(currency.value)) {
          currency.isChecked = true
        }
        else {
          currency.isChecked = false
        }
      })

      sell_currencies.forEach(currency => {
        if (personalization.sellCurrency.includes(currency.value)) {
          currency.isChecked = true
        }
        else {
          currency.isChecked = false
        }
      })
    }
    return null
  }

  modalHandler = () => {
    this.setState({
      showModal: !this.state.showModal
    });

    if (!this.state.showModal) {
      this.props.effect_open("currency_trader")
    }

  };

  childEvent(event) {
    event.stopPropagation();
    if (this.props.childEvent) {
      this.props.childEvent();
    }
  }

  closeDossierModal() {
    this.setState({
      showModal: false
    });

  }

  render() {

    var modal = null;
    if (this.state.showModal) {
      modal = <DossierModal modal_open={this.modalHandler.bind(this)} closeDossierModal={this.closeDossierModal.bind(this)} />;
    }
    var CurrencyTraderBody =
      <div>
        <TabContainer>
          <TabItem text={i18n.t("TAB_MY_DOSSIER")} active={this.state.activeTab === 1 ? "active" : ''} onClick={() => { this.setState({ activeTab: 1 }); }} />
          <TabItem text={i18n.t("TAB_BUY_EUR_ALL")} active={this.state.activeTab === 2 ? "active" : ''} onClick={() => { this.setState({ activeTab: 2 }); }} />
          <TabItem text={i18n.t("TAB_SELL_EUR_ALL")} active={this.state.activeTab === 3 ? "active" : ''} onClick={() => { this.setState({ activeTab: 3 }); }} />
          <TabItem text={i18n.t("TAB_ORDERS")} active={this.state.activeTab === 4 ? "active" : ''} onClick={() => { this.setState({ activeTab: 4 }); }} />
        </TabContainer>
        {this.showSubComp()}
      </div>;

    return (
      <div className={classes.dashboardItem}>
        <div className={classes.dashboardHeader} onClick={this.props.showTabHandler}>
          <div className={classes.dashboardHeaderLeftSide}>
            <div onClick={this.childEvent.bind(this)} style={{ display: 'inline-flex' }}>
              <div className={classes.dashboardHeaderTitle}>{i18n.t("CURRENCY_TRADER")}</div>
              <div style={{ position: "relative" }} onClick={() => { this.modalHandler() }}>
                <div className={classes.trade_button}>{i18n.t("TRADE")}</div>
                {modal}
              </div>
            </div>
          </div>
          <div className={classes.dashboardHeaderRightSide} >
            <div className="position-relative" onClick={(event) => { event.stopPropagation() }}>
              <i id="personalizationModalIconCT" className="fas fa-pen" onClick={this.personalizations_toggle_modal.bind(this)}></i>
              {this.state.showModal_pencil ? (
                <Personalizations
                  close_modal={this.close_personalizations_modal.bind(this)}
                  buy_currencies={this.state.buy_currencies}
                  sell_currencies={this.state.sell_currencies}
                />)
                :
                null}
            </div>
          </div>
        </div>
        {this.props.show ? CurrencyTraderBody : null}

      </div>);

  }
  personalizations_toggle_modal() {
    this.setState({ showModal_pencil: !this.state.showModal_pencil })
    if (!this.state.showModal_pencil) {
      this.props.effect_open("currency_trader")
    }
  }
  close_personalizations_modal() {
    this.setState({ showModal_pencil: false })
  }
}


export default graphql(get_personalizations)(CurrencyTrader);
