import i18n from "i18next";
import React, { Component } from "react";
import classes from "./FilterModal.module.scss";
import MultiSelectDropdown from "../../../components/UI/Dropdown/MultiSelectDropdown";
import CalendarComponent from "../../../containers/Modals/SettingsCalendar/Calendar";

var dateFormat = require("dateformat");

class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.child2 = React.createRef();
    this.state = {
      active_option: 0,
      min_rate_value: this.props.state.min_rate || "",
      max_rate_value: this.props.state.max_rate || "",
      min_paid_value: this.props.state.min_paid || "",
      max_paid_value: this.props.state.max_paid || "",
      min_value: this.props.state.min_value || "",
      max_value: this.props.state.max_value || "",
      min_date: this.props.state.min_date || "",
      max_date: this.props.state.max_date || "",
      currencies: this.props.state.currency_trader_currencies || [
        { value: "EUR", name: "EUR (EUROS)", sign: "€", isChecked: false },
        { value: "USD", name: "USD (US Dollars)", sign: "$", isChecked: false },
        { value: "GBP", name: "GBP (British Pounds)", sign: "£", isChecked: false },
        { value: "CHF", name: "CHF (Swiss Francs)", sign: "₣", isChecked: false },
        { value: "CAD", name: "CAD (Canadian Dollars)", sign: "C$", isChecked: false },
        { value: "AUD", name: "AUD (Australian Dollars)", sign: "A$", isChecked: false },
        { value: "JPY", name: "JPY (Japanese Yen)", sign: "¥", isChecked: false },
        { value: "SEK", name: "SEK (Swedish Krona)", sign: "kr", isChecked: false },
        { value: "NOK", name: "NOK (Norwegian Krone)", sign: "kr", isChecked: false },
        { value: "DKK", name: "DKK (Danish Krone)", sign: "Kr.", isChecked: false },
      ],
      selectedList: this.props.state.currency_objects || [],
      individual_checked: this.props.state.client_type === "individual" || false,
      business_checked: this.props.state.client_type === "business" || false,
      service_point_checked: this.props.state.client_type === "service_point" || false,
      bank_checked: this.props.state.client_type === "bank" || false,
      purchase_checked: this.props.state.category === "purchase" || false,
      sale_checked: this.props.state.category === "sale" || false,
      category: this.props.state.category || "",
      client_type: this.props.state.client_type || "",
      client: this.props.state.client || "",
      calendar_date: new Date(),
      date_input_type: null,
      calendar_active: false,
      sorting_element: this.props.state.sorting_element || null,
      sorting_order: this.props.state.sorting_order || "ASC",
      fixed_checked: this.props.state.typology === "Fixed" || false,
      flexible_checked: this.props.state.typology === "Flexible" || false,
      typology: this.props.state.typology || "",
      sub_users: [],
      selectedSubUserList: this.props.state.sub_users_objects || [],
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if (this.props.type !== "currency_trader") {
      let users_list = [];
      this.props.current_user.employees.map((user) => {
        users_list.push({ value: user.id, name: user.name, isChecked: false });
      });
      this.setState({
        sub_users: users_list,
      });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.srcElement.classList.value.search("filterModalIcon") === -1
    ) {
      this.props.closeModal();
      this.show_values();
    }
  }

  childEvent(event) {
    event.stopPropagation();
    if (this.props.childEvent) {
      this.props.childEvent();
    }
  }

  show_values = () => {
    this.props.get_parent({
      min_rate_value: this.state.min_rate_value,
      max_rate_value: this.state.max_rate_value,
      min_paid_value: this.state.min_paid_value,
      max_paid_value: this.state.max_paid_value,
      min_value: this.state.min_value,
      max_value: this.state.max_value,
      min_date: this.state.min_date,
      max_date: this.state.max_date,
      currencies: this.state.selectedList,
      category: this.state.category,
      client_type: this.state.client_type,
      client: this.state.client,
      sorting_element: this.state.sorting_element,
      sorting_order: this.state.sorting_order,
      currency_trader_currencies: this.state.currencies,
      typology: this.state.typology,
      sub_users: this.state.selectedSubUserList,
    });
  };

  resetFilters = () => {
    this.setState({
      min_rate_value: "",
      max_rate_value: "",
      min_paid_value: "",
      max_paid_value: "",
      min_value: "",
      max_value: "",
      min_date: "",
      max_date: "",
      individual_checked: false,
      business_checked: false,
      service_point_checked: false,
      bank_checked: false,
      sale_checked: false,
      purchase_checked: false,
      fixed_checked: false,
      flexible_checked: false,
      client: "",
      category: "",
      typology: "",
      client_type: "",
      sorting_element: null,
      sorting_order: "ASC",
    });

    if (this.props.type !== "currency_trader") {
      this.child.current.resetValues();
      this.child2.current.resetValues();
      this.setState({
        selectedList: [],
        selectedSubUserList: [],
      });
    } else if (this.props.type === "currency_trader") {
      let currencies = [...this.state.currencies];

      currencies.forEach((currency) => {
        currency.isChecked = false;
      });
      this.setState({
        currencies: currencies,
      });
    }
  };

  onSelect = (selectedList, selectedItem) => {
    this.setState({ selectedList });
  };

  onRemove = (selectedList, selectedItem) => {
    this.setState({ selectedList });
  };

  onSelectSubUser = (selectedSubUserList, selectedItem) => {
    this.setState({ selectedSubUserList });
  };

  onRemoveSubUser = (selectedSubUserList, selectedItem) => {
    this.setState({ selectedSubUserList });
  };

  onChangeEvent = (e, type) => {
    if (type === "individual") {
      this.setState({
        individual_checked: !this.state.individual_checked,
        business_checked: false,
        bank_checked: false,
        service_point_checked: false,
        client_type: e.target.checked ? "individual" : null,
      });
    } else if (type === "business") {
      this.setState({
        individual_checked: false,
        business_checked: !this.state.business_checked,
        bank_checked: false,
        service_point_checked: false,
        client_type: e.target.checked ? "business" : null,
      });
    } else if (type === "service_point") {
      this.setState({
        individual_checked: false,
        business_checked: false,
        service_point_checked: !this.state.service_point_checked,
        bank_checked: false,
        client_type: e.target.checked ? "service_point" : null,
      });
    } else if (type === "bank") {
      this.setState({
        individual_checked: false,
        business_checked: false,
        service_point_checked: false,
        bank_checked: !this.state.bank_checked,
        client_type: e.target.checked ? "bank" : null,
      });
    } else if (type === "sale") {
      this.setState({
        purchase_checked: false,
        sale_checked: !this.state.sale_checked,
        category: e.target.checked ? "sale" : null,
      });
    } else if (type === "purchase") {
      this.setState({
        sale_checked: false,
        purchase_checked: !this.state.purchase_checked,
        category: e.target.checked ? "purchase" : null,
      });
    } else if (type === "flexible") {
      this.setState({
        fixed_checked: false,
        flexible_checked: !this.state.flexible_checked,
        typology: e.target.checked ? "Flexible" : null,
      });
    } else if (type === "fixed") {
      this.setState({
        flexible_checked: false,
        fixed_checked: !this.state.fixed_checked,
        typology: e.target.checked ? "Fixed" : null,
      });
    }
  };

  onChangeCalendar = (date) => {
    if (this.state.date_input_type === "min") {
      this.setState({
        min_date: dateFormat(date, "dd/mm/yyyy"),
      });
    } else {
      this.setState({
        max_date: dateFormat(date, "dd/mm/yyyy"),
      });
    }
    this.setState({
      calendar_date: date,
      calendar_active: false,
    });
  };

  changeSortDirection = () => {
    if (this.state.sorting_order === "ASC") {
      this.setState({
        sorting_order: "DESC",
      });
    } else {
      this.setState({
        sorting_order: "ASC",
      });
    }
  };

  handleClickCurrency = (currency_value) => {
    let currencies = [...this.state.currencies];
    currencies.forEach((currency) => {
      if (currency.value === currency_value) {
        currency.isChecked = !currency.isChecked;
      }
    });
    this.setState({
      currencies: currencies,
    });
  };

  render() {
    const sort_element = (
      <div
        className={classes.arrowItem}
        style={{ top: this.state.sorting_order === "ASC" ? "-33px" : "-50px" }}
      >
        <i
          onClick={() => this.changeSortDirection()}
          className={this.state.sorting_order === "ASC" ? "fas fa-sort-up" : "fas fa-sort-down"}
        ></i>
      </div>
    );
    const currency_list = this.state.currencies
      .filter((currency) => ["EUR", "GBP", "CHF", "USD"].includes(currency.value))
      .map((currency) => {
        const currency_style = currency.isChecked
          ? {
              background: "#58595B",
              color: "white",
            }
          : {
              backround: "none",
              color: "#58595B",
            };
        return (
          <div
            key={currency.value}
            className={classes.currency}
            style={currency_style}
            onClick={() => this.handleClickCurrency(currency.value)}
          >
            {currency.sign}
          </div>
        );
      });
    return (
      <div
        ref={this.setWrapperRef}
        style={{ position: "absolute" }}
        onClick={this.childEvent.bind(this)}
      >
        <div className={classes.modal_item}>
          <div className={classes.modal_header}>
            <div className={classes.filter_modal_header}>{i18n.t("FILTERS")}</div>
            <div
              className={classes.filter_modal_header_icon}
              onClick={() => this.resetFilters()}
            >
              <i className="fas fa-undo-alt"></i>
            </div>
          </div>
          <div className={classes.modal_body}>
            <div className={classes.filter_modal_body}>
              <div className={classes.filterItem}>
                <div className={classes.itemHeader}>
                  <p className={classes.itemTitle}>{i18n.t("CURRENCY1")}</p>{" "}
                </div>
                <div style={{ margin: "10px 0px" }}>
                  {this.props.type === "currency_trader" ? (
                    <div>
                      <div className={classes.currencies}>{currency_list}</div>
                    </div>
                  ) : (
                    <MultiSelectDropdown
                      options={this.state.currencies}
                      selectedValues={this.state.selectedList}
                      onSelect={this.onSelect}
                      onRemove={this.onRemove}
                      ref={this.child}
                      closeOnSelect={false}
                      flex={"column"}
                    />
                  )}
                </div>
              </div>
              {this.props.type !== "currency_trader" && this.props.current_user.isStoreAdmin ? (
                <div className={classes.filterItem}>
                  <div className={classes.itemHeader}>
                    <p className={classes.itemTitle}>{i18n.t("RECIPIENT")}</p>{" "}
                  </div>
                  <div style={{ margin: "10px 0px" }}>
                    {this.props.type === "currency_trader" ? (
                      <div>
                        <div className={classes.currencies}>{currency_list}</div>
                      </div>
                    ) : (
                      <MultiSelectDropdown
                        options={this.state.sub_users}
                        selectedValues={this.state.selectedSubUserList}
                        onSelect={this.onSelectSubUser}
                        onRemove={this.onRemoveSubUser}
                        ref={this.child2}
                        closeOnSelect={false}
                        flex={"column"}
                      />
                    )}
                  </div>
                </div>
              ) : null}
              <div className={classes.filterItem}>
                <div
                  onClick={() => this.setState({ sorting_element: "value" })}
                  className={classes.itemHeader}
                >
                  {" "}
                  <p className={classes.itemTitle}>
                    {this.props.type === "currency_trader" ? i18n.t("AMOUNT1") : i18n.t("VALUE1")}
                  </p>
                </div>
                <div
                  className={classes.currencies}
                  style={{ position: "relative" }}
                >
                  {this.state.sorting_element === "value" ? sort_element : null}
                  <div style={{ marginRight: "2.5px" }}>
                    <input
                      type="text"
                      className={[classes.inputs, classes.left_input].join(" ")}
                      placeholder="Min"
                      value={this.state.min_value}
                      onChange={(e) => {
                        this.setState({ min_value: e.target.value });
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: "2.5px" }}>
                    <input
                      type="text"
                      className={classes.inputs}
                      placeholder="Max"
                      value={this.state.max_value}
                      onChange={(e) => {
                        this.setState({ max_value: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.filterItem}>
                <div
                  onClick={() => this.setState({ sorting_element: "amount_paid_cents" })}
                  className={classes.itemHeader}
                >
                  <p className={classes.itemTitle}>{i18n.t("PRICE_PAID")} </p>
                </div>
                <div
                  className={classes.currencies}
                  style={{ position: "relative" }}
                >
                  {this.state.sorting_element === "amount_paid_cents" ? sort_element : null}
                  <div style={{ marginRight: "2.5px" }}>
                    <input
                      type="text"
                      className={[classes.inputs, classes.left_input].join(" ")}
                      placeholder="Min"
                      value={this.state.min_paid_value}
                      onChange={(e) => {
                        this.setState({ min_paid_value: e.target.value });
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: "2.5px" }}>
                    <input
                      type="text"
                      className={classes.inputs}
                      placeholder="Max"
                      value={this.state.max_paid_value}
                      onChange={(e) => {
                        this.setState({ max_paid_value: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.filterItem}>
                <div
                  onClick={() => this.setState({ sorting_element: "rate" })}
                  className={classes.itemHeader}
                >
                  {" "}
                  <p className={classes.itemTitle}>{i18n.t("RATE")}</p>{" "}
                </div>
                <div
                  className={classes.currencies}
                  style={{ position: "relative" }}
                >
                  {this.state.sorting_element === "rate" ? sort_element : null}
                  <div style={{ marginRight: "2.5px" }}>
                    <input
                      type="text"
                      className={[classes.inputs, classes.left_input].join(" ")}
                      placeholder="Min"
                      value={this.state.min_rate_value}
                      onChange={(e) => {
                        this.setState({ min_rate_value: e.target.value });
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: "2.5px" }}>
                    <input
                      type="text"
                      className={classes.inputs}
                      placeholder="Max"
                      value={this.state.max_rate_value}
                      onChange={(e) => {
                        this.setState({ max_rate_value: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={classes.filterItem}>
                <div
                  onClick={() => this.setState({ sorting_element: "datetime" })}
                  className={classes.itemHeader}
                >
                  <p className={classes.itemTitle}>{i18n.t("DATE")}</p>
                </div>
                <div
                  className={classes.currencies}
                  style={{ position: "initial", position: "relative" }}
                >
                  {this.state.sorting_element === "datetime" ? sort_element : null}
                  <div
                    style={{ marginRight: "2.5px" }}
                    onClick={() => this.setState({ date_input_type: "min", calendar_active: true })}
                  >
                    <input
                      type="text"
                      className={[classes.inputs, classes.left_input].join(" ")}
                      placeholder="00/00/00"
                      value={this.state.min_date}
                      onChange={(e) => {
                        this.setState({ min_date: e.target.value });
                      }}
                    />
                  </div>
                  <div
                    style={{ marginLeft: "2.5px" }}
                    onClick={() => this.setState({ date_input_type: "max", calendar_active: true })}
                  >
                    <input
                      type="text"
                      className={classes.inputs}
                      placeholder="00/00/00"
                      value={this.state.max_date}
                      onChange={(e) => {
                        this.setState({ max_date: e.target.value });
                      }}
                    />{" "}
                  </div>

                  {this.state.calendar_active ? (
                    <div
                      className="filter_calendar"
                      style={{ position: "absolute", top: "60px" }}
                    >
                      <CalendarComponent
                        onChange={this.onChangeCalendar}
                        value={this.state.calendar_date}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              {this.props.type === "currency_trader" ? (
                <div className={classes.filterItem}>
                  <div className={classes.itemHeader}>
                    <p className={classes.itemTitle}>{i18n.t("ORDER_TYPE")}</p>
                  </div>
                  <div
                    className={classes.currencies}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <div
                      className={classes.checkbox_row}
                      style={{ marginRight: "5px" }}
                    >
                      <input
                        className={classes.formRadio}
                        onChange={(event) => this.onChangeEvent(event, "flexible")}
                        type="radio"
                        checked={this.state.flexible_checked}
                      />{" "}
                      <span>{i18n.t("FLEXIBLE1")}</span>
                    </div>
                    <div
                      className={classes.checkbox_row}
                      style={{ marginLeft: "5px" }}
                    >
                      <input
                        className={classes.formRadio}
                        onChange={(event) => this.onChangeEvent(event, "fixed")}
                        type="radio"
                        checked={this.state.fixed_checked}
                      />{" "}
                      <span>{i18n.t("FIXED1")}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className={classes.filterItem}>
                    <div className={classes.itemHeader}>
                      <p className={classes.itemTitle}>{i18n.t("CLIENT_TYPE")}</p>
                    </div>
                    <div
                      className={classes.currencies}
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        className={classes.checkbox_row}
                        style={{ marginRight: "5px" }}
                      >
                        <input
                          className={classes.formRadio}
                          onChange={(event) => this.onChangeEvent(event, "individual")}
                          type="radio"
                          checked={this.state.individual_checked}
                        />{" "}
                        <span>{i18n.t("INDIVIDUAL")}</span>
                      </div>
                      <div
                        className={classes.checkbox_row}
                        style={{ marginLeft: "5px" }}
                      >
                        <input
                          className={classes.formRadio}
                          onChange={(event) => this.onChangeEvent(event, "business")}
                          type="radio"
                          checked={this.state.business_checked}
                        />{" "}
                        <span>{i18n.t("BUSINESS")}</span>
                      </div>
                      <div
                        className={classes.checkbox_row}
                        style={{ marginRight: "5px" }}
                      >
                        <input
                          className={classes.formRadio}
                          onChange={(event) => this.onChangeEvent(event, "service_point")}
                          type="radio"
                          checked={this.state.service_point_checked}
                        />{" "}
                        <span>{i18n.t("SERVICE_POINT1")}</span>
                      </div>
                      <div
                        className={classes.checkbox_row}
                        style={{ marginLeft: "5px" }}
                      >
                        <input
                          className={classes.formRadio}
                          onChange={(event) => this.onChangeEvent(event, "bank")}
                          type="radio"
                          checked={this.state.bank_checked}
                        />{" "}
                        <span>{i18n.t("BANK1")}</span>
                      </div>
                    </div>
                  </div>

                  <div className={classes.filterItem}>
                    <div className={classes.itemHeader}>
                      <p className={classes.itemTitle}>{i18n.t("CLIENT")}</p>
                    </div>
                    <div className={classes.currencies}>
                      <input
                        type="text"
                        className={classes.inputs}
                        value={this.state.client}
                        onChange={(e) => {
                          this.setState({ client: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className={classes.filterItem}>
                <div className={classes.itemHeader}>
                  <p className={classes.itemTitle}>{i18n.t("CATEGORY")}</p>
                </div>
                <div
                  className={classes.currencies}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <div
                    className={classes.checkbox_row}
                    style={{ marginRight: "5px" }}
                  >
                    <input
                      className={classes.formRadio}
                      onChange={(event) => this.onChangeEvent(event, "purchase")}
                      type="radio"
                      checked={this.state.purchase_checked}
                    />{" "}
                    <span>{i18n.t("PURCHASE")}</span>
                  </div>
                  <div
                    className={classes.checkbox_row}
                    style={{ marginLeft: "5px" }}
                  >
                    <input
                      className={classes.formRadio}
                      onChange={(event) => this.onChangeEvent(event, "sale")}
                      type="radio"
                      checked={this.state.sale_checked}
                    />{" "}
                    <span>{i18n.t("SALE")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterModal;
