import React, { useState, useEffect } from "react";
import "../myprofile.scss";
import "./UserStyle.css";
import i18n from "i18next";
import LoggedUser from "../../../../queries/LoggedUser";
import { useQuery, useMutation } from "react-apollo";
import GiveEmployeePermission from "../../../../mutations/EmployeeRegistration/GiveEmployeePermission";
import DeleteEmployee from "../../../../mutations/EmployeeRegistration/DeleteEmployee";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const RegistredUsers = (props) => {
  const [employees, setEmployees] = useState([]);
  const [error_message, setErrorMessage] = useState([]);
  const { loading, error, data, refetch } = useQuery(LoggedUser);

  useEffect(() => {
    setEmployees(data.currentUser.employees);
  }, [data]);

  const [giveEmployeePermission] = useMutation(GiveEmployeePermission, {
    refetchQueries: [{ query: LoggedUser }],
  });
  const [deleteEmployee] = useMutation(DeleteEmployee, { refetchQueries: [{ query: LoggedUser }] });

  const handleInputChange = (employeeID, event) => {
    event.persist();
    const temp = event.target.name.replace(employeeID, "");
    let session = "my_profile";

    if (temp === "switch1") session = "currency_trader";

    let tempArray = [...employees];
    const index = employees.findIndex((employee) => employee.id === employeeID);
    tempArray[index] = { ...tempArray[index], session: event.target.checked };
    setEmployees([...tempArray]);

    giveEmployeePermission({
      variables: {
        id: parseInt(employeeID),
        session: session,
        toggle: event.target.checked,
      },
    })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteEmployee = (employeeID, firstName, lastName) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="CustomConfirmationDialog">
            <div
              style={{ height: 65, background: "#58595B", width: "113%", marginBottom: 30 }}
            ></div>
            <p style={{ fontSize: 22, textAlign: "center" }}>
              <b>Are you sure you want to permanently delete this user?</b>
            </p>
            <p style={{ fontSize: 22, marginBottom: 20 }}>
              <b>{firstName + " " + lastName}</b>
            </p>
            <div>
              <div
                className="button_type1"
                onClick={() => {
                  deleteUser(employeeID);
                  onClose();
                }}
              >
                {i18n.t("YES")}
              </div>
              <div
                className="button_type2"
                onClick={onClose}
              >
                {i18n.t("NO")}
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const deleteUser = (employeeID) => {
    deleteEmployee({
      variables: {
        id: parseInt(employeeID),
      },
    })
      .then()
      .catch((error) => {
        if (error.message === "GraphQL error: Balance Exists") {
          setErrorMessage(i18n.t("SUBUSER_BALANCE_EXIST"));
        }
      });
  };

  let employeesHTML = null;

  if (employees.length > 0) {
    employeesHTML = employees.map((employee) => (
      <div
        className="Users"
        key={employee.id}
      >
        <i
          className={["fas fa-times-circle", "DeleteEmployee"].join(" ")}
          style={props.editRegistredUsers ? { visibility: "visible" } : { visibility: "hidden" }}
          onClick={() => {
            handleDeleteEmployee(employee.id, employee.firstName, employee.lastName);
            setErrorMessage("");
          }}
        ></i>
        <div style={{ flex: 1 }}>{employee.firstName + " " + employee.lastName}</div>
        <input
          className="slider"
          type="checkbox"
          id={"switch" + employee.id}
          name={"switch" + employee.id}
          onChange={(event) => handleInputChange(employee.id, event)}
          checked={employee.myProfile}
        />
        <label
          className="react-switch-label"
          htmlFor={"switch" + employee.id}
        ></label>
        <input
          className="slider"
          type="checkbox"
          id={"switch1" + employee.id}
          name={"switch1" + employee.id}
          onChange={(event) => handleInputChange(employee.id, event)}
          checked={employee.currencyTrader}
          style={{ marginLeft: 20 }}
        />
        <label
          className="react-switch-label"
          htmlFor={"switch1" + employee.id}
          style={{ marginRight: 15 }}
        ></label>
      </div>
    ));
  }

  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#9A2020",
          fontSize: "14px",
        }}
      >
        {error_message}
      </div>
      <div
        className="Titles"
        style={{ paddingRight: 34 }}
      >
        <div>{i18n.t("MY_PROFILE1")}</div>
        <div className="example">{i18n.t("CURRENCY_TRADER2")}</div>
      </div>
      <div style={{ overflow: "auto", maxHeight: 400 }}>{employeesHTML}</div>
    </div>
  );
};

export default RegistredUsers;
