export const dropDownCurrencies = [
    { value: "ALL (Albanian Lek)", id: 3, isChecked: false },
    { value: "EUR (Euro)", id: 49, isChecked: true, self_checked: true },
    { value: "USD (US Dollar)", id: 149, isChecked: true },
    { value: "GBP (Pound Sterling)", id: 52, isChecked: true },
    { value: "CHF (Swiss Franc)", id: 30, isChecked: false },
    { value: "JPY (Japanese Yen)", id: 73, isChecked: false },
    { value: "AUD (Australian Dollar)", id: 8, isChecked: false },
    { value: "CAD (Canadian Dollar)", id: 27, isChecked: false },
    { value: "SEK (Swedish Krona)", id: 127, isChecked: false },
    { value: "NOK (Norwegian Krone)", id: 107, isChecked: false },
    { value: "DKK (Danish Krone)", id: 43, isChecked: false },
    { value: "AED (UAE Dirham)", id: 1, isChecked: false },
    { value: "AFN (Afghani)", id: 2, isChecked: false },
    { value: "AMD (Armenian Dram)", id: 4, isChecked: false },
    { value: "ANG (Netherlands Antillean)", id: 5, isChecked: false },
    { value: "AOA (Kwanza)", id: 6, isChecked: false },
    { value: "ARS (Argentine Peso)", id: 7, isChecked: false },
    { value: "AWG (Aruban Florin)", id: 9, isChecked: false },
    { value: "AZN (Azerbaijanian Manat)", id: 10, isChecked: false },
    { value: "BAM (Convertible Mark)", id: 11, isChecked: false },
    { value: "BBD (Barbados Dollar)", id: 12, isChecked: false },
    { value: "BDT (Taka)", id: 13, isChecked: false },
    { value: "BGN (Bulgarian Lev)", id: 14, isChecked: false },
    { value: "BHD (Bahraini Dinar)", id: 15, isChecked: false },
    { value: "BIF (Burundi Franc)", id: 16, isChecked: false },
    { value: "BMD (Bermudian Dollar)", id: 17, isChecked: false },
    { value: "BND (Brunei Dollar)", id: 18, isChecked: false },
    { value: "BOB (Boliviano)", id: 19, isChecked: false },
    { value: "BOV (Mvdol)", id: 20, isChecked: false },
    { value: "BRL (Brazilian Real)", id: 21, isChecked: false },
    { value: "BSD (Bahamian Dollar)", id: 22, isChecked: false },
    { value: "BTN (Ngultrum)", id: 23, isChecked: false },
    { value: "BWP (Pula)", id: 24, isChecked: false },
    { value: "BYN (Belarussian Ruble)", id: 25, isChecked: false },
    { value: "BZD (Belize Dollar)", id: 26, isChecked: false },
    { value: "CDF (Congolese Franc)", id: 28, isChecked: false },
    { value: "CHE (WIR Euro)", id: 29, isChecked: false },
    { value: "CHW (WIR Franc)", id: 31, isChecked: false },
    { value: "CLF (Unidad de Fomento)", id: 32, isChecked: false },
    { value: "CLP (Chilean Peso)", id: 33, isChecked: false },
    { value: "CNY (Yuan Renminbi)", id: 34, isChecked: false },
    { value: "COP (Colombian Peso)", id: 35, isChecked: false },
    { value: "COU (Unidad de Valor Real)", id: 36, isChecked: false },
    { value: "CRC (Costa Rican Colon)", id: 37, isChecked: false },
    { value: "CUC (Peso Convertible)", id: 38, isChecked: false },
    { value: "CUP (Cuban Peso)", id: 39, isChecked: false },
    { value: "CVE (Cabo Verde Escudo)", id: 40, isChecked: false },
    { value: "CZK (Czech Koruna)", id: 41, isChecked: false },
    { value: "DJF (Djibouti Franc)", id: 42, isChecked: false },
    { value: "DOP (Dominican Peso)", id: 44, isChecked: false },
    { value: "DZD (Algerian Dinar)", id: 45, isChecked: false },
    { value: "EGP (Egyptian Pound)", id: 46, isChecked: false },
    { value: "ERN (Nakfa)", id: 47, isChecked: false },
    { value: "ETB (Ethiopian Birr)", id: 48, isChecked: false },
    { value: "FJD (Fiji Dollar)", id: 50 },
    { value: "FKP (Falkland Islands Pound)", id: 51, isChecked: false },
    { value: "GEL (Lari)", id: 53, isChecked: false },
    { value: "GHS (Ghana Cedi)", id: 54, isChecked: false },
    { value: "GIP (Gibraltar Pound	)", id: 55, isChecked: false },
    { value: "GMD (Dalasi)", id: 56, isChecked: false },
    { value: "GNF (Guinea Franc)", id: 57, isChecked: false },
    { value: "GTQ (Quetzal)", id: 58, isChecked: false },
    { value: "GYD (Guyana Dollar)", id: 59, isChecked: false },
    { value: "HKD (Hong Kong Dollar)", id: 60, isChecked: false },
    { value: "HNL (Lempira)", id: 61, isChecked: false },
    { value: "HRK (Kuna)", id: 62, isChecked: false },
    { value: "HTG (Gourde)", id: 63, isChecked: false },
    { value: "HUF (Forint)", id: 64, isChecked: false },
    { value: "IDR (Rupiah)", id: 65, isChecked: false },
    { value: "ILS (New Israeli Sheqel)", id: 66, isChecked: false },
    { value: "INR (Indian Rupee)", id: 67, isChecked: false },
    { value: "IQD (Iraqi Dinar)", id: 68, isChecked: false },
    { value: "IRR (Iranian Rial)", id: 69, isChecked: false },
    { value: "ISK (Iceland Krona)", id: 70, isChecked: false },
    { value: "JMD (Jamaican Dollar)", id: 71, isChecked: false },
    { value: "JOD (Jordanian Dinar)", id: 72, isChecked: false },
    { value: "KES (Kenyan Shilling)", id: 74, isChecked: false },
    { value: "KGS (Som)", id: 75, isChecked: false },
    { value: "KHR (Riel)", id: 76, isChecked: false },
    { value: "KMF (Comoro Franc)", id: 77, isChecked: false },
    { value: "KPW (North Korean Won)", id: 78, isChecked: false },
    { value: "KRW (Won)", id: 79, isChecked: false },
    { value: "KWD (Kuwaiti Dinar)", id: 80, isChecked: false },
    { value: "KYD (Cayman Islands Dollar)", id: 81, isChecked: false },
    { value: "KZT (Tenge)", id: 82, isChecked: false },
    { value: "LAK (Kip)", id: 83, isChecked: false },
    { value: "LBP (Lebanese Pound)", id: 84, isChecked: false },
    { value: "LKR (Sri Lanka Rupee)", id: 85, isChecked: false },
    { value: "LRD (Liberian Dollar)", id: 86, isChecked: false },
    { value: "LSL (Loti)", id: 87, isChecked: false },
    { value: "LYD (Libyan Dinar)", id: 88, isChecked: false },
    { value: "MAD (Moroccan Dirham)", id: 89, isChecked: false },
    { value: "MDL (Moldovan Leu)", id: 90, isChecked: false },
    { value: "MGA (Malagasy Ariary)", id: 91, isChecked: false },
    { value: "MKD (Denar)", id: 92, isChecked: false },
    { value: "MMK (Kyat)", id: 93, isChecked: false },
    { value: "MNT (Tugrik)", id: 94, isChecked: false },
    { value: "MOP (Pataca)", id: 95, isChecked: false },
    { value: "MRU (Ouguiya)", id: 96, isChecked: false },
    { value: "MUR (Mauritius Rupee)", id: 97, isChecked: false },
    { value: "MVR (Rufiyaa)", id: 98, isChecked: false },
    { value: "MWK (Kwacha)", id: 99, isChecked: false },
    { value: "MXN (Mexican Peso)", id: 100, isChecked: false },
    { value: "MXV (Mexican Unidad)", id: 101, isChecked: false },
    { value: "MYR (Malaysian Ringgit)", id: 102, isChecked: false },
    { value: "MZN (Mozambique Metical)", id: 103, isChecked: false },
    { value: "NAD (Namibia Dollar)", id: 104, isChecked: false },
    { value: "NGN (Naira)", id: 105, isChecked: false },
    { value: "NIO (Cordoba Oro)", id: 106, isChecked: false },
    { value: "NPR (Nepalese Rupee)", id: 108, isChecked: false },
    { value: "NZD (New Zealand Dollar)", id: 109, isChecked: false },
    { value: "OMR (Rial Omani)", id: 110, isChecked: false },
    { value: "PAB (Nuevo Sol)", id: 111, isChecked: false },
    { value: "PEN (Nuevo Sol)", id: 112, isChecked: false },
    { value: "PGK (Kina)", id: 113, isChecked: false },
    { value: "PHP (Philippine Peso)", id: 114, isChecked: false },
    { value: "PKR (Pakistan Rupee)", id: 115, isChecked: false },
    { value: "PLN (Zloty)", id: 116, isChecked: false },
    { value: "PYG (Guarani)", id: 117, isChecked: false },
    { value: "QAR (Qatari Rial)", id: 118, isChecked: false },
    { value: "RON (Romanian Leu)", id: 119, isChecked: false },
    { value: "RSD (Serbian Dinar)", id: 120, isChecked: false },
    { value: "RUB (Russian Ruble)", id: 121, isChecked: false },
    { value: "RWF (Rwanda Franc)", id: 122, isChecked: false },
    { value: "SAR (Saudi Riyal)", id: 123, isChecked: false },
    { value: "SBD (Solomon Islands Dollar)", id: 124, isChecked: false },
    { value: "SCR (Seychelles Rupee)", id: 125, isChecked: false },
    { value: "SDG (Sudanese Pound)", id: 126, isChecked: false },
    { value: "SGD (Singapore Dollar)", id: 128, isChecked: false },
    { value: "SHP (Saint Helena Pound)", id: 129, isChecked: false },
    { value: "SLL (Leone)", id: 130, isChecked: false },
    { value: "SOS (Somali Shilling)", id: 131, isChecked: false },
    { value: "SRD (Surinam Dollar)", id: 132, isChecked: false },
    { value: "SSP (South Sudanese Pound)", id: 133, isChecked: false },
    { value: "STN (Dobra)", id: 134, isChecked: false },
    { value: "SVC (El Salvador Colon)", id: 135, isChecked: false },
    { value: "SYP (Syrian Pound)", id: 136, isChecked: false },
    { value: "SZL (Lilangeni)", id: 137, isChecked: false },
    { value: "THB (Baht)", id: 138, isChecked: false },
    { value: "TJS (Somoni)", id: 139, isChecked: false },
    { value: "TMT (Turkmenistan New Manat)", id: 140, isChecked: false },
    { value: "TND (Tunisian Dinar)", id: 141, isChecked: false },
    { value: "TOP (Pa’anga)", id: 142, isChecked: false },
    { value: "TRY (Turkish Lira)", id: 143, isChecked: false },
    { value: "TTD (Trinidad and Tobago Dollar)", id: 144, isChecked: false },
    { value: "TWD (New Taiwan Dollar)", id: 145, isChecked: false },
    { value: "TZS (Tanzanian Shilling)", id: 146, isChecked: false },
    { value: "UAH (Hryvnia)", id: 147, isChecked: false },
    { value: "UGX (Uganda Shilling)", id: 148, isChecked: false },
    { value: "USN (US Dollar(Next day))", id: 150, isChecked: false },
    { value: "UYI (Uruguay Indexadas)", id: 151, isChecked: false },
    { value: "UYU (Peso Uruguayo)", id: 152, isChecked: false },
    { value: "UZS (Uzbekistan Sum)", id: 153, isChecked: false },
    { value: "VEF (Bolivar)", id: 154, isChecked: false },
    { value: "VND (Dong)", id: 155, isChecked: false },
    { value: "VUV (Vatu)", id: 156, isChecked: false },
    { value: "WST (Tala)", id: 157, isChecked: false },
    { value: "XAF (CFA Franc BEAC)", id: 158, isChecked: false },
    { value: "XCD (East Caribbean Dollar)", id: 159, isChecked: false },
    { value: "XDR (Special Drawing Right)", id: 160, isChecked: false },
    { value: "XOF (CFA Franc BCEAO)", id: 161, isChecked: false },
    { value: "XPF (CFP Franc)", id: 162, isChecked: false },
    { value: "XSU (Sucre)", id: 163, isChecked: false },
    { value: "XUA (ADB Unit of Account)", id: 164, isChecked: false },
    { value: "YER (Yemeni Rial)", id: 165, isChecked: false },
    { value: "ZAR (Rand)", id: 166, isChecked: false },
    { value: "ZMW (Zambian Kwacha)", id: 167, isChecked: false },
    { value: "ZWL (Zimbabwe Dollar)", id: 168, isChecked: false }
];

export default dropDownCurrencies;
