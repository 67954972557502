import gql from 'graphql-tag';

export default gql`
mutation createFlexibleSaleOrder($attributes:SaleOrderInput!, $offer_list:[JSON!]){
    createFlexibleSaleOrder(attributes:$attributes, offerList: $offer_list) {
      id
      amountSoldCents
      amountSoldCurrency
      amountPaidCents
      amountPaidCurrency
      status
      number
      rate
      datetime
      expiration
    }
  }
`;
