import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './CustomerRegister.module.scss';

class clientDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      typeOfclient: this.props.type,
      client: this.props.client
    };

  }



  render() {

    const individual = <div className={classes.clientDetails}>
      <div style={{ fontSize: '25px', marginTop: '20px' }}><b>{this.state.client.firstName} {this.state.client.lastName}</b></div>
      <div style={{ marginBottom: '40px' }}><b>{i18n.t("PERSONAL_ID")}</b> {this.state.client.personalIdNumber} </div>

      <div><b>{i18n.t("ADDRESS")}</b></div>
      <div>{this.state.client.address}</div>
      <div>{this.state.client.postalCode} {this.state.client.city}, {this.state.client.country}</div>

      <div style={{ marginTop: '20px' }}><b>{i18n.t("EMAIL")}</b></div>
      <div>{this.state.client.email}</div>

      <div style={{ marginTop: '20px' }}><b>{i18n.t("PHONE_FAX")}</b></div>
      <div>{i18n.t("PHONE")} {this.state.client.cellphone}</div>
    </div>;

    const business = <div className={classes.clientDetails}>
      <div style={{ fontSize: '25px', marginTop: '20px' }}><b>{this.state.client.firstName}</b></div>
      <div><b>{i18n.t("NIUS")}</b> {this.state.client.rea} </div>

      <div style={{ marginTop: '40px' }}><b>{i18n.t("ADDRESS")}</b></div>
      <div>{this.state.client.address}</div>
      <div>{this.state.client.postalCode} {this.state.client.city}, {this.state.client.country}</div>

      <div style={{ marginTop: '20px' }}><b>{i18n.t("EMAIL")}</b></div>
      <div>{this.state.client.email}</div>

      <div style={{ marginTop: '20px' }}><b>{i18n.t("PHONE_FAX")}</b></div>
      <div>{i18n.t("PHONE")} {this.state.client.cellphone}</div>

      <div style={{ marginTop: '20px' }}><b>{i18n.t("PREVIOUSLY_REPRESENTED_BY")}</b></div>
      <div style={{ maxHeight: '170px', overflow: "auto" }}>
        {this.state.client.personalIdNumber.map(personal_id => {
          return <div key={personal_id}>{personal_id}</div>;
        })}
      </div>
    </div>;

    return <div>
      {this.state.typeOfclient === "INDIVIDUALS" ? individual : business}
    </div>;
  }
}


export default clientDetails;
