import gql from 'graphql-tag';

export default gql`
query($currency: String, $date: String, $group_id:ID) {
  exchangeRates(currency:$currency, date: $date, groupId:$group_id){
    id
    currency
    saleRate
    purchaseRate
    datetime
    servicePointId
    exchangeRateGroupId
  }
}
`;
