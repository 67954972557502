import i18n from "i18next";
import React, { Component } from "react";
import classes from "./TopUpComponent.module.scss";
import NumberFormat from "react-number-format";
import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';
import { graphql } from 'react-apollo';
import CREATE_TRANSFER_TOP_UP from '../../../../../mutations/CashManager/ServicePointAdmin/CreateTransferTopUp';

var dateFormat = require("dateformat");

class TopUpTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_users: this.props.current_user.employees,
      sub_users_for_dropdown:[],
      issued_user: null,
      to_user: true,
      from_user: false,
      amount: null,
      available_amount: null,
      currency: null
    };
  }

  componentDidMount = () => {
    let sub_users = []
    this.props.current_user.employees.map(sub_user => {
      sub_users.push({id: sub_user.id, value:sub_user.firstName + " " + sub_user.lastName})
    })
    this.setState({
      sub_users_for_dropdown: sub_users
    })
  }

  handleRadioButtons = (radio_button) => {
    if(radio_button == "to"){
      this.setState({
        to_user: !this.state.to_user,
        from_user: false
      })
    }else{
      this.setState({
        from_user: !this.state.from_user,
        to_user: false
      })
    }
  }

  handleDropdownchange = (name, id) => {
    this.setState({issued_user: id})
    if(this.state.currency){
      this.desplayAvailableAmount(id)
    }
  }

  desplayAvailableAmount = (sub_user_id) => {
    const employee = this.find_sub_user(sub_user_id)
    employee.cashManagerBalances.map(balance => {
      if(balance.amountCurrency == this.state.currency.toUpperCase()){
        this.setState({
          available_amount: balance.amountCents
        })
        return;
      }
    })
  }

  find_sub_user = (sub_user_id, name = false) => {
    const sub_user = this.state.sub_users.find(sub_user => sub_user.id == sub_user_id)
    if(name && sub_user){
      return sub_user.firstName + " " + sub_user.lastName
    }else{
      return sub_user
    }
  }

  defineAmountsign = (amount) => {
    if(this.state.from_user){
      return (0 - amount).toString()
    }
    else{
      return amount.toString()
    }
  }

  handleMutation = () => {
    const {amount, currency, issued_user} = this.state
    if(amount && currency && issued_user){
      this.props.create_transfer({
          variables: {
            attributes: {
              topUpAmountCents: this.defineAmountsign(this.state.amount),
              topUpAmountCurrency: this.state.currency.toUpperCase(),
              category: "transfer"
            },
            issued_user: this.state.issued_user
          }
      }).then(order_registration_res => {
        this.props.close_input_window()
      }).catch(error => {
        const message = error.message.replace('GraphQL error: ','');
        this.props.set_error_message(i18n.t(message))
      })
    }else{
      this.props.set_error_message(i18n.t("OBLIGATORY_FIELDS"))
    }
  }

  text_or_input = (position) => {
    if(this.props.editable){
      switch (position) {
        case 'currency':
          return {value:<input className={classes.nr_format_input} onChange={(e) => this.setState({currency: e.target.value})}/>}

        case 'top-left':
          return {description:i18n.t("RECIPIENT"),
                  value:<Dropdown key="firstDropdown"
                    items={this.state.sub_users_for_dropdown}
                    onSelectDropdown={(name, id) => this.handleDropdownchange(name, id)}
                  />}
        case 'top-right':
          return {description: i18n.t("TRANSFER"),
                  value:<div>
                    <span className={classes.daily_settings_extra_small_text} style={{display:"inline-flex"}}>
                      <input className={classes.formRadio} type="radio" onChange={() => this.handleRadioButtons("to")} checked={this.state.to_user} /> <span>{i18n.t("TO2")}</span>
                    </span>
                    <span className={classes.daily_settings_extra_small_text} style={{display:"inline-flex"}}>
                      <input className={classes.formRadio} type="radio" onChange={() => this.handleRadioButtons("from")} checked={this.state.from_user} /> <span>{i18n.t("FROM2")}</span>
                    </span>
                  </div>}
        case 'bottom-left':
          return {description: i18n.t("AMOUNT"),
                  value:<NumberFormat
                    className={classes.nr_format_input}
                    thousandSeparator={true}
                    value={this.state.amount == 0 ? null : this.state.amount}
                    onValueChange={(value) => this.setState({amount: Math.abs(value.value)})}
                  />}

        case 'bottom-right':
          return {description: i18n.t("AVAILABLE"),
                  value:<NumberFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    value={this.state.available_amount}
                  />}
        default:
          return null
      }
    }
    else{
      switch (position) {
        case 'currency':
          return {value:this.props.currency}
        case 'top-left':
          return {description: i18n.t("AMOUNT"),
                  value:<NumberFormat
                    style={{fontWeight:"bold"}}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={
                      this.props.currency_symbol === "L"
                        ? null
                        : this.props.currency_symbol
                    }
                    suffix={
                      this.props.currency_symbol === "L"
                        ? this.props.currency_symbol
                        : null
                    }
                    value={this.props.amount}
                  />}
        case 'top-right':
          return {description:i18n.t("EXECUTED_BY"),
                  value:<div className={classes.daily_settings_extra_small_text} style={{display:"inline-flex", fontWeight:"bold"}}>
                          {this.props.current_user.firstName + " " + this.props.current_user.lastName}
                        </div>}
        case 'bottom-left':
        return {description:i18n.t("OUTGOING_TO"),
                value:<div className={classes.daily_settings_extra_small_text} style={{display:"inline-flex"}}>
                        {this.find_sub_user(this.props.issued_user, true)}
                      </div>}

        case 'bottom-right':
          return {description: i18n.t("TYPE"),
                  value:<div className={classes.daily_settings_extra_small_text} style={{display:"inline-flex"}}>
                          Transfer
                        </div>}
        default:
          return null
      }
    }
  };

  render() {
    return (
      <div className="d-flex" style={{flex:1, marginTop: "5px", alignItems: "center"}}>
        <div className="d-flex" style={{flex:1}}>
          <div className="d-flex" style={{flex:1}}>
            <span className={classes.daily_settings_card_small_text} style={{ color: "rgb(100, 145, 119)", marginRight: "5px" }}>
              {this.text_or_input("currency").value}
            </span>
          </div>

          <div className="d-flex flex-column" style={{flex: 1, marginRight: "5.13px", fontWeight: "bold"}}>
            <div className="d-flex justify-content-end" style={{flex:1, fontSize: "9px"}}>
              {this.props.date}
            </div>
            <div className="d-flex justify-content-end" style={{fontSize: "9px"}}>
              {this.props.time}
            </div>
          </div>

        </div>

        <div className="d-flex" style={{ flex: 5, position:"relative" }}>
          <div style={{width: "-webkit-fill-available",border: `1px solid rgba(204, 204, 204, 0.8)`,width:"100%"}}>
            <div className="d-flex flex-row" style={{flex: 2, marginLeft: "3px"}}>
              <div className="d-flex" style={{flex: 1, marginLeft: this.props.marginLeft}}>

                <div className="d-flex align-items-center" style={{width: "100%", marginTop: "5px", flex: 1}}>
                  <span className={classes.daily_settings_extra_small_text}>
                    {this.text_or_input("top-left").description}
                  </span>
                  {this.text_or_input("top-left").value}
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center" style={{flex: 1}}>
                <div className={classes.daily_settings_extra_small_text} style={{display:"inline-flex"}}>
                  {this.text_or_input("top-right").description}
                </div>
                {this.text_or_input("top-right").value}
              </div>


            </div>

            <div className="d-flex align-items-center" style={{fontSize: "9px", marginBottom: "3px",marginLeft: "3px", marginTop:this.props.editable ? "15px" : "0px"}}>
              <div style={{flex:1, whiteSpace:"noWrap"}}>
                <span style={{marginRight:"10px"}}>{this.text_or_input("bottom-left").description}</span>
                <span className={classes.daily_settings_card_small_text}>
                  {this.text_or_input("bottom-left").value}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center" style={{flex:1}}>
                <span style={{marginRight:"15px"}}>{this.text_or_input("bottom-right").description}</span>
                <span className={classes.daily_settings_card_small_text}>
                  {this.text_or_input("bottom-right").value}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{position:"absolute", height:"100%", minWidth:"100px", right: "-105px"}}>
            {this.props.editable ?
              <div>
                <i
                  className={"fas fa-check-circle"}
                  style={{cursor: "pointer",color: "rgb(211, 156, 62)",fontSize: "17.33px", marginRight:"5px"}}
                  onClick={() => this.handleMutation()}
                ></i>
                <i
                  className={"far fa-times-circle"}
                  style={{cursor: "pointer",color: "rgb(211, 156, 62)",fontSize: "17.33px"}}
                  onClick={() => this.props.close_input_window()}
                ></i>
              </div>
            :
            <div style={{width:"100%"}}>
              <span style={{fontSize:"9px", marginRight:"3px"}}>{i18n.t("BY")}</span>
              <b className={classes.daily_settings_extra_small_text}>{this.props.creator}</b>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

export default graphql(CREATE_TRANSFER_TOP_UP, { name: 'create_transfer' })(TopUpTransfer);
