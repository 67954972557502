import i18n from 'i18next';
import React, { Component } from 'react';

import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import classes from '../Login.module.css';
import mutation from '../../../mutations/Login/SendOtp';
import { graphql } from 'react-apollo';

class VerificationTool extends Component {
  state = {
    orderForm: {
      email: {
        elementType: "radio",
        elementConfig: {
          type: 'radio',
          name: 'verification ',
          value: 'email'
        },

        description: i18n.t("EMAIL_EA_GMAIL_COM")
      },

      text: {
        elementType: "radio",
        elementConfig: {
          type: 'radio',
          name: 'verification ',
          value: 'text'
        },

        description: i18n.t("TEXT1")
      }
    },


    formIsValid: false,
    message: null
  };


  inputChangedHandler = () => {
    this.setState({ formIsValid: true });
  };

  sendHandler = () => {
    this.props.mutate({}).then(res => {
    });
    this.props.history.replace({
      pathname: '/login/code'
    });

  };



  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });

    }

    const email = this.state.orderForm.email;
    const text = this.state.orderForm.text;
    const intro = this.props.match.params.code === "newcode" ? <p className={classes.requestNewCode}> {i18n.t("REQUEST_A_NEW_CODE")}</p> : <p className={classes.verificationHeader}>{i18n.t("HOW_WOULD_YOU_LIKE_TO_RECEIVE")} <br /> {i18n.t("YOUR_VERIFICATION_CODE")}</p>;

    let form =
      <form>
        <div className={classes.Components4}>
          <div className={classes.Head}> </div>
          <div className={classes.verificationContent}>
            {intro}

            {this.state.message}

            <div className={classes.verificationInputs}>
              <Input
                elementType={email.elementType}
                elementConfig={email.elementConfig}
                changed={() => this.inputChangedHandler()}
                description={email.description} />

              <Input
                elementType={text.elementType}
                elementConfig={text.elementConfig}
                changed={() => this.inputChangedHandler()}
                description={text.description} />

            </div>
            <Button clicked={() => this.sendHandler()}
              extraStyle={classes.closeButton}>

              {i18n.t("SEND")}
            </Button>
          </div>
        </div>
      </form>;


    return (
      <div>
        {form}
      </div>);

  }
}


export default graphql(mutation)(VerificationTool);