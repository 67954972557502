import gql from 'graphql-tag';

export default gql`
query($date: String!) {
  exchangeRateGroup(date:$date){
  id
    exchangeRates{
      id
      currency
      saleRate
      purchaseRate
    }
    users{
      id
      name
      firstName
      lastName
    }
  }
}
`;
