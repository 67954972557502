import i18n from 'i18next';
import React, { Component } from 'react';
import '../myprofile.scss';
import PhoneFax from "./BranchOffice/PhoneFax";
import Email from "./BranchOffice/Email";
import Address from "./BranchOffice/Address";
import Certificates from "./BranchOffice/Certificates";
import ShareHolderScreen from "./BranchOffice/ShareHolderScreen";
import compose from 'lodash/fp/compose';
import { graphql } from 'react-apollo';
import user from '../../../../queries/LoggedUser';
import update_headquarter from '../../../../mutations/Registration/CreateHeadquarter';
import update_service_point from '../../../../mutations/Registration/CreateServicePoint';


class Contact extends Component {
  constructor(props) {
    super();
    this.state = {
      contactMode: true,
      editMode: false,
      contactSaveMode: false,
      ShareHolderButton: false,
      shareholder_screen: false,
      address_branch: {
        address: "",
        postal_code: "",
        city: "",
        country: ""
      },
      address_headquarter: {
        address: "",
        postal_code: "",
        city: "",
        country: ""
      },
      email_list_branch: [],
      email_list_headquarter: [],
      phone_list_branch: [],
      phone_list_headquarter: [],
      fax_list_branch: [],
      fax_list_headquarter: [],
      commercial_name: "",
      fiscal_code: "",
      registration_number: "dde",
      licence_number: "deded",
      user_id: null,
      error_message: "",
      graphql_error: "",
      isloading: false,
      b_office: true,
      h_quarters: false,
      color_arrived: false
    };


  }


  handleBranchOffice = () => {
    this.setState({
      b_office: true,
      h_quarters: false,
      shareholderButton: false
    });

  };

  handleHeadQuarter = () => {
    this.setState({
      b_office: false,
      h_quarters: true,
      shareholderButton: false
    });

  };

  changeColor = () => {
    this.setState({
      color_arrived: true
    });

  };

  handleShareHolder = () => {
    this.setState({
      shareholderButton: true,
      shareholder_screen: true
    });

  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    const service_point_data = this.props.data.currentUser.servicePoint;
    const headquarter_data = this.props.data.currentUser.headquarter;

    this.setState({
      user_id: this.props.data.currentUser.id,
      commercial_name: service_point_data.commercialName,
      fiscal_code: service_point_data.fiscalCode,

      address_branch: {
        address: service_point_data.address,
        city: service_point_data.city,
        country: service_point_data.country,
        postal_code: service_point_data.postalCode
      },

      address_headquarter: {
        address: headquarter_data.address,
        city: headquarter_data.city,
        country: headquarter_data.country,
        postal_code: headquarter_data.postalCode
      },


      email_list_branch: service_point_data.email,
      email_list_headquarter: [headquarter_data.email],

      licence_number: headquarter_data.licenceNumber,
      registration_number: headquarter_data.registrationNumber,
      phone_list_branch: service_point_data.phone,
      fax_list_branch: service_point_data.fax
    });

  };

  updateServicePoint = () => {
    let address = this.state.address_branch;
    this.props.update_service_point({
      variables: {
        attributes: {
          address: address.address,
          city: address.city,
          country: address.country,
          postalCode: address.postal_code,
          email: this.state.email_list_branch,
          fax: this.state.fax_list_branch,
          phone: this.state.phone_list_branch
        },
        user_id: this.state.user_id
      },
      refetchQueries: [{ query: user }]
    }).then(res => {
      this.setState({
        error_message: "",
        contactSaveMode: true,
        graphql_error: ""
      });

    }).catch(error => {
      this.setState({
        error_message: i18n.t("FIELDS_CANNOT_BE_BLANK"),
        graphql_error: error.message
      });

    });
  };

  updateHeadquarter = () => {
    let address = this.state.address_headquarter;
    this.props.update_headquarter({
      variables: {
        attributes: {
          address: address.address,
          city: address.city,
          country: address.country,
          postalCode: address.postal_code,
          email: this.state.email_list_headquarter[0]
        },
        user_id: this.state.user_id
      },
      refetchQueries: [{ query: user }]
    }).then(res => {
      this.setState({
        error_message: "",
        contactSaveMode: true,
        graphql_error: ""
      });

    }).catch(error => {
      this.setState({
        error_message: i18n.t("FIELDS_CANNOT_BE_BLANK"),
        graphql_error: error.message
      });

    });
  };


  update_data = (element, data) => {
    let name = "";
    if (this.state.h_quarters)
      name = element.concat("_headquarter"); else {
      name = element.concat("_branch");
    }
    this.setState({
      [name]: data
    });

  };

  onSave = () => {
    if (this.state.b_office) {
      this.updateServicePoint();
    } else {
      this.updateHeadquarter();
    }
  };

  onCancel = () => {
    this.fetchData();
    this.setState({
      shareholderButton: false,
      editHeadquarters: false,
      contactMode: true,
      editMode: false,
      error_message: ""
    });

  };


  render() {
    if (this.state.isloading) {
      return null;
    } else {

      var pencil_icon =
        <div className="ih_edit" onClick={() => { this.setState({ editMode: true, contactMode: false }); }}>
          <i className="fas fa-pen"></i>
        </div>;


      if (this.state.editMode) {
        pencil_icon =
          <div className="ih_edit">
            <i className="fas fa-pen" style={{ opacity: 0.28 }}></i>
          </div>;

      }

      const Cards =
        <div style={{ marginTop: "18px" }}>
          <div className="row">
            <div className="col-md-12">
              <Address
                address={this.state.h_quarters ? this.state.address_headquarter : this.state.address_branch}
                checkChange={this.checkChange.bind(this)}
                modifiable={this.state.editMode}
                type={this.state.h_quarters ? "headquarter" : "servicePoint"}
                update_data={this.update_data} />

            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Email
                email_list={this.state.h_quarters ? this.state.email_list_headquarter.filter(email => { return email !== ""; }) : this.state.email_list_branch.filter(email => { return email !== ""; })}
                checkChangeEmail={this.checkChangeEmail.bind(this)}
                modifiable={this.state.editMode}
                add_functionality={this.state.h_quarters ? false : true}
                type={this.state.h_quarters ? "headquarter" : "servicePoint"}
                update_data={this.update_data}
                error={this.state.graphql_error.includes("Email")}
                fire_mutation={this.updateServicePoint} />

            </div>
            <div className="col-md-6">
              {this.state.b_office ?
                <PhoneFax
                  phone_list={this.state.phone_list_branch.filter(phone => { return phone !== ""; })}
                  fax_list={this.state.fax_list_branch.filter(fax => { return fax !== ""; })}
                  checkChangePhone={this.checkChangePhone.bind(this)}
                  checkChangeFax={this.checkChangeFax.bind(this)}
                  modifiable={this.state.editMode}
                  add_functionality={this.state.h_quarters ? false : true}
                  type={this.state.h_quarters ? "headquarter" : "servicePoint"}
                  update_data={this.update_data}
                  fire_mutation={this.updateServicePoint} /> :


                <Certificates
                  licence_number={this.state.licence_number}
                  registration_number={this.state.registration_number} />}


            </div>
          </div>
        </div>;



      const ContactSavedSuccesfuly =
        <div className="SavedContainer" onClick={() => { this.setState({ editMode: false, contactSaveMode: false, contactMode: true }); }}>
          <div className="SavedContainerText">{i18n.t("CHANGES_SAVED")} <br /> {i18n.t("SUCCESSFULLY")}</div>
          <div className="SavedContainerIcon"><i className="fas fa-check"></i></div>
        </div>;


      return (
        <div>
          <div className="information_header">
            <div className="ih_title">{i18n.t("CONTACT_INFORMATION")}</div>
            {pencil_icon}
          </div>
          <div style={{ textAlign: "center", fontSize: "14px", color: "#881401" }}>{this.state.error_message}</div>
          <div className="hr_choose_row">
            <div
              className="hr_choose_item"
              style={this.state.b_office || this.state.color_arrived || this.state.shareholderButton ? { opacity: 1, cursor: "default" } : { opacity: 0.28 }}
              onClick={this.handleBranchOffice}>
              {i18n.t("BRANCH_OFFICE")} </div>

            <div className="hr_choose_seperator"></div>

            <div className="hr_choose_item"
              style={this.state.h_quarters ? { opacity: 1, cursor: "default" } : { opacity: 0.28 }}
              onClick={this.handleHeadQuarter}>
              {i18n.t("HEADQUARTERS")}</div>
          </div>

          <div>
            <div className="text-center" style={{ paddingTop: 30 }}>
              <div className="info_subheader">{this.state.commercial_name}</div>
              <div className="info_subheader_mini">{this.state.fiscal_code}</div>
            </div>
            {this.state.shareholder_screen ? <ShareHolderScreen editMode={this.state.editMode} cancelEdit={() => this.setState({ editMode: false })} /> : Cards}
            {this.state.contactSaveMode ? ContactSavedSuccesfuly : null}
          </div>

          {this.state.editMode && !this.state.shareholder_screen ?
            <div className="text-center" style={{ marginTop: 20, marginBottom: 20, width: "100%" }}>
              <div className="button_type1" onClick={this.onSave}>{i18n.t("SAVE")}</div>
              <div className="button_type2" onClick={this.onCancel}>{i18n.t("CANCEL")}</div>
            </div> :

            null}


          {
            this.state.h_quarters ?
              <div className="text-center">
                <button className="button_type1"
                  style={{ display: this.state.shareholderButton ? "none" : "inline-block" }}
                  onClick={this.handleShareHolder}>
                  {i18n.t("SHAREHOLDER_MAP")}</button>
              </div> :

              null}


        </div>);


    }
  }


  saveCard() {
    this.setState({
      contactSaveMode: true
    });

  }
  cancelCard() {
    this.setState({
      editMode: false,
      contactMode: true
    });

  }
  checkChange(id) {
    this.setState({ primary_address: id });
  }
  checkChangeEmail(index) {
    this.setState(Object.assign(this.state.email, { primary: index }));
  }
  checkChangePhone(index) {
    this.setState(Object.assign(this.state.phone, { primary: index }));
  }
  checkChangeFax(index) {
    this.setState(Object.assign(this.state.fax, { primary: index }));
  }
}



export default graphql(user)(compose(
  graphql(update_headquarter, { name: 'update_headquarter' }),
  graphql(update_service_point, { name: 'update_service_point' }))(
    Contact));
