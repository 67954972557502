import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import classes from '../../CashManager.module.scss';
import DailySettingsRate from '../DailySettingsRate';
import get_exchange_rates_query from '../../../../../queries/CashManager/ExchangeRates';
import CREATE_EXCHANGE_RATE from '../../../../../mutations/CashManager/CreateExchangeRate';
import ADD_USER from '../../../../../mutations/CashManager/ServicePointAdmin/ExceptionalExchangeRates/AddUserToGroup';
import REMOVE_USER from '../../../../../mutations/CashManager/ServicePointAdmin/ExceptionalExchangeRates/RemoveUserFromGroup';
import REMOVE_GROUP from '../../../../../mutations/CashManager/ServicePointAdmin/ExceptionalExchangeRates/RemoveGroup';
import MultiSelectDropdown from '../../../../../components/UI/MultiSelectDropdown/MultiSelectDropdown';
import receive_client from "../../../../../Client";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from '@apollo/react-hooks';
import LoggedUser from '../../../../../queries/LoggedUser';
import ExceptionRateGroup from "../../../../../queries/CashManager/ServicePointAdmin/ExceptionalExchangeRates/Group";

var dateFormat = require('dateformat');

const ExceptionComponent = props => {
  //QUERIES
  const { loading, error, data, refetch } = useQuery(get_exchange_rates_query,{
    variables: { group_id: props.group_id || null}
  });

  //MUTATIONS
  const [changeRate] = useMutation(CREATE_EXCHANGE_RATE);
  const [addUser] = useMutation(ADD_USER);
  const [removeUser] = useMutation(REMOVE_USER);
  const [removeGroup] = useMutation(REMOVE_GROUP);

  const [sub_users, setSubUsers] = useState([]);
  const [input_value, setInputValue] = useState("");
  const [dropdownState, setDropdownState] = useState(false);
  const [newExchangeRate, setNewExchangeRate] = useState(false);

  useEffect(() => {
    let users_list = []
    if(props.exceptional_users){
      props.exceptional_users.map(user => {
        users_list.push({id: user.id, value:user.name, isChecked:true})
      })
    }
    props.store_users.map(user => {
      users_list.push({id: user.id, value:user.name, isChecked:false})
    })
    setSubUsers(users_list)
  },[props.store_users, props.exceptional_users, data]);


  const handleInput = e => {
    setInputValue(e.target.value)
  };

  const handleDropdownChange = (item_selected, action) => {
    if(action == "remove"){
      remove_user(item_selected.id)
    }else{
      add_user(item_selected.id)
    }
    let users = JSON.parse(JSON.stringify(sub_users));
    users.forEach(user => {
      if (user.value == item_selected.value) {
        if (action == "checked") {
          user.isChecked = true;
        } else
          if (action == "remove") {
            user.isChecked = false;
          }
      }
    });
    setSubUsers(users)
  }

  const handleClickedNew = type => {
    changeRate({
      variables:
      {
        attributes:
        {
          currency: type.currency ? type.currency.toUpperCase() : null,
          purchaseRate: parseFloat(type.first_element),
          saleRate: parseFloat(type.second_element),
        },
         group_id: props.group_id
      }
    }).then(result=>{
      setNewExchangeRate(false)
      refetch()
    })
  };

  const add_user = (user_id) => {
    addUser({
      variables:{group_id: props.group_id, user_id:user_id}
    }).then(result => {
      props.refreshList()
    })
  }

  const remove_user = (user_id) => {
    removeUser({
      variables:{user_id:user_id}
    }).then(result => {
      props.refreshList()
    })
  }

  const remove_group = (group_id) => {
    removeGroup({
      variables:{group_id:group_id}
    }).then(result => {
      props.refreshList()
    })
  }

  if (loading) return null;
  if (error) return `Error! ${error}`;
  var listItems = null;

  if (data.exchangeRates) {
    listItems = data.exchangeRates.map(record => {
      return (
        <DailySettingsRate
          key={record.id}
          currency={record.currency}
          purchase_rate={record.purchaseRate.toString()}
          sales_rate={record.saleRate.toString()}
          type={"exchange_rate"}
          input={props.input}
          onChildClicked={handleClickedNew}
          date_of_creation={dateFormat(record.datetime, "dd/mm/yyyy")}
          flex={1}
          with_ALL={true}
          marginLeft={"0px"}/>
      )
    });
  }
  return (
    <div>
      {i18n.t("EXCHANGE_RATE_EXCEPTION")}
      <i
        className="fas fa-plus"
        style={{
          color: "rgb(211, 156, 62)",
          fontSize: "14px",
          marginLeft: "10px"
        }}
        onClick={() => setNewExchangeRate(true)}>
      </i>
      <div style={{ marginTop: "12.6px", width: "90%", marginLeft: "auto", marginRight: "auto", marginBottom:"10px" }}>
        <div style={{display:"inline-flex", alignItems:"baseline"}}>
          <div style={{whiteSpace:"nowrap", marginRight:"10px", fontSize:"10px"}}>{i18n.t("APPLY")}</div>
          <MultiSelectDropdown
            type={"sub_users"}
            data={sub_users}
            value={input_value}
            show_dropdown={dropdownState}
            handleDropdownChange={null}
            description={""}
            onClickIcon={() => setDropdownState(!dropdownState)}
            onChange={(e) => handleInput(e)}
            onClick={() => setDropdownState(true)}
            handleDropdownChange={(item, action) => handleDropdownChange(item, action)}
            flex={"row"}
            width={"225px"}
          />
        </div>
        <i
          className="fas fa-times-circle"
          style={{
            color: "rgb(211, 156, 62)",
            fontSize: "20px",
            float:"right"
          }}
          onClick={() => remove_group(props.group_id)}>
        </i>
        {listItems}
        {newExchangeRate ?
          <DailySettingsRate type="exchange_rate"
            onChildClicked={handleClickedNew} input={"true"}
            modifiable={true}
            date_of_creation={dateFormat(new Date(), i18n.t("DD_MM_YYYY"))}
            flex={1}
            with_ALL={true}
            marginLeft="0px"/> :
          null}
      </div>
    </div>
  )
}

export default ExceptionComponent;
