import React, { useState, useEffect } from 'react';
import classes from './BankAccounts.module.scss';
// import IntesaSanPaolo from '../../../../../assets/img/intesa_sanpaolo.png';
// import Raiffeisen from '../../../../../assets/img/raiffeisen.png';
// import UnionBank from '../../../../../assets/img/union.png';
// import AlphaBank from '../../../../../assets/img/alpha.png';
import NumberFormat from 'react-number-format';

const Bank = props => {
  // const [icon, set_icon] = useState(null)

  // useEffect(() => {
  //   switch (props.icon) {
  //     case "Intesa SanPaolo":
  //       set_icon(IntesaSanPaolo)
  //       break;
  //     case "Raiffeisen":
  //       set_icon(Raiffeisen)
  //       break;
  //     case "Alpha Bank":
  //       set_icon(AlphaBank)
  //       break;
  //     case "Union Bank":
  //       set_icon(UnionBank)
  //       break;
  //     default:
  //       set_icon(AlphaBank)
  //   }
  // }, [props.icon]);
  let icon = null;
  const images = require.context('../../../../../assets/img', true);
  icon = images('./' + props.icon + '.png');

  return (
    <div className={classes.bankComponent}>
      <div className={classes.bank_title}>
        <img src={icon} alt="BankIcon" className={classes.bank_icon} />
        <span className={classes.bank_name}>{props.name}</span>
      </div>
      {props.currencies.map(currency => {
        return (
          <div
            key={currency.currency}
            className={classes.currencyItem}>
            <span>{currency.currency}</span>
            <span> {currency.total.toLocaleString('ja-JP', { minimumFractionDigits: 2 })}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Bank;
