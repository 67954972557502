import gql from 'graphql-tag';

export default gql`
mutation createPurchaseOrder($attributes: PurchaseOrderInput!, $rate: String!, $customer: CustomerInput){
  createPurchaseOrder(attributes: $attributes, rate: $rate, customer: $customer) {
    order{
      ... on PurchaseOrder {
      id
      amountPurchasedCents
    	amountPurchasedCurrency
      amountPaidCents
      amountPaidCurrency
      rate
      commission
      number
      portfolio {
        user {
          name
          firstName
          lastName
          globalName
          cellphone
          city
          country
          email
          address
          personalIdNumber
          dateOfBirth
          postalCode
          tradingDailyLimit
          servicePoint {
            commercialName
            fiscalCode
            city
            country
            address
            phone
            fullInvoiceAddress
          }
        }
      }
    },
        ... on SaleOrder {
        id
        amountSoldCents
        amountSoldCurrency
        amountPaidCents
        amountPaidCurrency
        rate
        commission
        number
        portfolio {
          user {
            name
            firstName
            lastName
            globalName
            cellphone
            city
            country
            email
            address
            personalIdNumber
            dateOfBirth
            postalCode
            tradingDailyLimit
            servicePoint {
              commercialName
              fiscalCode
              city
              country
              address
              phone
              fullInvoiceAddress
            }
          }
        }
      }
    },
    fiscalizationData{
      nivf
      nslf
      qrUrl
    }
  }
}
`;
