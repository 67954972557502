import i18n from 'i18next';
import React, { Component } from 'react';
import '../myprofile.scss';
import "./UserStyle.css";
import { graphql } from 'react-apollo';
import compose from 'lodash/fp/compose';
import user from '../../../../queries/LoggedUser';
import update_user from '../../../../mutations/Registration/UpdateUser';
import change_password from '../../../../mutations/ChangePassword';
import AddNewUser from './AddNewUser';
import RegistredUsers from './RegistredUsers';

class User extends Component {
  constructor(props) {
    super();
    this.state = {
      extraInfo: true,
      editPassword: false,
      editMode: false,
      passwordSavedSuccesfuly: false,
      editSavedSuccesfuly: false,
      registredUsers: false,
      editRegistredUsers: false,
      addNewUser: false,
      showAddNewUserForm: false,
      checked: false,
      user_id: "",
      user_typology: null,
      firstName: "",
      lastName: "",
      personalIdNumber: "",
      address: "",
      postalCode: "",
      city: "",
      country: "",
      email: "",
      cellphone: "",
      error_message: "",
      newPassword: "",
      currentPassword: "",
      confirmPassword: ""
    };
  }

  handleSlider(checked) {
    this.setState({ checked });
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    const user = this.props.data.currentUser;
    this.setState({
      firstName: user.firstName,
      lastName: user.lastName,
      personalIdNumber: user.personalIdNumber,
      address: user.address,
      postalCode: user.postalCode,
      city: user.city,
      country: user.country,
      email: user.email,
      cellphone: user.cellphone,
      user_id: user.id,
      user_typology: user.typology
    });
  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSave = () => {
    const { firstName, lastName, personalIDNumber, address, cellphone, city, country, postalCode, user_id } = this.state;
    this.props.update_user({
      variables: { attributes: { firstName, lastName, personalIDNumber, address, cellphone, city, country, postalCode }, user_id },
      refetchQueries: [{ query: user }]
    }).then(res => {
      this.setState({
        editSavedSuccesfuly: true,
        error_message: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        error_message: i18n.t("FIELDS_CANNOT_BE_BLANK")
      });
    });
  };

  onCancel = () => {
    this.fetchData();
    this.setState({
      editMode: false,
      extraInfo: true,
      error_message: false
    });
  };

  onCancelPasswordChange = () => {
    this.setState({
      editPassword: false,
      extraInfo: true,
      error_message: ""
    });
  };

  onSaveNewPassword = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    if (newPassword === confirmPassword) {
      this.props.change_password({
        variables: { password: currentPassword, new_password: newPassword },
        refetchQueries: [{ query: user }]
      }).then(res => {
        if (res.data.changePassword.success === true) {
          this.setState({
            passwordSavedSuccesfuly: true,
            error_message: ""
          });
        } else {
          this.setState({
            error_message: i18n.t("PASSWORD_IS_INCORRECT")
          });
        }
      }).catch(error => {
        if (error.message === "GraphQL error: Password does not fulfill requirements") {
          this.setState({
            error_message: i18n.t("PASSWORD_DOES_NOT_FULFILL_REQU")
          });
        }
      });
    } else {
      this.setState({
        error_message: i18n.t("PASSWORD_DOES_NOT_MATCH")
      });
    }
  };

  toggleAddUser = () => {
    this.setState({ addNewUser: false, editRegistredUsers: false });
  }

  toggleShowForm = () => {
    this.setState({ showAddNewUserForm: false });
  }

  handlePencilIconClick = () => {
    if (!this.state.registredUsers) this.setState({ editMode: true, extraInfo: false, registredUsers: false });
    else this.setState({ editMode: false, editRegistredUsers: true })
  }

  handlePencilIconEditClick = () => {
    if (!this.state.registredUsers) this.setState({ editMode: false, extraInfo: true, addNewUser: false });
    else this.setState({ editMode: false, editRegistredUsers: false, addNewUser: false })
  }

  render() {
    const PasswordSavedSuccesfuly =
      <div className="SavedContainer" onClick={() => { this.setState({ passwordSavedSuccesfuly: false, editPassword: false, extraInfo: true }); }}>
        <div className="SavedContainerText">{i18n.t("CHANGES_SAVED")} <br /> {i18n.t("SUCCESSFULLY")}</div>
        <div className="SavedContainerIcon"><i className="fas fa-check"></i></div>
      </div>;

    const EditSavedSuccesfuly =
      <div className="SavedContainer" onClick={() => { this.setState({ editSavedSuccesfuly: false, editMode: false, extraInfo: true }); }}>
        <div className="SavedContainerText">{i18n.t("CHANGES_SAVED")} <br /> {i18n.t("SUCCESSFULLY")}</div>
        <div className="SavedContainerIcon"><i className="fas fa-check"></i></div>
      </div>;

    const ExtraInfo =
      <div style={{ marginTop: 5 }}>
        <div className="info_subheader">{this.state.firstName} {this.state.lastName}</div>
        <div className="info_subheader_mini" style={{ marginBottom: 20 }}>{this.state.personalIdNumber}</div>

        <div className="info_subheader_v1">{i18n.t("CONTACT_INFORMATION1")}</div>
        <div className="info_subheader_mini">{this.state.address}</div>
        <div className="info_subheader_mini">{this.state.postalCode} {this.state.city} {this.state.country}</div>

        <div className="info_subheader_v1" style={{ marginTop: 20 }}>{i18n.t("EMAIL3")}</div>
        <div className="info_subheader_mini">{this.state.email}</div>

        <div className="info_subheader_v1" style={{ marginTop: 20 }}>{i18n.t("PHONE1")}</div>
        <div className="info_subheader_mini"><b>{i18n.t("CELL")}</b> {this.state.cellphone}</div>

        <div className="text-center" style={{ marginTop: 60, marginBottom: 40 }}>
          <div className="button_type1" onClick={() => { this.setState({ editPassword: true, extraInfo: false }); }}>{i18n.t("CHANGE_PASSWORD")}</div>
        </div>
      </div>;

    const EditPassword =
      <div style={{ marginTop: 30 }}>
        <div className="row">
          <div className="col-md-9" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <div className="info_card">
              <div className="info_card_header">
                <div className="info_card_header_title">{i18n.t("CHANGE_PASSWORD")}</div>
              </div>
              <div>
                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-md-6">
                    <div className="info_card_text">{i18n.t("CURRENT_PASSWORD")}</div>
                    <input type="password" className="info_card_input" name="currentPassword" onChange={this.handleInput} />
                  </div>
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-md-6">
                    <div className="info_card_text">{i18n.t("NEW_PASSWORD")}</div>
                    <input type="password" className="info_card_input" name="newPassword" onChange={this.handleInput} />
                  </div>
                  <div className="col-md-6">
                    <div className="info_card_text">{i18n.t("CONFIRM_PASSWORD")}</div>
                    <input type="password" className="info_card_input" name="confirmPassword" onChange={this.handleInput} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center" style={{ marginTop: 60, marginBottom: 40, width: "100%" }}>
            <div className="button_type1" onClick={() => this.onSaveNewPassword()}>{i18n.t("SAVE")}</div>
            <div className="button_type2" onClick={() => this.onCancelPasswordChange()}>{i18n.t("CANCEL")}</div>
          </div>
        </div>
      </div>;

    const EditMode =
      <div style={{ marginTop: 30 }}>
        <div className="row">
          <div className="col-md-9" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <div className="info_card">
              <div className="info_card_header">
                <div className="row info_card_row">
                  <div className="col-md-12">
                    <div className="info_card_text">{i18n.t("CONTACT_INFORMATION2")}</div>
                  </div>
                  <div className="col-md-12">
                    <input type="text" className="info_card_input" name="address" placeholder="Address" value={this.state.address} onChange={this.handleInput} />
                  </div>

                  <div className="col-md-4">
                    <input type="text" className="info_card_input" name="postalCode" placeholder="Postal Code" value={this.state.postalCode} onChange={this.handleInput} />
                  </div>
                  <div className="col-md-4">
                    <input type="text" className="info_card_input" name="city" placeholder="City" value={this.state.city} onChange={this.handleInput} />
                  </div>
                  <div className="col-md-4">
                    <input type="text" className="info_card_input" name="country" placeholder="Country" value={this.state.country} onChange={this.handleInput} />
                  </div>

                  <div className="col-md-12">
                    <div className="info_card_text">{i18n.t("CELL")}</div>
                  </div>
                  <div className="col-md-12">
                    <input type="text" className="info_card_input" name="cellphone" placeholder="Cellphone" value={this.state.cellphone} onChange={this.handleInput} />
                  </div>

                </div>
              </div>
            </div>
            <div className="text-center" style={{ marginTop: 60, marginBottom: 40, width: "100%" }}>
              <div className="button_type1" onClick={() => this.onSave()}>{i18n.t("SAVE")}</div>
              <div className="button_type2" onClick={() => this.onCancel()}>{i18n.t("CANCEL")}</div>
            </div>
          </div>
        </div>
      </div>;

    var PencilIcon =
      <div className="ih_edit" onClick={() => this.handlePencilIconClick()}> <i className="fas fa-pen"></i></ div>;

    var PencilIconEdit =
      <div className="ih_edit" onClick={() => this.handlePencilIconEditClick()}><i className="fas fa-pen" style={{ opacity: 0.28 }}></i></div>;

    // if (this.state.editPassword || this.state.editMode) {
    //   PencilIcon = null;
    //   PencilIconEdit = null;
    // }

    const addIcon = <i className="fas fa-plus-circle" style={{ marginLeft: 10, fontSize: 27 }} onClick={() => this.setState({ addNewUser: true, showAddNewUserForm: true })}></i>;

    return (
      <div className="text-center">
        <div className="information_header">
          {this.state.registredUsers ? addIcon : null}
          <div className="ih_title">{i18n.t("USER_INFORMATION")}</div>
          {this.state.editMode || this.state.editRegistredUsers ? PencilIconEdit : PencilIcon}
        </div>
        <div style={{ textAlign: "center", fontSize: "14px", color: "#881401" }}>{this.state.error_message}</div>
        <div className="hr_choose_row">
          <div
            className="hr_choose_item"
            style={this.state.extraInfo ? { opacity: 1, cursor: "default" } : { opacity: 0.28 }}
            onClick={() => this.setState({ extraInfo: true, registredUsers: false, editRegistredUsers: false })}>
            {i18n.t("ADMINISTRATOR")} </div>
          {!["market_viewer", "private_account"].includes(this.state.user_typology) &&
            <>
              <div className="hr_choose_seperator"></div>

              <div className="hr_choose_item"
                style={this.state.registredUsers ? { opacity: 1, cursor: "default" } : { opacity: 0.28 }}
                onClick={() => this.setState({ registredUsers: true, extraInfo: false, editMode: false })}>
                {i18n.t("REGISTERED_USERS")}
              </div>
            </>
          }
        </div>


        <div className="position-relative" style={{ paddingTop: 30 }}>
          <div className="text-center">
            {this.state.extraInfo ? ExtraInfo : null}
            {this.state.editPassword ? EditPassword : null}
            {this.state.editMode ? EditMode : null}
            {this.state.passwordSavedSuccesfuly ? PasswordSavedSuccesfuly : null}
            {this.state.editSavedSuccesfuly ? EditSavedSuccesfuly : null}
            {this.state.registredUsers ?
              (this.state.addNewUser ?
                <AddNewUser toggleAddUser={this.toggleAddUser} toggleShowForm={this.toggleShowForm} showAddNewUserForm={this.state.showAddNewUserForm} /> :
                <RegistredUsers editRegistredUsers={this.state.editRegistredUsers} />) : null}
          </div>
        </div>
      </div>);

  }
}


export default graphql(user)(compose(
  graphql(update_user, { name: 'update_user' }),
  graphql(change_password, { name: 'change_password' }))(
    User));
