import i18n from 'i18next';
import React, { Component } from 'react';
import '../myprofile.scss';
import PaymentsInfo from './PaymentsInfo';
import payments from '../../../../queries/MyProfile/Payments';
import { graphql } from 'react-apollo';

class Payments extends Component {
  constructor(props) {
    super();
    this.state = {
      paymentsMode: true,
      editMode: false,
      paymentsSaveMode: false,
      payments_list: [],
      error_message: ""
    };

  }

  static getDerivedStateFromProps(nextProps) {
    if (!nextProps.data.loading) {
      return {
        payments_list: nextProps.data.payments.bankAccounts
      };

    }
    return null;
  }

  errorFired = condition => {
    if (condition) {
      this.setState({
        error_message: i18n.t("THIS_IS_YOUR_PRIMARY_BANK_ACCO")
      });

    } else {
      this.setState({
        error_message: ""
      });

    }
  };

  render() {

    var pencil =
      <div className="ih_edit" onClick={() => { this.setState({ editMode: true }); }}>
        <i className="fas fa-pen"></i>
      </div>;

    if (this.state.editMode) {
      pencil =
        <div className="ih_edit" onClick={() => { this.setState({ editMode: false }); }}>
          <i className="fas fa-pen" style={{ opacity: 0.28 }}></i>
        </div>;

    }

    const PaymentSavedSuccesfuly =
      <div className="SavedContainer" onClick={() => { this.setState({ editMode: false, contactSaveMode: false, contactMode: true }); }}>
        <div className="SavedContainerText">{i18n.t("CHANGES_SAVED")} <br /> {i18n.t("SUCCESSFULLY")}</div>
        <div className="SavedContainerIcon"><i className="fas fa-check"></i></div>
      </div>;

    let payments_account_list = this.state.payments_list.map((item, index) => {
      return (
        <PaymentsInfo item={item} defaultChecked={item.bank_accounts.length === 0 ? true : false} key={index} currency={item.currency} deleteMode={this.state.editMode} onErrorFired={this.errorFired} />);

    });
    var PaymentCards =
      <div className="row" style={{ marginTop: "18px" }}>
        {payments_account_list}
      </div>;


    return (
      <div>
        <div className="information_header">
          <div className="ih_title">{i18n.t("PAYMENTS_INFORMATION")}</div>
          {pencil}
        </div>
        <div className="hr_choose_row">
          <div className="info_subheader">{i18n.t("BANK_ACCOUNTS")}</div>
        </div>
        <div className="hr_note_text_mini">{i18n.t("NOTE_ALL_CHANGES_TO_YOUR_IBAN")}</div>
        <div style={{ textAlign: "center", fontSize: "11px", color: "#881401" }}>{this.state.error_message}</div>
        {this.state.paymentsMode ? PaymentCards : null}
        {this.state.paymentsSaveMode ? PaymentSavedSuccesfuly : null}
      </div>);

  }
  hide_icon() {
    this.setState({ icon_show: false });
  }
  show_icon() {
    this.setState({ icon_show: true });
  }
  checkChange(type, index) {
    // this.state.payments_list[type].primary_account = index;
    this.setState(Object.assign(this.state.payments_list[type], { primary_account: index }));
  }
}


export default graphql(payments)(Payments);
