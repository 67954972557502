import React from 'react';

const cros = (props) => (
    <div style={{display:"flex",height:"50px",justifyContent:"center",alignItems:"center"}}>
        <i className="fas fa-times-circle"
            style={{cursor : 'pointer',
                    color : 'white',
                    fontSize:'30px',
                   
                    }}
            onClick={props.clicked}></i>
    </div>
);

export default cros;