import gql from 'graphql-tag';

export default gql`
mutation addFiscalizationData($attributes: FiscalizationInput!, $user_id: ID!){
  addFiscalizationData(attributes: $attributes, userId: $user_id) {
    success
  	errors
  }
}

`;
