import i18n from 'i18next';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import classes from './NavigationItems.module.css';
class NavigationItems extends Component {

  state = {
    Login: false,
    Logout: false
  };


  componentDidMount() {
    const { items } = this.props;
    if (items.includes('Login'))
      this.setState({ Login: true });
    if (items.includes('Logout'))
      this.setState({ Logout: true });
  }

  setDirection = () => {
    this.props.setDirection();
  };


  render() {

    return (
      <ul className={classes.NavigationItems}>
        {this.state.Login ? <li>  <NavLink to="/login" exact>{i18n.t("LOGIN")}</NavLink> </li> : null}
        {this.state.Logout ? <li>  <div onClick={this.setDirection} style={{ color: 'white', marginLeft: '10px', cursor: "pointer" }}>{i18n.t("LOGOUT")}</div> </li> : null}
      </ul>);


  }
}

export default withRouter(NavigationItems);