import i18n from 'i18next'; 
import React, { Component } from 'react';
import classes from './frequency.module.scss';

export default class Frequency extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
     * Set the wrapper ref
     */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
    * close modal if clicked on outside of element
    */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.id !== 'frequencyModalIcon') {
      this.props.close_modal();
    }
  }

  render() {
    return (
      <div ref={this.setWrapperRef} className={[classes.modal_item, classes.frequency_modal].join(' ')}>
        <div className={classes.modal_header}>
          <div className={classes.frequency_modal_header}>{i18n.t("FREQUENCY")}</div>
          <div className={classes.frequency_modal_header_icon}>
            <i className="fas fa-undo-alt"></i>
          </div>
        </div>

        <div className={[classes.modal_body, classes.frequency_modal_body].join(' ')}>
          <div className={classes.frequency_click_item}>{i18n.t("HOURLY")}</div>
          <div className={classes.frequency_click_item}>{i18n.t("DAILY")}</div>
          <div className={classes.frequency_click_item}>{i18n.t("WEEKLY")}</div>
          <div className={classes.frequency_click_item}>{i18n.t("MONTHLY")}</div>
        </div>
      </div>);

  }
}