import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Layout from './Layout/Layout';
import Introduction from './containers/Introduction/Introduction';
import Login from './containers/Login/Login';
// import Register from './containers/Register/Register';
import Dashboard from './containers/Dashboard/Dashboard';

import './language/inext/i18n';
// import AccountSelection from './containers/Register/AccountSelection/AccountSelection';
// import RegisterPrivateAccount from './containers/Register/RegisterPrivateAccount';
// import RegisterMarketViewer from './containers/Register/RegisterMarketViewer';
// import RegisterServicePoint from './containers/Register/RegisterServicePoint';

import OrderInitialization from './containers/Dashboard/CurrencyTrader/DossierModal/CurrencyTraderOrders/OrderInitialization';
import BestOffer from './containers/Dashboard/CurrencyTrader/DossierModal/CurrencyTraderOrders/BestOffer';
import TransactionCompleted from './containers/Dashboard/CurrencyTrader/DossierModal/CurrencyTraderOrders/TransactionCompleted';
import ModifyOffer from './containers/Dashboard/CurrencyTrader/DossierModal/CurrencyTraderOrders/ModifyOffer';
import RecapOrder from './containers/Dashboard/CurrencyTrader/DossierModal/CurrencyTraderOrders/RecapOrder';
import PartialAcceptance from './containers/Dashboard/CurrencyTrader/DossierModal/CurrencyTraderOrders/PartialAcceptance';

import SellCurrency from './containers/Dashboard/CurrencyTrader/Sale/SellCurrency';
import BuyCurrency from './containers/Dashboard/CurrencyTrader/Purchase/BuyCurrency';
import BoughtRegistration from './containers/Dashboard/CashManager/CheckoutModal/Bought/BoughtRegistration';
import SoldRegistration from './containers/Dashboard/CashManager/CheckoutModal/Sold/SoldRegistration';
import Invoice from './containers/Dashboard/CashManager/CheckoutModal/Invoice/Invoice';
import BoughtCancel from './containers/Dashboard/CashManager/CheckoutModal/Bought/BoughtCancel';
import BoughtCompleted from './containers/Dashboard/CashManager/CheckoutModal/Bought/BoughtCompleted';
import BoughtIndividuals from './containers/Dashboard/CashManager/CheckoutModal/Bought/ExistingClients/BoughtIndividuals';
import BoughtBusiness from './containers/Dashboard/CashManager/CheckoutModal/Bought/ExistingClients/BoughtBusiness';
import SoldBusiness from './containers/Dashboard/CashManager/CheckoutModal/Sold/ExistingClients/SoldBusiness';
import SoldIndividuals from './containers/Dashboard/CashManager/CheckoutModal/Sold/ExistingClients/SoldIndividuals';
import SoldCancel from './containers/Dashboard/CashManager/CheckoutModal/Sold/SoldCancel';
import SoldCompleted from './containers/Dashboard/CashManager/CheckoutModal/Sold/SoldCompleted';
import CustomerRegister from './containers/CustomerRegister/CustomerRegister';
import ProjectRates from './containers/ProjectRates/ProjectRates';
import PrivateRoute from './PrivateRoute';
import query from './queries/LoggedUser';
import { graphql } from 'react-apollo';
import compose from 'lodash/fp/compose';
import EmployeeSignUp from './containers/Modals/MyProfile/User/EmployeeSignUp/SignUpComponent';


const App = ({ data: { loading, error, currentUser } }) => {

  return (
    <div style={{ height: '100%' }}>
      {!loading ? <Layout>
        <Switch>
          <PrivateRoute path="/introduction" currentUser={currentUser} component={Introduction} />
          <Route path="/login" component={Login} />
          {/* <Route path="/signup" exact component={AccountSelection} />
          <Route path="/register" component={Register} />
          <Route path="/servicepointaccount" component={RegisterServicePoint} />
          <Route path="/businessaccount" component={Register} />
          <Route path="/privateaccount" component={RegisterPrivateAccount} />
          <Route path="/marketviewer" component={RegisterMarketViewer} /> */}
          <Route path="/signup/employee" component={EmployeeSignUp} />
          <PrivateRoute path="/dashboard" currentUser={currentUser} component={Dashboard} />

          <PrivateRoute path="/initialization" currentUser={currentUser} component={OrderInitialization} />
          <PrivateRoute path="/bestoffer" currentUser={currentUser} component={BestOffer} />
          <PrivateRoute path="/modifyoffer" currentUser={currentUser} component={ModifyOffer} />
          <PrivateRoute path="/completedorder" currentUser={currentUser} component={TransactionCompleted} />
          <PrivateRoute path="/partialacceptance" currentUser={currentUser} component={PartialAcceptance} />
          <PrivateRoute path="/recaporder" currentUser={currentUser} component={RecapOrder} />

          <PrivateRoute path="/buyCurrency" currentUser={currentUser} component={BuyCurrency} />
          <PrivateRoute path="/sellCurrency" currentUser={currentUser} component={SellCurrency} />
          <PrivateRoute path="/boughtregistration" currentUser={currentUser} component={BoughtRegistration} />
          <PrivateRoute path="/soldregistration" currentUser={currentUser} component={SoldRegistration} />
          <PrivateRoute path="/invoice" currentUser={currentUser} component={Invoice} />
          <PrivateRoute path="/boughtcancel" currentUser={currentUser} component={BoughtCancel} />
          <PrivateRoute path="/soldcancel" currentUser={currentUser} component={SoldCancel} />
          <PrivateRoute path="/boughtcompleted" currentUser={currentUser} component={BoughtCompleted} />
          <PrivateRoute path="/soldcompleted" currentUser={currentUser} component={SoldCompleted} />
          <PrivateRoute path="/boughtindividuals" currentUser={currentUser} component={BoughtIndividuals} />
          <PrivateRoute path="/soldindividuals" currentUser={currentUser} component={SoldIndividuals} />
          <PrivateRoute path="/boughtbusiness" currentUser={currentUser} component={BoughtBusiness} />
          <PrivateRoute path="/soldbusiness" currentUser={currentUser} component={SoldBusiness} />
          <PrivateRoute path="/customerRegister" currentUser={currentUser} component={CustomerRegister} />
          <PrivateRoute path="/project_rates" currentUser={currentUser} component={ProjectRates} />

          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Layout> : null}
    </div>
  );
}

export default compose(graphql(query), withTranslation())(App);
