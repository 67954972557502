import gql from 'graphql-tag';

export default gql`

mutation updatePersonalization($homepage: [String!], $quick_registration: [String!], $my_checkout: [String!], $duration: String, $buy_currency: [String!], $sell_currency: [String!]) {
  updatePersonalization(homepage: $homepage, myCheckout: $my_checkout, quickRegistration: $quick_registration, duration: $duration, buyCurrency: $buy_currency, sellCurrency: $sell_currency) {
    homepage
    myCheckout
    duration
    quickRegistration
    buyCurrency
    sellCurrency
  }
}


`;
