import React, { Component } from 'react';
import Language from './Language/Language';
import NavigationItems from './NavigationItems/NavigationItems';
import classes from './Toolbar.module.css';
import UserIcon from './UserIcon/UserIcon';
import ShareSquare from '../UI/ShareSquare/ShareSquare';
import mutation from '../../mutations/Logout';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import CustomerRegisterNav from './CustomerRegiterNav/CustomerRegisterNav';

class toolbarLogout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.current_user,
    };

  }

  giveDirection = () => {
    this.props.mutate({}).then(res => {
    });
    this.props.history.push("/login");
  };

  projectRates= () => {
    window.open("/project_rates", "_blank");
  }


  render() {
    const user = this.state.user
    return (
      <header className={classes.Toolbar}>
        <div className={classes.RightNav}>
          <Language />
          {user && this.state.user.servicePoint.typology === "service_point" ? <CustomerRegisterNav /> : null}
          <div style={{ color: 'white', fontSize: '20px', justifyContent: 'baseline', marginLeft:"13px", cursor:"pointer"}}><b>{user ? user.name : null}</b></div>
        </div>
        <div className={classes.ProfileSettings}>
        {user ?
          <div style={{display:"inline-flex"}}>
            {user.servicePoint.typology === "service_point" ? <i className={"fas fa-tv "} onClick={this.projectRates} style={{ color: 'white', fontSize: '20px', justifyContent: 'baseline', marginRight:"13px", cursor:"pointer"}}></i> : null}
            {this.state.user.servicePoint.typology === "service_point" && this.state.user.isStoreAdmin ? <ShareSquare /> : null}
            {this.props.my_profile ? <UserIcon /> : null}
          </div>
        :
          null
        }
          <nav className={classes.NavigationItems}>
            <NavigationItems setDirection={this.giveDirection} items={['Logout']} />
          </nav>
        </div>
      </header>);

  }
}

;

export default withRouter(
  graphql(mutation)(toolbarLogout));
