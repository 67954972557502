import gql from 'graphql-tag';


export default gql`

query($order_type:String!, $desired_amount:String!, $currency:String!, $paid_currency:String! ) {

  bestOffer(orderType: $order_type, desiredAmount: $desired_amount, currency: $currency, paidCurrency: $paid_currency){
    bestOffer
  }
}

`;
