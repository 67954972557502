import i18n from 'i18next';
import React, { Component } from 'react';
import CalendarComponent from './Calendar';
import classes from './TopUpsFilter.module.scss';
import MultiSelectDropdown from '../../../components/UI/Dropdown/MultiSelectDropdown';

var dateFormat = require('dateformat');

class TopUpsFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date_input_type: "min",
            calendar_date: new Date(),
            min_date: props.attributes ? props.attributes.minDate : dateFormat(new Date, "dd/mm/yyyy"),
            min_date_CF: new Date(),
            max_date: props.attributes ? props.attributes.maxDate : dateFormat(new Date, "dd/mm/yyyy"),
            max_date_CF: new Date(),
            categories: this.props.current_user.isStoreAdmin ?
            [
                { value: "exchange", name: "Exchange" },
                { value: "deposit", name: "Deposit" },
                { value: "withdrawal", name: "Withdrawal" },
                { value: "payment", name: "Payment" },
                { value: "transfer", name: "Transfer" }
            ]
              :
              [
                  { value: "exchange", name: "Exchange" },
                  { value: "deposit", name: "Deposit" },
                  { value: "withdrawal", name: "Withdrawal" },
                  { value: "payment", name: "Payment" }
              ],
            selectedList: props.categoriesSelectedList
        };


        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    cancelFunction = () => {
        this.props.closeModal();
    };

    saveFunction = () => {
        this.props.closeModal(this.state);
    };

    onChange = date => {

        this.setState({
            date: date,
            date_input: dateFormat(date, "dd/mm/yyyy")
        });

    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
       * Set the wrapper ref
       */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
      * close modal if clicked on outside of element
      */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.classList.value.search('calendarModalIconCM') === -1) {
            this.props.closeModal();
        }
    }

    onChangeCalendar = date => {

        if (this.state.date_input_type === "min") {
            this.setState({
                min_date: dateFormat(date, "dd/mm/yyyy"),
                min_date_CF: date
            });

        } else {
            this.setState({
                max_date: dateFormat(date, "dd/mm/yyyy"),
                max_date_CF: date
            });

        }

        this.setState({
            calendar_date: date,
            calendar_active: false
        });

    };

    onSelect = (selectedList, selectedItem) => {
        this.setState({ selectedList: [...selectedList] });
    };

    onRemove = (selectedList, selectedItem) => {
        this.setState({ selectedList: [...selectedList] });
    };

    resetFilters = () => {
        this.setState({
            min_date_CF: new Date(),
            max_date_CF: new Date(),
            min_date: dateFormat(new Date, "dd/mm/yyyy"),
            max_date: dateFormat(new Date, "dd/mm/yyyy"),
            selectedList: []
        });
    }

    render() {

        return (
            <div ref={this.setWrapperRef} className={classes.modal_item}>
                <div className={classes.modal_header}>
                    <div className={classes.settings_calendar_modal_header}>{i18n.t("FILTERS")}</div>
                    <div className={classes.modal_header_icon}>
                        <i className="fas fa-redo-alt fa-flip-horizontal" onClick={this.resetFilters}></i>
                    </div>
                </div>

                <div>

                    <div className={classes.modal_body} style={{ paddingBottom: 60 }}>

                        <div className={classes.filterItem}>
                            <div className={classes.itemHeader}>
                                <p className={classes.itemTitle}>{i18n.t("CATEGORY")}</p> </div>
                            <div style={{ margin: "10px 0px" }}>
                                <MultiSelectDropdown
                                    options={this.state.categories}
                                    selectedValues={this.state.selectedList}
                                    onSelect={this.onSelect}
                                    onRemove={this.onRemove}
                                    ref={this.child}
                                    closeOnSelect={false}
                                    flex={"column"}/>
                            </div>
                        </div>

                        <div className={classes.filterItem}>
                            <div className={classes.itemHeader}>
                                <p className={classes.itemTitle}>{i18n.t("DATE_RANGE")}</p>
                            </div>
                            <div className={classes.currencies} style={{ position: "initial" }}>
                                <div style={{ marginRight: "2.5px" }} onClick={() => this.setState({ date_input_type: "min", calendar_active: true })}>
                                    <input type="text"
                                        className={[classes.inputs, classes.left_input].join(' ')}
                                        placeholder="00/00/00"
                                        value={this.state.min_date}
                                        onChange={e => { this.setState({ min_date: e.target.value }); }}
                                        readOnly />
                                </div>
                                <div style={{ marginLeft: "2.5px" }} onClick={() => this.setState({ date_input_type: "max", calendar_active: true })}>
                                    <input type="text"
                                        className={classes.inputs}
                                        placeholder="00/00/00"
                                        value={this.state.max_date}
                                        onChange={e => { this.setState({ max_date: e.target.value }); }}
                                        readOnly />
                                </div>

                                {this.state.calendar_active ?
                                    <div className="filter_calendar" style={{ position: "absolute", zIndex: 99, bottom: -173 }}>
                                        <CalendarComponent
                                            onChange={this.onChangeCalendar}
                                            value={this.state.date_input_type === "min" ? this.state.min_date_CF : this.state.max_date_CF} />
                                    </div> :
                                    null}
                            </div>
                        </div>
                    </div>
                    <div className={classes.action_buttons}>
                        <div className="button_type1" onClick={this.saveFunction}>{i18n.t("SAVE")}</div>
                        <div className="button_type2" onClick={this.cancelFunction}>{i18n.t("CANCEL")}</div>
                    </div>
                </div>
            </div>);

    }
}


export default TopUpsFilter;
