import gql from 'graphql-tag';

export default gql`

mutation createServicePoint($attributes:ServicePointInput!, $user_id:ID!) {
  createServicePoint(attributes:$attributes, userId:$user_id) {
    id
    }
}

`;
