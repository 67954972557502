import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './MarketRates.module.scss';
import Calendar from '../../../assets/icons/calendar.svg';
import Graph from './MarketRatesGraph/Graph';
import MyAlert from '../../Modals/MyAlert/myalert';
import Frequency from '../../Modals/Frequency/frequency';
import CalendarModal from '../../Modals/Calendar/calendar';

class MarketRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      myalert_modal: false,
      frequency_modal: false,
      calendar_modal: false,
      val: 1
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.update == this.props.update) {
      return false
    }
    else {
      return true
    }
  }

  render() {
    return (
      <div className={classes.dashboardItem}>
        <div className={classes.dashboardHeader}>
          <div className={classes.dashboardHeaderLeftSide}>
            <div className={classes.dashboardHeaderTitle}> {i18n.t("MARKET_RATES")} </div>
            <div className="position-relative">
              {this.state.myalert_modal ? <MyAlert close_modal={this.close_modal.bind(this)} /> : null}
              <i className="fas fa-bell" id="my_alert_bell" onClick={this.myalert_toggle.bind(this)}></i>
            </div>
          </div>
        </div>
        <div style={{ padding: '20px' }}>
          <div>
            <div className="row no-gutters">
              <div className={["col-lg-6", classes.brRight, classes.brBottom].join(' ')}>
                <Graph panel="Market Rates" currency="EUR" position={1} showMaxIcon={true} showMaxIcon={true} width={410} />
              </div>
              <div className={["col-lg-6", classes.brBottom].join(' ')}>
                <Graph panel="Market Rates" currency="USD" position={2} showMaxIcon={true} showMaxIcon={true} width={410} />
              </div>
              <div className={["col-lg-6", classes.brRight].join(' ')}>
                <Graph panel="Market Rates" currency="CHF" position={3} showMaxIcon={true} showMaxIcon={true} width={410} />
              </div>
              <div className="col-lg-6">
                <Graph panel="Market Rates" currency="GBP" position={4} showMaxIcon={true} showMaxIcon={true} width={410} />
              </div>
            </div>
          </div>
        </div>

      </div>);

  }
  myalert_toggle() {
    this.setState({ myalert_modal: !this.state.myalert_modal });
  }
  close_modal() {
    this.setState({ myalert_modal: false });
  }
  frequency_toggle() {
    this.setState({ frequency_modal: !this.state.frequency_modal });
  }
  close_frequency_modal() {
    this.setState({ frequency_modal: false });
  }
  calendar_toggle() {
    this.setState({ calendar_modal: !this.state.calendar_modal });
  }
  close_calendar_modal() {
    this.setState({ calendar_modal: false });
  }
}


export default MarketRates;
