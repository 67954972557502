import i18n from 'i18next'; 
import React, { Component } from 'react';
import classes from './calendar.module.scss';


/*
                                              Calendar Reference
                                              https://airbnb.io/react-dates/?path=/story/daypicker--vertical-with-fixed-width-container
                                              */

export default class CalendarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekday_names: ['Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun'],
      dates: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
    };



    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
     * Set the wrapper ref
     */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
    * close modal if clicked on outside of element
    */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.id !== 'calendarModalIcon') {
      this.props.close_modal();
    }
  }

  render() {
    var weekday_names_list = this.state.weekday_names.map((index, key) => {
      return (
        <div className={classes.weekDayItem} key={key}>{index}</div>);

    });
    var DateNumbers_list = this.state.dates.map((index, key) => {
      return (
        <div className={classes.DateDayNumber} key={key}>{index}</div>);

    });
    var FirstCalendar =
      <div className={classes.miniCalendar}>
        <div className={classes.miniCalendarHeader}>
          <div className={classes.mini_arrows}><i className="fas fa-caret-left"></i></div>
          <div className={classes.monthName}>January 2019</div>
          <div className={classes.mini_arrows}><i className="fas fa-caret-right"></i></div>
        </div>
        <div className={classes.miniCalendarBody}>
          <div className={classes.weekDay}>{weekday_names_list}</div>
          <hr style={{ marginTop: '5px', marginBottom: '5px' }} />
          <div className={classes.DateDay}>{DateNumbers_list}</div>
        </div>
      </div>;

    var SecondCalendar =
      <div className={classes.miniCalendar}>
        <div className={classes.miniCalendarHeader}>
          <div className={classes.mini_arrows}><i className="fas fa-caret-left"></i></div>
          <div className={classes.monthName}>February 2019</div>
          <div className={classes.mini_arrows}><i className="fas fa-caret-right"></i></div>
        </div>
        <div className={classes.miniCalendarBody}>
          <div className={classes.weekDay}>{weekday_names_list}</div>
          <hr style={{ marginTop: '5px', marginBottom: '5px' }} />
          <div className={classes.DateDay}>{DateNumbers_list}</div>
        </div>
      </div>;


    return (
      <div ref={this.setWrapperRef} className={[classes.modal_item, classes.calendar_modal].join(' ')}>
        <div className={classes.modal_header}>
          <div className={classes.calendar_modal_header}>
            <div className={classes.calendar_modal_header_text}>{i18n.t("PERIOD1")}
              <i className="fas fa-link"></i></div>
            <div className={classes.calendar_modal_header_left_icon}>
              <i className="fas fa-redo-alt fa-flip-horizontal"></i>
            </div>
          </div>
        </div>
        <div className={classes.modal_body}>
          <div className="row">
            <div className="col-lg-6">
              <div className={classes.cm_row}>
                <div className={classes.cm_text}>{i18n.t("FROM")}</div>
                <div className={classes.cm_input}><input type="text" placeholder="00/00/0000" /></div>
              </div>
              <div style={{ marginTop: '10px' }}>
                {FirstCalendar}
              </div>
            </div>
            <div className="col-lg-6">
              <div className={classes.cm_row}>
                <div className={classes.cm_text}>{i18n.t("TO")}</div>
                <div className={classes.cm_input}><input type="text" placeholder="00/00/0000" /></div>
              </div>
              <div style={{ marginTop: '10px' }}>
                {SecondCalendar}
              </div>
            </div>
          </div>
        </div>
      </div>);

  }
}