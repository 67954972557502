import gql from 'graphql-tag';

export default gql`
query {
    personalization{
  	homepage
    myCheckout
    duration
    quickRegistration
    buyCurrency
    sellCurrency
  }
}
`;
