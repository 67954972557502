import gql from 'graphql-tag';

export default gql`
mutation createEmployee($email: String!, $password: String!, $password_confirmation: String!, $first_name: String!, $last_name:String!) {
  createEmployee(email:$email, password:$password, passwordConfirmation:$password_confirmation, firstName:$first_name, lastName:$last_name) {
    id
  }
}
`;
/* After being created a confirmation link is sent to user email to continue registration */
