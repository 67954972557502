import gql from 'graphql-tag';
export default gql`

query($currency:[String!]) {
  bankAccounts(currency:$currency){
    id
    iban,
    bankName
    primary
    currency,
    user{
      firstName
    }
  }
}

`;
