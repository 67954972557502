import gql from 'graphql-tag';

export const sale_customer = gql`
mutation createCustomer($attributes:CustomerInput!, $saleOrderId:ID){
    createCustomer(attributes:$attributes, saleOrderId:$saleOrderId) {
   id
   cellphone
   city
   country
   firstName
   lastName
   personalIdNumber
   postalCode
   typology
   rea
    }
  }
`;


const purchase_customer = gql`
mutation createCustomer($attributes:CustomerInput!, $purchaseOrderId:ID){
    createCustomer(attributes:$attributes, purchaseOrderId:$purchaseOrderId) {
   id
   cellphone
   city
   country
   firstName
   lastName
   personalIdNumber
   postalCode
   typology
   rea
    }
  }
`;




export default purchase_customer;
