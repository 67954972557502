import React, { useEffect, useState } from 'react';
import '../myprofile.scss';
import "./UserStyle.css";
import i18n from 'i18next';
import { useMutation } from 'react-apollo';
import InviteEmployee from '../../../../mutations/EmployeeRegistration/InviteEmployee';

const AddNewUser = (props) => {

    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [inviteEmployee] = useMutation(InviteEmployee);
    const [message, setMessage] = useState(null);
    const [showForm, setShowForm] = useState(props.showAddNewUserForm);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }


    const onSave = () => {
        inviteEmployee({ variables: { email: email, address: address, city: city, country: country } }).then(result => {
            setMessage(null);
            setShowForm(false);
            props.toggleShowForm();
        }).catch(error => {
            if (error.message === "GraphQL error: email exist") setMessage(<p style={{ color: "#9A2020" }}>{i18n.t("EMAIL_EXISTS")}</p>)
            else if (error.message === "GraphQL error: Invalid input: Email is invalid") setMessage(<p style={{ color: "#9A2020" }}>{i18n.t("EMAIL_IS_INVALID")}</p>)
        });
    }

    const onCancel = () => {
        props.toggleAddUser();
    }

    useEffect(() => {
        setShowForm(props.showAddNewUserForm);
        setEmail("");
    }, [props.showAddNewUserForm]);

    return (
        <div style={{ marginTop: 30 }}>
            {showForm ?
                <div className="row">
                    <div className="col-md-9" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="info_card">
                            <div className="info_card_header">
                                <div className="row info_card_row">
                                    <div className="col-md-12" style={{ marginBottom: 20 }}>
                                        <div className="info_card_text" style={{ textAlign: "left" }}>{i18n.t("ADD_NEW_USER")}</div>
                                    </div>
                                    {message}
                                    <div className="col-md-12" style={{ textAlign: "left" }}>
                                        <div style={{ fontSize: 13, marginBottom: 10 }}><b>{i18n.t("EMAIL")}</b></div>
                                        <input type="text" className="info_card_input" name="email" placeholder="Email" value={email} onChange={handleEmailChange} />
                                    </div>
                                    <div className="col-md-12" style={{ textAlign: "left" }}>
                                        <div style={{ fontSize: 13, marginBottom: 10 }}><b>{i18n.t("ADDRESS")}</b></div>
                                        <input type="text" className="info_card_input" name="address" placeholder="Address" value={address} onChange={(e) => {setAddress(e.target.value)}} />
                                    </div>
                                    <div className="col-md-12" style={{ textAlign: "left" }}>
                                        <div style={{ fontSize: 13, marginBottom: 10 }}><b>{i18n.t("CITY")}</b></div>
                                        <input type="text" className="info_card_input" name="city" placeholder="City" value={city} onChange={(e) => {setCity(e.target.value)}} />
                                    </div>
                                    <div className="col-md-12" style={{ textAlign: "left" }}>
                                        <div style={{ fontSize: 13, marginBottom: 10 }}><b>{i18n.t("COUNTRY")}</b></div>
                                        <input type="text" className="info_card_input" name="country" placeholder="Country" value={country} onChange={(e) => {setCountry(e.target.value)}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center" style={{ marginTop: 60, marginBottom: 40, width: "100%" }}>
                            <div className="button_type1" onClick={() => onSave()}>{i18n.t("SAVE")}</div>
                            <div className="button_type2" onClick={() => onCancel()}>{i18n.t("CANCEL")}</div>
                        </div>
                    </div>
                </div> :
                <div>{i18n.t("INVITATION")}</div>
            }
        </div>
    )
}

export default AddNewUser;
