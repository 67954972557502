import gql from 'graphql-tag';


export default gql`

query ($attributes: FilterCashManagerInput) {
  orders(attributes: $attributes) {
    ... on PurchaseOrder {
      amountPaidCents
      id
      amountPaidCurrency
      amountPurchasedCents
      amountPurchasedCurrency
      commission
      datetime
      rate
      number
      portfolio {
        user {
          name
          firstName
          lastName
          globalName
          cellphone
          city
          country
          email
          address
          personalIdNumber
          dateOfBirth
          postalCode
          tradingDailyLimit
          servicePoint {
            commercialName
            fiscalCode
            city
            country
            address
            phone
            fullInvoiceAddress
          }
          document {
            typology
            issueDate
            issueEntity
            expirationDate
            number
            description
            identityDocumentUrl
            identityDocumentFilename
          }
        }
      }
      companyRepresentative
      customer {
        firstName
        lastName
        city
        country
        address
        personalIdNumber
        postalCode
        cellphone
        email
        rea
        typology
      }
    }
    ... on SaleOrder {
      id
      amountPaidCents
      amountSoldCents
      amountPaidCurrency
      amountSoldCurrency
      commission
      datetime
      rate
      number
      companyRepresentative
      portfolio {
        user {
          name
          firstName
          lastName
          globalName
          cellphone
          city
          country
          email
          address
          personalIdNumber
          dateOfBirth
          postalCode
          tradingDailyLimit
          servicePoint {
            commercialName
            fiscalCode
            city
            country
            address
            phone
            fullInvoiceAddress
          }
          document {
            typology
            issueDate
            issueEntity
            expirationDate
            number
            description
            identityDocumentUrl
            identityDocumentFilename
          }
        }
      }
      customer {
        firstName
        lastName
        city
        country
        address
        personalIdNumber
        postalCode
        cellphone
        email
        rea
        typology
      }
    }
  }
}
`;
