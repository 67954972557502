import React, { useState } from 'react';
import i18n from 'i18next';
import classes from '../Login.module.css';
import classes1 from '../../../containers/Dashboard/CurrencyTrader/DossierModal/BuySell.module.scss';
import Button from '../../../components/UI/Button/Button';
import { useMutation } from 'react-apollo';
import UpdatePasswordWithToken from '../../../mutations/Login/UpdatePasswordWithToken';
import { data } from 'jquery';
import { propStyle } from 'aws-amplify-react';

const ResetPassword = (props) => {

    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
    const [newPassword, setNewPassword] = useState({
        password: "",
        confirmPassword: ""
    });
    const [message, setMessage] = useState(null);
    const [updatePasswordWithToken] = useMutation(UpdatePasswordWithToken);

    const handleInput = (event) => {
        event.persist();
        const fieldName = event.target.name;
        setNewPassword({ ...newPassword, [fieldName]: event.target.value });
    }

    const changePassword = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');
        if (newPassword.password === "" && newPassword.confirmPassword === "") {
            setMessage(<p style={{ color: "#992020" }}>{i18n.t("ENTER_BOTH_FIELDS")}</p>);
        }
        else {
            updatePasswordWithToken({
                variables: {
                    reset_password_token: token,
                    password: newPassword.password,
                    password_confirmation: newPassword.confirmPassword
                }
            }).then((data) => {
                //props.history.push("/login");
                setResetPasswordSuccess(true);
            }).catch((error) => {
                console.log(error.message)
                if (error.message === "GraphQL error: Error: Reset password token is invalid") {
                    setMessage(<p style={{ color: "#992020" }}>{i18n.t("RESET_PASSWORD_TOKEN")}</p>);
                }
                else if (error.message === "GraphQL error: Error: Password confirmation doesn't match Password") {
                    setMessage(<p style={{ color: "#992020" }}>{i18n.t("PASSWORD_CONFIRMATION")}</p>);
                }
                else if (error.message === "GraphQL error: Error: Password is too short (minimum is 6 characters)") {
                    setMessage(<p style={{ color: "#992020" }}>{i18n.t("PASSWORD_TOO_SHORT")}</p>);
                }
            });
        }
    }

    const closeHandler = () => {
        props.history.push("/login");
    };

    const handleOnKeyPress = (event) => {
        if (event.key === 'Enter') {
            changePassword();
        }
    }

    const resetPasswordSuccessHTML = (<div className={classes.Components2}>
        <div className={classes.Head}></div>
        <div className={classes.contentTextLogin}>
            <p className={classes.infoTextLogin}>{i18n.t("THE_PASSWORD_WAS_CHANGED")} <br />
                {i18n.t("YOU_CAN_LOGIN_TO_YOUR_ACCOUNT")} <br />
                {i18n.t("USING_THE_NEW_PASSWORD")}</p>
            <Button
                clicked={() => closeHandler()}
                extraStyle={classes.closeButton}>

                {i18n.t("CLOSE")}
            </Button>
        </div>
    </div>);

    const form = (<div className={classes.Components2} style={{ height: 390 }}>
        <div className={classes.Head}></div>
        <p className={classes.enterYourEmailText}>{i18n.t("PLEASE_ENTER_YOUR_NEW_PASSWORD")}</p>
        {message}
        <div>
            <input
                type="password"
                style={{ width: 285 }}
                name="password"
                placeholder="Enter Password"
                className={classes1.inputs}
                onChange={handleInput}
                value={newPassword.password}
                onKeyPress={handleOnKeyPress} />

            <input
                type="password"
                style={{ width: 285 }}
                name="confirmPassword"
                placeholder="Confirm Password"
                className={classes1.inputs}
                onChange={handleInput}
                value={newPassword.confirmPassword}
                onKeyPress={handleOnKeyPress} />
        </div>
        <Button
            clicked={changePassword}
            extraStyle={classes.nextButton}>
            {i18n.t("CONFIRM")}
        </Button>
    </div>)

    return (
        <div>
            {!resetPasswordSuccess ? form : resetPasswordSuccessHTML}

        </div>
    );
}

export default ResetPassword;