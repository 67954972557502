import gql from 'graphql-tag';

export default gql`
mutation createExchangeRate($attributes:ExchangeRateInput!, $group_id:ID){
  createExchangeRate(attributes:$attributes, groupId:$group_id) {
    id
    currency
    datetime
    purchaseRate
    saleRate
  }
}

`;
