import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import maximize from '../../../../assets/icons/Expand.svg';
import minimize from '../../../../assets/icons/Minimize_icon.svg';
import classes from './graph.module.css';
import { useQuery } from '@apollo/react-hooks';
import MarketRatesQuery from '../../../../queries/MarketRates/MarketRates';

const MarketRatesGraph = (props) => {

    const [candles, setCandles] = useState([]);
    const [buyRate, setBuyRate] = useState(0);
    const [buyRateColor, setBuyRateColor] = useState('#649177');
    const [sellRate, setSellRate] = useState(0);
    const [sellRateColor, setSellRateColor] = useState('#649177');

    const marketRatesQuery = useQuery(MarketRatesQuery, {
        variables: { currency: props.currency }, onCompleted: () => {
            if (!marketRatesQuery.error && !marketRatesQuery.loading) {
                setCandles([...marketRatesQuery.data.marketRates[0].candles]);
                setBuyRate(marketRatesQuery.data.marketRates[0].buyRate);
                setSellRate(marketRatesQuery.data.marketRates[0].sellRate);

                const buyRateTemp = marketRatesQuery.data.marketRates[0].buyRate;
                const prevBuyRateTemp = marketRatesQuery.data.marketRates[0].prevBuyRate;
                if (buyRateTemp < prevBuyRateTemp) setBuyRateColor('#9a2020')
                const sellRateTemp = marketRatesQuery.data.marketRates[0].sellRate;
                const prevSellRateTemp = marketRatesQuery.data.marketRates[0].prevSellRate;
                if (sellRateTemp < prevSellRateTemp) setSellRateColor('#9a2020')
            }
        }
    });

    const options = {

        time: {
            useUTC: false
        },

        chart: {
            height: props.height,
            width: props.width,
        },

        rangeSelector: {
            // selected: 3,
            inputPosition: {
                align: props.maximize ? "right" : "left"
            },
            inputDateFormat: "%d-%m-%Y",
            inputEditDateFormat: "%d-%m-%Y",
            inputDateParser: function (value) {
                value = value.split('-');
                return Date.UTC(
                    parseInt(value[2]),
                    parseInt(value[1] - 1),
                    parseInt(value[0])
                );
            },
            allButtonsEnabled: true,
            buttons: [{
                type: 'minute',
                text: '5 min',
                count: 200,
                dataGrouping: {
                    forced: true,
                    units: [['minute', [5]]]
                }
            }, {
                type: 'minute',
                text: '15 min',
                count: 300,
                dataGrouping: {
                    forced: true,
                    units: [['minute', [15]]]
                }
            }, {
                type: 'hour',
                text: '1 hrs',
                count: 10,
                dataGrouping: {
                    forced: true,
                    units: [['hour', [1]]]
                }
            }, {
                type: 'day',
                text: '1 day',
                count: 10,
                dataGrouping: {
                    forced: true,
                    units: [['day', [1]]]
                }
            }, {
                type: 'week',
                text: '1 week',
                count: 100,
                dataGrouping: {
                    forced: true,
                    units: [['week', [1]]]
                }
            }],
            buttonTheme: {
                width: 40
            },
        },

        title: {
            text: "<p> <b> <span style='font-size: 27px'>" + props.currency + " &nbsp;</span>" +
                "<span style='font-size: 20px'>SELL:</span> <span style='font-size: 20px; color:" + sellRateColor + "'>" + sellRate + "</span> | " +
                "<span style='font-size: 20px'>BUY:</span> <span style='font-size: 20px; color:" + buyRateColor + "'>" + buyRate + "</span></b></p>",
            useHTML: true,
            margin: 0,
            align: "left"
        },

        series: [{
            type: 'candlestick',
            name: props.currency,
            data: candles,
            upColor: "#649177",
            color: "#9a2020",
        }],
        credits: {
            enabled: false
        },
        yAxis: {
            offset: 40,
        },
    };

    return (
        <div className="MarketRatesGraph">
            {props.showMaxIcon ?
                (props.maximize ? <img src={minimize} alt="minimize" className={classes.GraphIcons} onClick={props.toggleSize} /> :
                    <img src={maximize} alt="maximize" className={classes.GraphIcons} onClick={props.toggleSize} />) : null}
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={options}
                immutable={true}
            />
        </div>
    );
}

export default MarketRatesGraph;
