import i18n from 'i18next'; 
import React, { Component } from 'react';
import Button from '../../../../../components/UI/Button/Button';
import classes from '../../../CurrencyTrader/DossierModal/BuySell.module.scss';
import ToolbarLogout from '../../../../../components/Toolbar/ToolbarLogout';
import { Link } from "react-router-dom";
class BoughtCancel extends Component {

    render() {
        return (
            <div>
                <ToolbarLogout />
                <div className={classes.Component}>
                    <div className={classes.Head}>
                        <p className={classes.TransactionText}>{i18n.t("NEW_REGISTRATION")}</p>
                    </div>
                    <div className={classes.container} style={{ padding: "30px 40px" }}>
                        <div className={classes.checkoutHeader}></div>
                        <div className={classes.checkoutCurrencies} style={{ height: "160px" }}>
                            <div>
                                <p className={classes.someText}>{i18n.t("ARE_YOU_SURE_YOU_WANT_TO_CONTI")} </p>

                                <p className={classes.someText}>{i18n.t("IF_YOU_CANCEL_WITHOUT_IDENTIFY")} <br />
                                    {i18n.t("YOUR_PURCHASE_WILL_NOT_BE_REGI")}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", margin: "5px 40px 30px " }}>
                        <Link to="/dashboard">  <Button> {i18n.t("YES")} </Button></Link>
                        <Link to="/boughttransaction">  <Button extraStyle={classes.cancelButton}> {i18n.t("NO")} </Button> </Link>
                    </div>
                </div>
            </div>);

    }
}



export default BoughtCancel;