import i18n from 'i18next';
import React, { Component } from 'react';
import classes from '../../../../CurrencyTrader/DossierModal/BuySell.module.scss';
import Exit from '../../../../../../components/UI/Cros/Cros';
import ToolbarLogout from '../../../../../../components/Toolbar/ToolbarLogout';
import Select from "react-dropdown-select";
import { graphql } from 'react-apollo';
import query from '../../../../../../queries/Customer';
import receive_client from "../../../../../../Client";

const client = receive_client();

class BoughtIndividuals extends Component {

  constructor(props) {
    super();
    this.state = {
      clients: []
    };

  }



  componentDidMount = async () => {
    let lista = [];
    const fetched_data = await client.query({ query: query });
    fetched_data.data.customers.map(client => {
      let obj = { 'id': client.id, 'value': client.firstName + " " + client.lastName };
      lista.push(obj);
      return null
    });
    this.setState({
      clients: lista,
      isFetching: false
    });


  };


  goBack = () => {
    this.props.history.goBack();
  };



  render() {
    return (
      <div>
        <ToolbarLogout />
        <div className={classes.Component} style={{ height: "750px", margin: "20px auto" }}>
          <div className={classes.Head}>
            <p className={classes.TransactionText}>{i18n.t("EXISTING_CLIENTS")}</p>
            <div style={{ marginRight: "10px" }}>
              <div onClick={() => this.goBack()}> <Exit /> </div>
            </div>

          </div>
          <p className={classes.subtitle}>{i18n.t("INDIVIDUALS")}</p>
          <div className={classes.container} style={{ padding: "10px 40px" }}>
            <div className={classes.currenciesHeader}></div>
            <div style={{ background: "white", marginTop: "30px" }}>

              <Select
                style={{ textAlign: "left" }}
                labelField={"value"}
                options={this.state.clients}
                values={[]}
                searchable
                clearable={true}
                backspaceDelete={true}
                keepOpen={true}
                create={true}
                createNewLabel={"{search}"} />



            </div>
          </div>

        </div>
      </div>);

  }
}



export default graphql(query)(BoughtIndividuals);
