import React from 'react';
import './language_module.css';
import { useTranslation } from 'react-i18next';

function NavbarLanguage() {
  const { i18n } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };
  var default_language = "en";
  if (window.localStorage.getItem('language') != null) {
    default_language = window.localStorage.getItem('language');
  }

  return (
    <div className={'Language'}>
      <span className={default_language === 'en' ? 'language_active' : ''} onClick={() => changeLanguage('en')}>EN</span>
      |
      <span className={default_language === 'sq' ? 'language_active' : ''} onClick={() => changeLanguage('sq')}>SQ</span>
    </div>
  );
}

export default NavbarLanguage;