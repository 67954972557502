import gql from 'graphql-tag';

export default gql`
query($id: ID) {
  customers(id:$id){
    id
    firstName
      lastName
      personalIdNumber
      address
      postalCode
      city
      country
      cellphone
      email
      rea
      typology
  }
}
`;
