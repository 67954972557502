import gql from 'graphql-tag';

export default gql`
query servicePointShifts($id: [ID!], $date: String){
  servicePointShifts(id:$id, date:$date){
    id
    shiftNumber
    amountOpenedCurrency
    amountOpenedCents
    averageOpenedRate
    amountClosedCents
    averageOpenedRate
    averageClosedRate
    timeClosed
    timeOpened
  }
}
`;
