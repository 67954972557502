import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './personalizations.module.scss';
import update_personalization from '../../../../../mutations/Personalization/UpdatePersonalization';
import get_personalizations from '../../../../../queries/Personalization/Personalization';
import { graphql } from 'react-apollo';

class Personalizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buy_currencies: this.props.buy_currencies,
      sell_currencies: this.props.sell_currencies,
      ck:false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }


  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.id !== 'personalizationModalIconCT') {
      this.props.close_modal();
    }
  }

  handleSave = () => {
    let buy_currencies = [];
    let sell_currencies = [];

    this.state.buy_currencies.forEach(currency => {
      if (currency.isChecked) {
        buy_currencies.push(currency.value);
      }
    });

    this.state.sell_currencies.forEach(currency => {
      if (currency.isChecked) {
        sell_currencies.push(currency.value);
      }
    });

    this.props.mutate({
      variables: { buy_currency: buy_currencies, sell_currency: sell_currencies },
      refetchQueries: [{ query: get_personalizations }]
    }).then((result)=>{
    }).catch(error => {
      console.log(error)
    });

    this.props.close_modal();
  };

  onCheckCurrency = (currency_selected, state, type) => {
    let list = JSON.parse(JSON.stringify(this.state[type + ["_currencies"]]));
    list.forEach(currency => {
      if (currency.value === currency_selected){
        currency.isChecked = state
      }
    });
    this.setState({ [type + "_currencies"]: list });
  }

  render() {
    return (
      <div ref={this.setWrapperRef} className={[classes.modal_item, classes.personalizations_modal].join(' ')}>
        <div className={classes.modal_header}>
          <div className={classes.frequency_modal_header}>{i18n.t("PERSONALIZATIONS")}</div>
        </div>
        <div className={classes.modal_body}>
          <div className={classes.personalizations_body}>
            <div className={[classes.personalizations_lightgray, 'col-12'].join(' ')} style={{marginBottom:"10px"}}>
            {i18n.t("CHANGES_MADE_HERE_WILL_BE_SAVED")}
            </div>
            <div className={[classes.personalizations_hrow, 'row'].join(' ')}>
              <div className="col-lg-6">
                <div className={classes.personalizations_miniHeader}>{i18n.t("TAB_BUY_EUR_ALL")}</div>
                <div className="row">
                  <div className="col-12">
                    <div className="text-center">
                      <div className={classes.personalizations_lightgray} style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {i18n.t("SELECT_CURRENCIES_FOR_BUY")}
                      </div>
                      {this.state.buy_currencies.map(currency => {
                        return(
                          <div className={classes.checkbox_row} key={currency.value}>
                            <input className={classes.CurrencyTrader_formRadio} type="radio" value="kot" checked={currency.isChecked} readOnly onClick={() => this.onCheckCurrency(currency.value, !currency.isChecked, "buy")}/>
                              <span>{currency.value}</span>
                            </div>
                        )
                      })}
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-lg-6">
                <div className={classes.personalizations_miniHeader}>{i18n.t("TAB_SELL_EUR_ALL")}</div>
                <div className="row">
                  <div className="col-12">
                    <div className="text-center">
                      <div className={classes.personalizations_lightgray} style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {i18n.t("SELECT_CURRENCIES_FOR_SELL")}
                      </div>
                      <div className="text-left" style={{ display: 'inline-block' }}>
                        {this.state.sell_currencies.map(currency => {
                          return(
                            <div className={classes.checkbox_row} key={currency.value}>
                              <input className={classes.CurrencyTrader_formRadio} type="radio" checked={currency.isChecked}  readOnly onClick={() => this.onCheckCurrency(currency.value, !currency.isChecked, "sell")}/>
                              <span>{currency.value}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right" style={{ marginTop: '15px', width: '100%' }}>
                <div className="button_type1" onClick={() => this.handleSave()}>{i18n.t("SAVE")}</div>
                <div className="button_type2" onClick={() => this.props.close_modal()}>{i18n.t("CANCEL")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>);

  }
}

export default graphql(update_personalization)(Personalizations);
