import gql from 'graphql-tag';

export default gql`

mutation deleteFixedSaleOrder($id: ID!){
deleteFixedSaleOrder(id:$id) {
id
}
}

`;
