import React from 'react';

import classes from './Input.module.css';
import Icon from '../Plus/Plus';

const input = ( props ) => {
    let inputElement = null;

    const inputClasses = [classes.InputElement];

    // if (props.invalid && props.shouldValidate && props.touch ){
    //     inputClasses.push(classes.Invalid);
    // }

    switch ( props.elementType ) {
        case ( 'input' ):
            inputElement = <input
                className={[inputClasses,props.inputStyle].join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
                onKeyDown={props.onKeyDown}
                 />;
            break;
        case ( 'radio' ):
            inputElement = <div ><input
            className={[inputClasses.Radio,props.inputStyle].join(' ')}
                {...props.elementConfig}
                onChange={props.changed} /> {props.description} </div>;
            break;
        case ( 'currency' ):
            inputElement = <div>{props.elementConfig.logo}<input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} /><Icon clicked={props.upload}/></div>
            break;
        case ( 'select' ):
            inputElement = (
                <select
                    className={inputClasses.join(' ')}
                    value={props.value}
                    onChange={props.changed}>
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </select>
            );
            break;
        // case ('date'):
        //     inputElement = <input
        //         className={inputClasses.join(' ')}
        //         {...props.elementConfig}
        //         value={props.value}
        //         onChange={props.changed} />;
        //     break;

        default:
            inputElement = <input
            className={[inputClasses,props.inputStyle].join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
    }

    return (
        <div className={classes.Input}>
            <label className={classes.Label}>{props.label}</label>
            {inputElement}
        </div>
    );

};

export default input;
