import gql from 'graphql-tag';

export default gql`
mutation createFixedPurchaseOrder($attributes:PurchaseOrderInput!, $purchased_from_sale:ID, $expiration:String, $purchased_currency_bank:ID, $paid_currency_bank:ID){
    createFixedPurchaseOrder(attributes:$attributes, purchasedFromSale: $purchased_from_sale, expiration: $expiration, purchasedCurrencyBank:$purchased_currency_bank, paidCurrencyBank:$paid_currency_bank) {
       id
       amountPaidCents
       amountPaidCurrency
       amountPurchasedCents
       amountPurchasedCurrency
       status
       number
       rate
       datetime
       expiration
    }
  }
`;
