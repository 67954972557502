import gql from 'graphql-tag';

export default gql`
query($date: String){
  notUpdatedServicePointShift(date: $date){
  amountClosedCents
  amountOpenedCents
  amountOpenedCurrency
  amountOpenedCurrency
  averageClosedRate
  averageOpenedRate
  id
  shiftNumber
  timeClosed
  timeOpened
  }
}
`;
