import i18n from 'i18next';
import React, { Component } from 'react';
import classes from './Orders.module.scss';
import FilterModal from '../../../../containers/Modals/FilterModal/FilterModal';
import FourDots from '../../../../components/UI/Icons/FourDots/FourDots';
import OrderList from './OrderList';
import GET_CURRENCY_TRADER_ORDERS from '../../../../queries/CurrencyTrader/GetOrders';
import GET_CURRENCY_TRADER_ORDERS_SUBSCRIPTION from '../../../../subscriptions/GetCurrencyTraderOrdersSubscription';
import CURRENCY_TRADER_ORDER_COUNTER from '../../../../queries/CurrencyTrader/CountCurrencyTrader';
import FilteredComponent from "../../../../containers/Modals/FilterModal/FilteredComponent";
import receive_client from "../../../../Client";
import Pagination from "react-js-pagination";
import { Query } from 'react-apollo'

var dateFormat = require('dateformat');

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      currency_trader_orders: [],
      active_page: 1,
      offset: 0,
      activePage: 1,
      openedChild: null,
      loading: true,
      max_rate: null,
      min_rate: null,
      min_paid: null,
      max_paid: null,
      max_value: null,
      min_value: null,
      min_date: null,
      max_date: null,
      category: null,
      currency_trader_currencies: null,
      selected_currencies: null,
      sorting_element: null,
      sorting_order: null,
      typology: null,
      user_id: this.props.current_user.id,
      order_counter: 0
    };
  }

  getFltr = () => {
    const { offset, min_rate, max_rate, min_paid, max_paid, min_value, max_value, min_date, max_date, category, selected_currencies, sorting_element, sorting_order, typology } = this.state;
    const filtering_elements = {
      offset: offset,
      minRateValue: min_rate,
      maxRateValue: max_rate,
      minPaidValue: min_paid,
      maxPaidValue: max_paid,
      minAmount: min_value,
      maxAmount: max_value,
      minDate: min_date,
      maxDate: max_date,
      category: category,
      currency: selected_currencies,
      sortingElement: sorting_element,
      sortingOrder: sorting_order,
      typology: typology
    }
    return filtering_elements
  }

  modalHandler = () => {
    this.setState({
      showModal: !this.state.showModal
    });

  };

  closeModal() {
    this.setState({
      showModal: false
    });
  }

  handlePageChange = async (pageNumber) => {
    await this.setState({
      activePage: pageNumber,
      offset: (pageNumber - 1) * 7
    });
  };

  setOpenedChild = child_id => {
    this.setState({
      openedChild: child_id
    });

  };


  getParent = obj => {
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        obj[key] = null;
      }
    });

    let selected_currencies = [];
    obj.currency_trader_currencies.map(currency => {
      if (currency.isChecked) {
        selected_currencies.push(currency.value);
      }
      return null
    });

    this.setState({
      max_rate: obj.max_rate_value,
      min_rate: obj.min_rate_value,
      min_paid: obj.min_paid_value,
      max_paid: obj.max_paid_value,
      min_value: obj.min_value,
      max_value: obj.max_value,
      min_date: obj.min_date,
      max_date: obj.max_date,
      category: obj.category,
      selected_currencies: selected_currencies.length > 0 ? selected_currencies : null,
      currency_trader_currencies: obj.currency_trader_currencies,
      sorting_element: obj.sorting_element,
      sorting_order: obj.sorting_order,
      typology: obj.typology
    });
  };

  onRemoveFilter = async (element, value) => {
    if (element === "currency") {
      let currencies = this.state.selected_currencies.filter(e => e !== value);
      let currency_objects = [...this.state.currency_trader_currencies];
      currency_objects.forEach(currency => {
        if (currency.value === value) {
          currency.isChecked = false;
        }
      });
      this.setState({
        selected_currencies: currencies.length > 0 ?
          currencies :
          null,
        currency_trader_currencies: currency_objects
      });

    }

    await this.setState({ [element]: null });
  };

  refetchAfterDelete = (refetch) => {
    refetch()
    this.setState({
      openedChild: null
    });
  };

  _subscribeToNewOrders = (subscribeToMore, refetch) => {
    subscribeToMore({
      document: GET_CURRENCY_TRADER_ORDERS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        refetch()
      }
    })
  }

  render() {
    let order_list = [];
    let amount = null;
    let currency = null;
    let portion;
    let percentage_portion;
    var modal = null;

    var list_of_filters = [];
    var stati = this.state;
    let filtering_string = "";

    Object.keys(stati).forEach(key => {
      if (stati[key] !== null && !["showModal", "offset", "activePage", "user_id", "sorting_order", "sorting_element", "currency_trader_orders", "active_page", "loading", "currency_trader_currencies", "openedChild", "order_counter"].includes(key)) {
        list_of_filters.push(key);
        filtering_string = filtering_string.concat(key + "=" + stati[key] + "&");
      }
    });

    filtering_string = "user_id=" + this.state.user_id + "&" + filtering_string + "orders_typology=online";
    const url = `${process.env.REACT_APP_HOME_URL}/file/order_details?${filtering_string}`;

    list_of_filters = list_of_filters.map(element => {
      let currency_list = [];
      if (element === "selected_currencies") {
        currency_list = this.state.selected_currencies.map(currency => {
          return <FilteredComponent key={currency} property={"currency"} value={currency} onRemoveFilter={this.onRemoveFilter} />;
        });
        return currency_list;
      } else {
        return <FilteredComponent key={element} property={element.replace(/_/g, " ")} value={this.state[element]} onRemoveFilter={this.onRemoveFilter} />;
      }

    });

    if (this.state.showModal) {
      modal = <FilterModal modal_open={this.modalHandler.bind(this)} closeModal={this.closeModal.bind(this)} get_parent={this.getParent} state={this.state} type={"currency_trader"} />;
    }

    return (
      <div className={classes.order_container}>
        <div className="d-flex align-items-center">
          <div style={{ whiteSpace: "nowrap" }}><b>{i18n.t("ORDER_HISTORY")}</b></div>
          <div className="filterModalIcon" style={{ position: "relative" }} onClick={() => { this.modalHandler(); }}>
            <FourDots size={18} dots_size={7.37} />
            {modal}
          </div>
          <a href={this.state.user_id ? url : ""}>
            <i className="fas fa-share-square" style={{ color: 'rgb(211, 156, 62)', fontSize: '18px', marginRight: '10px', cursor: "pointer" }}></i>
          </a>
          <div style={{ width: "75%" }}>
            <i className="fas fa-dollar-sign" onClick={() => this.props.bankAccountPopUp()} style={{ color: 'rgb(211, 156, 62)', fontSize: '18px', marginRight: '10px', float: "right", cursor: "pointer" }}></i>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", flexFlow: "wrap" }}>
          {list_of_filters}
        </div>
        <Query query={GET_CURRENCY_TRADER_ORDERS} variables={{attributes: this.getFltr()}} fetchPolicy= "network-only">
          {({ loading, error, data, refetch, subscribeToMore }) => {
            if (loading) return null
            if (error) return null
              this._subscribeToNewOrders(subscribeToMore, refetch)
            const orders = data.currencyTraderOrders
            return (
              <div>
                {orders.map(order => {
                  if (order.amountPurchasedCents) {
                    amount = order.amountPurchasedCents
                    currency = order.amountPurchasedCurrency
                    percentage_portion = order.percentagePurchased
                    portion = order.portionPurchased
                  }
                  else {
                    amount = order.amountSoldCents
                    currency = order.amountSoldCurrency
                    percentage_portion = order.percentageSold
                    portion = order.portionSold
                  }

                  const order_type = order.amountPurchasedCents ? "Purchase" : "Sale";
                  let status = "";
                  if (order_type === "Purchase") {
                    if (order.status === "completed") {
                      status = "purchases_purchased";
                    } else {
                      status = "purchases_posted";
                    }
                  } else
                    if (order_type === "Sale") {
                      if (order.status === "completed") {
                        status = "sales_sold";
                      } else {
                        status = "sales_posted";
                      }
                    }

                  const order_unique_key = order.id + order_type;
                  return (
                    <OrderList
                      key={order_unique_key}
                      order={order.id}
                      type={order_type}
                      money={amount}
                      order_nr={order.number}
                      expiration_time={new Date(order.expiration)}
                      purchase={order.amountPaidCents}
                      rate={order.rate}
                      currency={currency}
                      datetime={dateFormat(order.datetime, "mmmm d, yyyy HH:MM")}
                      status={status}
                      typology={order.typology}
                      banks = {order.bankAccounts}
                      paid_currency={order.amountPaidCurrency}
                      completed={order.status === "completed" ? true : false}
                      completed_time={dateFormat(order.updatedAt, "mmmm d, yyyy")}
                      portion={portion}
                      portion_remaining={order.availableTradingAmount}
                      percentage_portion={percentage_portion}
                      close_other_childs={() => this.setOpenedChild(order_unique_key)}
                      extraInfo={this.state.openedChild === order_unique_key ? true : false}
                      transactions={order.transactions}
                      refetchQueries={() => this.refetchAfterDelete(refetch)}
                      user={order.portfolio.user}
                    />
                  )
                })}
              </div>
            )
          }}
        </Query>
        <div className={classes.order_pagination}>
          <Query query={CURRENCY_TRADER_ORDER_COUNTER} variables={{attributes: this.getFltr()}} fetchPolicy= "network-only">
            {({ loading, error, data, refetch, subscribeToMore }) => {
              if (loading) return null
              if (error) return null
                this._subscribeToNewOrders(subscribeToMore, refetch)
              const number_pages = data.countCurrencyTrader
              return (
                <div>
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={7}
                      totalItemsCount={number_pages}
                      pageRangeDisplayed={9}
                      prevPageText={null}
                      nextPageText={null}
                      activeLinkClass={classes.activePage}
                      itemClass={classes.numbers}
                      linkClass={classes.numbers}
                      onChange={this.handlePageChange}
                    />
                </div>
              )
            }}
          </Query>
        </div>
      </div>);

  }
}




export default Orders;
